import { Box, Checkbox, Group, MultiSelect, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form/lib/types';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../../../../components/ColoredMessage/ColoredMessage';
import SelectOption from '../../../../../components/Form/SelectOption/SelectOption';
import { SbomUploadAlertSectionState } from '../../../../../types/FullInterfaces';
import { SbomAlertSourceFormValues } from '../../hooks/useSbomAlertsForms';
import { SBOMAlertControlsConfig } from '../../utils/getSbomAlertsConfig';
import AlertFormSection from '../AlertSection/AlertSection';
import SectionDetailSkeleton from '../SectionDetailSkeleton/SectionDetailSkeleton';
import SelectValue from './SelectValue';
import styles from './SourceSection.module.scss';
import SourceDetail from './SourceSectionDetail/SourceSectionDetail';

interface SourceProps {
  state: SbomUploadAlertSectionState;
  editButton?: {
    isVisible: boolean;
    onClick?: () => void;
  };
  nextButton?: {
    isVisible: boolean;
    onClick?: () => void;
  };
  form: UseFormReturnType<SbomAlertSourceFormValues>;
  sectionConfig: SBOMAlertControlsConfig['sourceSection'];
  isLoading?: boolean;
}

function Source({
  state,
  editButton,
  nextButton,
  form,
  sectionConfig,
  isLoading = false,
}: SourceProps) {
  const { t } = useTranslation();
  return (
    <AlertFormSection
      titleIconName="arrow-up-from-bracket"
      title={t('page.userSettings.sbomUploadAlerts.source.title')}
      description={t('page.userSettings.sbomUploadAlerts.source.description')}
      state={state}
      nextButton={{
        isDisabled: false,
        isVisible: Boolean(nextButton?.isVisible),
        onClick: nextButton?.onClick,
        label: t('global.next'),
      }}
      editButton={{
        isVisible: Boolean(editButton?.isVisible),
        onClick: editButton?.onClick,
        label: t('global.edit'),
      }}
      ViewComponent={
        isLoading ? (
          <SectionDetailSkeleton withTitle itemsNumber={3} />
        ) : (
          <SourceDetail
            labels={form.values.labels}
            allLabels={sectionConfig.controls.labels.options}
            uploadedVia={form.values.uploadedVia}
          />
        )
      }
      EditComponent={
        <div className={styles.container}>
          <div className={styles.checkboxGroupContainer}>
            <Text>
              {sectionConfig.controls.uploadedVia.label}
              <span className={styles.requiredStar}> *</span>
            </Text>
            <Checkbox.Group
              classNames={{
                error: styles.mantineCheckboxError,
              }}
              {...form.getInputProps('uploadedVia')}
              error={
                form.getInputProps('uploadedVia').error && (
                  <Box mt={8}>
                    <ErrorMessage message={form.getInputProps('uploadedVia').error} />
                  </Box>
                )
              }
            >
              <Group align="start" className={styles.checkboxGroup}>
                {sectionConfig.controls.uploadedVia.options.map((option) => {
                  return (
                    <Checkbox
                      classNames={{
                        label: styles.sourceControlLabel,
                      }}
                      value={option.value}
                      label={option.label}
                      key={option.value}
                    />
                  );
                })}
              </Group>
            </Checkbox.Group>
          </div>

          <MultiSelect
            label={sectionConfig.controls.labels.label}
            searchable
            itemComponent={SelectOption}
            classNames={{
              root: styles.multiSelectRoot,
              label: styles.sourceControlLabel,
              wrapper: styles.multiselectInput,
            }}
            valueComponent={SelectValue}
            data={sectionConfig.controls.labels.options}
            {...form.getInputProps('labels')}
          />
        </div>
      }
    />
  );
}

export default Source;
