import { TabProps, Tabs, TabsProps } from '@mantine/core';
import stylesBase from './ManifestTabsBase.module.scss';
import stylesPill from './ManifestTabsPill.module.scss';

import { TextComponent } from '@manifest-cyber/design-system/lib/components/TextComponent';
import clsx from 'clsx';

interface ManifestTabsProps extends TabsProps {
  children: React.ReactNode;
}

export const ManifestTabs = ({
  children,
  variant = 'default',
  ...props
}: ManifestTabsProps) => {
  const defaultClassNames = {
    tab: clsx(stylesBase.tab),
  };

  const pillClassNames = {
    tab: clsx(stylesBase.tab, stylesPill.tab),
  };

  return (
    <Tabs
      classNames={
        props.unstyled ? {} : variant === 'default' ? defaultClassNames : pillClassNames
      }
      variant={variant}
      {...props}
    >
      {children}
    </Tabs>
  );
};

ManifestTabs.Tab = ({ children, ...props }: TabProps) => {
  return (
    <Tabs.Tab {...props}>
      <TextComponent inline className={stylesBase.tabLabelText}>
        {children}
      </TextComponent>
    </Tabs.Tab>
  );
};
ManifestTabs.List = Tabs.List;
ManifestTabs.Panel = Tabs.Panel;
