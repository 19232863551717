import { TextComponent } from '@manifest-cyber/design-system/lib/components/TextComponent';
import { Tooltip, TooltipProps } from '@mantine/core';
import classNames from 'classnames';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import styles from './LinkComponent.module.scss';
/**
 * Props for the LinkComponent component
 * @interface LinkComponentProps
 */
export interface LinkComponentProps {
  /** Child elements to be rendered inside the clickable region */
  children?: React.ReactNode;
  /** Additional CSS class names to apply to the component */
  className?: string;
  /** Whether the clickable region is disabled */
  disabled?: boolean;
  /** URL for the link. If provided, renders as an anchor or Link component */
  href: string;
  /** Click event handler */
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  /** Target attribute for anchor tags (e.g., '_blank') */
  target?: string;
  /**
   * Can be an array of a single string or array with multiple elements for multiline tooltips.
   */
  tooltipLabel?: string[];
  /** Whether to disable the tooltip display */
  isTooltipDisabled?: boolean;
  /** Position of the tooltip relative to the element */
  tooltipPosition?: TooltipProps['position'];
  variant?: 'primary' | 'secondary';
  size?: 'small' | 'medium';
}

/**
 * LinkComponent is an accessibility-focused component that renders as either an external link (anchor)
 * or an internal router link while maintaining consistent styling and behavior.
 *
 * @component
 * @example
 * // As an external link
 * <LinkComponent
 *   href="https://example.com"
 *   tooltipLabel={["Visit Example"]}
 * >
 *   Visit Site
 * </LinkComponent>
 *
 * @example
 * // As an internal Router Link
 * <LinkComponent
 *   href="/dashboard"
 *   tooltipLabel={["Go to Dashboard"]}
 * >
 *   Dashboard
 * </LinkComponent>
 *
 * @example
 * // With multiline tooltip and disabled state
 * <LinkComponent
 *   href="/settings"
 *   tooltipLabel={["Settings", "Configure your preferences"]}
 *   disabled={true}
 * >
 *   Settings
 * </LinkComponent>
 *
 * Key Features:
 * - Automatic detection between external and internal links
 * - Built-in accessibility support with ARIA labels
 * - Support for single and multiline tooltips
 * - Proper disabled state handling with visual feedback
 * - Consistent styling across link variants
 *
 * @param {LinkComponentProps} props - Component props
 * @returns {JSX.Element} Rendered link component as either anchor or Router Link
 */
export const LinkComponent = forwardRef<HTMLAnchorElement, LinkComponentProps>(
  (
    {
      children,
      className,
      disabled,
      href,
      tooltipLabel,
      isTooltipDisabled = false,
      tooltipPosition,
      variant = 'primary',
      size = 'small',
      ...rest
    }: LinkComponentProps,
    ref: React.Ref<HTMLAnchorElement>,
  ) => {
    const regionClasses = classNames(
      className,
      styles.link,
      disabled && styles.disabledRegion,
    );
    const parsedTooltipLabel = tooltipLabel?.join('\n') ?? '';
    const titleLabel = tooltipLabel?.[0] ?? '';

    const isNativeLink =
      href.startsWith('http://') || href.startsWith('https://') || href.startsWith('#');

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (disabled) {
        e.preventDefault();
        return;
      }
      rest.onClick?.(e);
    };

    if (isNativeLink) {
      return (
        <Tooltip
          label={parsedTooltipLabel}
          position={tooltipPosition}
          disabled={isTooltipDisabled || !parsedTooltipLabel}
          multiline={tooltipLabel && tooltipLabel.length > 1}
          classNames={{ tooltip: styles.multilineTooltip }}
        >
          <TextComponent
            ref={ref as React.Ref<HTMLAnchorElement>}
            aria-label={titleLabel}
            aria-disabled={disabled}
            className={regionClasses}
            component="a"
            href={href}
            rel="noopener noreferrer"
            variant={size === 'small' ? 'bodySmall' : 'bodyMedium'}
            color={variant === 'primary' ? 'primary' : 'base'}
            hoverColor={variant === 'primary' ? 'primary' : 'white'}
            {...rest}
            onClick={handleClick}
          >
            {children}
          </TextComponent>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        label={parsedTooltipLabel}
        position={tooltipPosition}
        disabled={isTooltipDisabled || !tooltipLabel}
        multiline={tooltipLabel && tooltipLabel.length > 1}
        classNames={{ tooltip: styles.multilineTooltip }}
      >
        <TextComponent
          aria-label={titleLabel}
          aria-disabled={disabled}
          className={regionClasses}
          to={href}
          component={Link}
          variant={size === 'small' ? 'bodySmall' : 'bodyMedium'}
          color={variant === 'primary' ? 'primary' : 'base'}
          hoverColor={variant === 'primary' ? 'primary' : 'white'}
          {...rest}
          onClick={handleClick}
          ref={ref}
        >
          {children}
        </TextComponent>
      </Tooltip>
    );
  },
);
