import { ManifestApiResponse } from '@manifest-cyber/types/interface/apiResponse';
import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../../../api/axiosProxy/axiosProxy';
import { rejectFailedRequestOrEmptyData } from '../../../../lib/request/rejectFailedRequest/rejectFailedRequest';

export interface RoleUser {
  _id: string;
  firstName: string;
  lastName: string;
  emails: string[];
  roles: string[];
}

export interface MappedRoleUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export const useFetchRoleUsers = ({
  roleId,
  enabled,
}: {
  roleId?: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [`role/${roleId}/users`],
    queryFn: async (): Promise<MappedRoleUser[] | undefined> => {
      if (!roleId) {
        return undefined;
      }

      const response = (await AxiosProxy.get({
        url: `role/${roleId}/users`,
      })) as ManifestApiResponse<RoleUser[]>;

      const validatedData = await rejectFailedRequestOrEmptyData<RoleUser[]>(response);

      return validatedData.data.map((user) => ({
        id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.emails[0],
      })) as MappedRoleUser[];
    },
    enabled: Boolean(roleId) && enabled,
  });
};
