import axios, { AxiosError, ResponseType } from 'axios';
import Cookies from 'js-cookie';
import appConfig from '../../configs/appConfig';
import { LocationUtils } from '../../lib/location/location';
import { Logger } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

interface GetParams {
  url: string;
  withCredentials?: boolean;
  params?: {};
  headers?: {};
  signal?: AbortSignal;
  options?: { responseType?: ResponseType };
}

interface PostParams {
  url: string;
  withCredentials?: boolean;
  body?: {};
  options?: {};
}

interface PutFilesParams {
  url: string;
  formData: FormData;
  withCredentials?: boolean;
  options?: {};
}

export const setProtocol = (url: string) => {
  const currentProtocol = appConfig?.useSsl ? 'https:' : 'http:';
  return `${currentProtocol}${url.replace('https:', '').replace('http:', '')}`;
};

/**
 * formattedApiUrl()
 * Wrapper for any preprocessing for URL
 * @returns {string}
 */
export const formattedApiUrl = (): string => {
  const returnUrl = `//${appConfig.apiUrl}/v${appConfig.apiVersion}`;
  return returnUrl;
};

/**
 * getOrganizationId()
 * Grabs our JWT from localStorage (or falls back to Cookie)
 */
const getOrganizationId = () => Cookies.get('organizationid') || '';

/**
 * API: GET
 * Can pass query params in URL or manually via params
 * @param {string} url Relative URL for API
 * @param {boolean} withCredentials Include JWT (for auth'ed routes)
 * @param {object} params Pass in any query params
 * @param headers
 * @param signal
 * @param options
 * @returns Promise
 */
const get = async ({
  url,
  withCredentials = true,
  params,
  headers,
  signal,
  options = {},
}: GetParams) =>
  axios
    .get(`${formattedApiUrl()}/${url}`, {
      withCredentials, // Send cookies
      headers: {
        organizationid: `${getOrganizationId()}`,
        ...headers,
      },
      params,
      signal,
      ...options,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      const e = error as AxiosError;
      const browserLocation = LocationUtils.getLocation();

      Logger.error(
        `GET ERROR. status: ${e?.response?.status} url: ${`/${url}`} pathname: ${browserLocation.pathname}. code: ${e.code}`,
        e,
      );
      if (!browserLocation.href.includes('/login')) {
        if (e?.response?.status === 401) {
          const responseData = e?.response?.data;
          const isDisabledFeatureFlag =
            typeof responseData === 'string' &&
            responseData.includes('Feature Not Enabled');

          if (isDisabledFeatureFlag) {
            Logger.error(`Feature Not Enabled`);
            return Promise.reject(e);
          }

          const redirectUrl = browserLocation.pathname.startsWith('/o/')
            ? '/logout'
            : '/logout?reason=invalid-expired-session';

          Logger.error(`logging out: 401`);
          LocationUtils.browserNavigate(redirectUrl);
          return Promise.reject(e);
        }
      }

      return Promise.reject(e);
    });

/**
 * API: POST
 * @param {string} url Relative URL for API
 * @param {object} body POST body
 * @param {boolean} withCredentials Include JWT (for auth'ed routes)
 * @param {object} options Pass in any additional options.
 * @returns Promise
 */
const post = async ({ url, withCredentials = true, body, options = {} }: PostParams) =>
  axios
    .post(`${formattedApiUrl()}/${url}`, body, {
      headers: {
        organizationid: `${getOrganizationId()}`,
      },
      withCredentials,
      ...options,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      Logger.error(
        `POST ERROR. status: ${e?.response?.status} code: ${e?.code}  url: ${`/${url}`}. pathname: ${LocationUtils.getLocation().pathname}`,
        e,
      );
      return Promise.reject(e);
    });
/**
 * API: PUT
 * @param {string} url Relative URL for API
 * @param {object} body POST body
 * @param {boolean} withCredentials Include JWT (for auth'ed routes)
 * @param {object} options Pass in any additional options.
 * @returns Promise
 */
const put = async ({ url, withCredentials = true, body, options = {} }: PostParams) =>
  axios
    .put(`${formattedApiUrl()}/${url}`, body, {
      headers: {
        organizationid: `${getOrganizationId()}`,
      },
      withCredentials,
      ...options,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      Logger.error(
        `PUT ERROR. status: ${e?.response?.status} code: ${e?.code}  url: ${`/${url}`}. pathname: ${LocationUtils.getLocation().pathname}`,
        e,
      );
      return Promise.reject(e);
    });
/**
 * API: PUT Files
 * @param {string} url Relative URL for API
 * @param {FormData} formData to put
 * @param {boolean} withCredentials Include JWT (for auth'ed routes)
 * @param {object} options Pass in any additional options.
 * @returns Promise
 */
const putFiles = async ({
  url,
  formData,
  withCredentials = true,
  options = {},
}: PutFilesParams) => {
  return axios({
    method: 'PUT',
    url: `${formattedApiUrl()}/${url}`,
    data: formData,
    withCredentials,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...options,
  })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      Logger.error(
        `PUT FILES ERROR. status: ${e?.response?.status} code: ${e?.code}  url: ${`/${url}`}. pathname: ${LocationUtils.getLocation().pathname}`,
        e,
      );
      return Promise.reject(e);
    });
};
/**
 * API: DELETE
 * @param {string} url Relative URL for API
 * @param {object} body POST body
 * @param {boolean} withCredentials Include JWT (for auth'ed routes)
 * @param {object} options Pass in any additional options.
 * @returns Promise
 */
const axiosDelete = async ({
  url,
  withCredentials = true,
  body,
  options = {},
}: PostParams) =>
  axios
    .delete(`${formattedApiUrl()}/${url}`, {
      headers: {
        organizationid: `${getOrganizationId()}`,
      },
      data: body,
      withCredentials,
      ...options,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      Logger.error(
        `DELETE ERROR. status: ${e?.response?.status} code: ${e?.code}  url: ${`/${url}`}. pathname: ${LocationUtils.getLocation().pathname}`,
        e,
      );
      return Promise.reject(e);
    });

export const AxiosProxy = {
  get,
  post,
  put,
  putFiles,
  delete: axiosDelete,
};
