import { ManifestColors } from '@manifest-cyber/design-system/lib/foundations/ts/colors';
import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { rejectFailedRequestOrEmptyData } from '../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { MappedOrganization, Organization } from '../../types/organization.interface';

interface Params {
  organizationId?: string | null;
}

interface Options {
  staleTime?: number;
}

export const mapOrganization = (organization: Organization): MappedOrganization => {
  const isProductInProductsEnabled = organization.enabledFeatures?.some(
    (feature) => feature === 'product-in-products',
  );
  const isAcceptedVulnerabilityTriageEnabled = organization.enabledFeatures?.some(
    (feature) => feature === 'accepted-vuln-triage',
  );
  const isSlaEnabled = organization.enabledFeatures?.some(
    (feature) => feature === 'slaV1',
  );

  return {
    ...organization,
    featureFlags: {
      isProductInProductsEnabled,
      isAcceptedVulnerabilityTriageEnabled,
      isSlaEnabled,
    },
    defaultRole: {
      ...organization.defaultRole,
      id: organization.defaultRole?._id ?? '',
      color: organization.defaultRole?.color ?? ManifestColors.colorPrimary1,
    },
  };
};

export const useFetchOrganization = ({ organizationId }: Params, options?: Options) => {
  const fetchUrl = `organization/${organizationId || ''}`;

  return useQuery({
    enabled: Boolean(organizationId),
    queryKey: ['currentOrganization', { _id: organizationId }, `/${fetchUrl}`],
    queryFn: async () => {
      const response = await AxiosProxy.get({ url: fetchUrl });

      const validatedResponse =
        await rejectFailedRequestOrEmptyData<[Organization]>(response);
      return mapOrganization(validatedResponse.data[0]);
    },
    staleTime: options?.staleTime,
  });
};
