import { PostAssetsParams } from '../../../../hooks/mutations/usePostAssets';

export const getAssetsToUpdate = (
  assetIds: Array<string | undefined>,
  state: 'active' | 'inactive',
): PostAssetsParams['assets'] => {
  const isActive = state === 'active';
  return assetIds
    .filter((assetId): assetId is string => Boolean(assetId))
    .reduce<PostAssetsParams['assets']>((acc, assetId) => {
      acc[assetId] = isActive;
      return acc;
    }, {});
};
