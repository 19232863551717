import { ManifestApiResponse } from '@manifest-cyber/types/interface/apiResponse';
import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../../../api/axiosProxy/axiosProxy';
import { CompositePermissionUrn } from './permissions.type';
import { MappedCompositePermission, useFetchPermissions } from './useFetchPermissions';
import { Role } from './useFetchRoles';

export interface RoleDetailInterface extends Role {
  permissions: CompositePermissionUrn[];
  userQuantity: number;
}

export interface MappedRoleDetail {
  name: string;
  isDefault: boolean;
  isPredefined: boolean;
  metadata: string;
  permissionsGroups: {
    title: string;
    compositePermissions: MappedCompositePermission[];
    isMainGroup: boolean;
  }[];
  usersQuantity: number;
}

export type PermissionVisibleGroupId =
  | 'all'
  | 'api_tokens'
  | 'general_settings'
  | 'sboms'
  | 'assets'
  | 'products';

const groups = new Map<PermissionVisibleGroupId, CompositePermissionUrn[]>([
  ['all', ['view-all']],
  ['api_tokens', ['create-api-token', 'delete-api-token']],
  [
    'general_settings',
    ['manage-settings', 'manage-sub-organizations', 'manage-people', 'manage-roles'],
  ],
  ['sboms', ['manage-sboms-and-vex', 'share-sbom', 'request-sbom', 'import-oss']],
  ['assets', ['manage-assets', 'export-reports', 'manage-vulnerability-triage']],
  ['products', ['manage-products']],
]);

interface RoleDetailTexts {
  sectionsSubtitles: {
    apiTokens: string;
    generalSettings: string;
    sboms: string;
    assets: string;
    products: string;
    allPermissions: string;
  };
  permissions: {
    viewAll: {
      title: string;
      description: string;
    };
    manageSettings: {
      title: string;
      description: string;
    };
    manageSubOrganizations: {
      title: string;
      description: string;
    };
    manageTokens: {
      title: string;
      description: string;
    };
    managePeople: {
      title: string;
      description: string;
    };
    manageRoles: {
      title: string;
      description: string;
    };
    manageSharingPortal: {
      title: string;
      description: string;
    };
    manageSbomsAndVex: {
      title: string;
      description: string;
    };
    manageAssets: {
      title: string;
      description: string;
    };
    manageProducts: {
      title: string;
      description: string;
    };
    shareSboms: {
      title: string;
      description: string;
    };
    requestSboms: {
      title: string;
      description: string;
    };
    importOss: {
      title: string;
      description: string;
    };
    exportReports: {
      title: string;
      description: string;
    };
    vulnerabilityTriage: {
      title: string;
      description: string;
    };
  };
}

const getPermissionGroupTitle = (
  groupId: PermissionVisibleGroupId,
  texts: RoleDetailTexts,
) => {
  switch (groupId) {
    case 'all':
      return texts.sectionsSubtitles.allPermissions;
    case 'api_tokens':
      return texts.sectionsSubtitles.apiTokens;
    case 'general_settings':
      return texts.sectionsSubtitles.generalSettings;
    case 'sboms':
      return texts.sectionsSubtitles.sboms;
    case 'assets':
      return texts.sectionsSubtitles.assets;
    case 'products':
      return texts.sectionsSubtitles.products;
    default:
      return '';
  }
};

const getPermissionNameAndDescription = ({
  permissionUrn,
  texts,
}: {
  permissionUrn: CompositePermissionUrn;
  texts: RoleDetailTexts;
}) => {
  switch (permissionUrn) {
    case 'view-all':
      return texts.permissions.viewAll;
    case 'create-api-token':
      return texts.permissions.manageTokens;
    case 'manage-settings':
      return texts.permissions.manageSettings;
    case 'manage-sub-organizations':
      return texts.permissions.manageSubOrganizations;
    case 'manage-people':
      return texts.permissions.managePeople;
    case 'manage-roles':
      return texts.permissions.manageRoles;
    case 'manage-sharing-portal':
      return texts.permissions.manageSharingPortal;
    case 'manage-sboms-and-vex':
      return texts.permissions.manageSbomsAndVex;
    case 'manage-assets':
      return texts.permissions.manageAssets;
    case 'manage-products':
      return texts.permissions.manageProducts;
    case 'share-sbom':
      return texts.permissions.shareSboms;
    case 'request-sbom':
      return texts.permissions.requestSboms;
    case 'import-oss':
      return texts.permissions.importOss;
    case 'export-reports':
      return texts.permissions.exportReports;
    case 'manage-vulnerability-triage':
      return texts.permissions.vulnerabilityTriage;
    default:
      return {
        title: 'Missing title',
        description: 'Missing description',
      };
  }
};

export const useFetchRoleDetail = ({
  roleId,
  texts,
}: {
  roleId?: string;
  texts: RoleDetailTexts;
}) => {
  const { data: permissions } = useFetchPermissions();

  return useQuery({
    queryKey: ['role', roleId],
    queryFn: async (): Promise<undefined | MappedRoleDetail> => {
      if (!roleId || !permissions) {
        return undefined;
      }

      const { data } = (await AxiosProxy.get({
        url: `role/${roleId}`,
      })) as ManifestApiResponse<RoleDetailInterface>;
      const role = data as RoleDetailInterface;

      const { permissions: compositePermissions } = role;

      const permissionsGroups = Array.from(
        groups.entries().map(([groupId, compositePermissionsUrn]) => {
          const compositePermission = compositePermissionsUrn.reduce((accum, urn) => {
            const foundPermission = permissions.composite.find(
              (permission) => permission.urn === urn,
            );

            if (foundPermission) {
              const { title, description } = getPermissionNameAndDescription({
                permissionUrn: foundPermission.urn,
                texts,
              });
              accum.push({
                ...foundPermission,
                name: title,
                displayName: title,
                description,
                isSelected: compositePermissions.some(
                  (compositePermission) => compositePermission === foundPermission.urn,
                ),
                isRemovable: foundPermission.urn !== 'view-all',
              });
            }

            return accum;
          }, [] as MappedCompositePermission[]);

          return {
            title: getPermissionGroupTitle(groupId, texts),
            isMainGroup: groupId === 'all',
            compositePermissions: compositePermission,
          };
        }),
      );

      return {
        ...role,
        permissionsGroups,
        metadata: '',
        usersQuantity: role.userQuantity,
      };
    },
    enabled: Boolean(roleId && permissions),
  });
};
