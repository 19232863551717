import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import '../../../../scss/pages/integrations/github.scss';
import { IntegrationAddShell } from '../components/IntegrationAddShell';
import { IntegrationAddStart } from '../components/IntegrationAddStart';
import { getGitHubIntegration } from '../integrations-data';
import { GitHubIntegrationConfirm } from './components/GitHubIntegrationConfirm';
import { GitHubIntegrationOrganization } from './GitHubIntegrationOrganization';
import { GithubIntegrationOrganizations } from './GitHubIntegrationOrganizations';

export const GitHubIntegration = () => {
  const { t } = useTranslation();
  const gitHubIntegrationInfo = getGitHubIntegration(t);

  return (
    <Routes>
      <Route
        path="add"
        element={
          <IntegrationAddShell integrationInfo={gitHubIntegrationInfo}>
            <IntegrationAddStart integrationInfo={gitHubIntegrationInfo} />
          </IntegrationAddShell>
        }
      />
      <Route
        path="confirm"
        element={
          <IntegrationAddShell integrationInfo={gitHubIntegrationInfo}>
            <GitHubIntegrationConfirm />
          </IntegrationAddShell>
        }
      />
      <Route
        path="organizations"
        element={<GithubIntegrationOrganizations integration={gitHubIntegrationInfo} />}
      />
      <Route
        path="organization/:installationId/*"
        element={<GitHubIntegrationOrganization integration={gitHubIntegrationInfo} />}
      />
      <Route path="*" element={<Navigate to={'organizations'} replace />} />
    </Routes>
  );
};
