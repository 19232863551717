import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

interface AgreementResponse {
  data: {
    _id: string;
    agreementText: string;
    requiresAgreement: boolean;
    agreementLastUpdated?: string;
    organizationName?: string;
    domains: string[];
    parentData?: {
      _id?: string;
      name?: string;
      domains?: string[];
    };
  };
  success: boolean;
  errors: string[];
}

const handleError = (error: unknown): void => {
  if (axios.isAxiosError(error)) {
    captureExceptionWithMessage('API Error:', error.response?.data || error.message);
  } else {
    captureExceptionWithMessage('Unexpected Error:', error);
  }
};

const fetchAgreement = async (): Promise<AgreementResponse> => {
  return await AxiosProxy.get({ url: 'organization/agreement' });
};

export const useOrganizationAgreement = (orgId?: string) => {
  return useQuery(['organizationAgreement', orgId], () => fetchAgreement(), {
    onError: handleError,
    retry: 3,
  });
};
