import { TextComponent } from '@manifest-cyber/design-system/lib/components/TextComponent';
import { Divider, Flex, SimpleGrid } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../components/Icon';
import { LinkComponent } from '../../../../../components/Link/LinkComponent';
import ManifestAccordion from '../../../../../components/ManifestAcordion/ManifestAcordion';
import { ComponentsIcon } from '../../../../../img/illustrations/sidebar/Components';
import { LicenseIssueWithAffectedComponents } from '../../../api/useFetchAssetLicenseIssues';
import styles from './LicenseIssueCard.module.scss';

interface Props {
  licenseIssue: LicenseIssueWithAffectedComponents;
}

export const LicenseIssueHeader = ({
  licenseIssue,
  asCard,
}: {
  licenseIssue: LicenseIssueWithAffectedComponents;
  asCard?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <SimpleGrid
      cols={3}
      p={asCard ? '1rem' : undefined}
      className={asCard ? styles.card : undefined}
    >
      <Flex className="license-issue-title">
        <TextComponent color="primary" mr="8px">
          <Icon icon="key" />
        </TextComponent>
        <TextComponent variant="headerSmall" color="title">
          {licenseIssue.shortName}
        </TextComponent>
      </Flex>
      <Flex align={'center'}>
        <TextComponent color="base" variant="bodySmall" inline>
          {licenseIssue.licenseType}
        </TextComponent>{' '}
        <Divider orientation="vertical" size="sm" mr="sm" ml="sm" />
        <TextComponent color="base" variant="bodySmall" inline>
          {t('tags.components', {
            count: asCard ? 1 : licenseIssue.affectedComponents?.length,
          }).toLowerCase()}
        </TextComponent>
      </Flex>
    </SimpleGrid>
  );
};

export const LicenseIssueCard = ({ licenseIssue }: Props) => {
  return (
    <>
      <ManifestAccordion.Item value={licenseIssue.id}>
        <ManifestAccordion.Control>
          <LicenseIssueHeader licenseIssue={licenseIssue} />
        </ManifestAccordion.Control>
        {Boolean(licenseIssue.affectedComponents) && (
          <ManifestAccordion.Panel>
            <SimpleGrid cols={3} mr={'45px'} spacing="8px" verticalSpacing="2px">
              {licenseIssue.affectedComponents?.map((component) => (
                <LinkComponent
                  key={component.organizationComponentId}
                  variant="secondary"
                  href={`/component/${component.organizationComponentId}/overview`}
                >
                  <TextComponent
                    variant="bodySmall"
                    color="muted"
                    hoverColor="muted"
                    mr="4px"
                    align="center"
                    className={styles.iconContainer}
                  >
                    <ComponentsIcon width={14} height={14} />
                  </TextComponent>
                  {component.name} {component.version}
                </LinkComponent>
              ))}
            </SimpleGrid>
          </ManifestAccordion.Panel>
        )}
      </ManifestAccordion.Item>
    </>
  );
};
