import { useState } from 'react';
import { MRT_RowSelectionState } from 'mantine-react-table';

export const useSelectionState = () => {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectedRowsAmount, setSelectedRowsAmount] = useState<number>(0);
  const [selectedProductsAmount, setSelectedProductsAmount] = useState<number>(0);

  return {
    rowSelection,
    setRowSelection,
    selectedRowsAmount,
    setSelectedRowsAmount,
    selectedProductsAmount,
    setSelectedProductsAmount,
  };
};
