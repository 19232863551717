import { TextComponent } from '@manifest-cyber/design-system/lib/components/TextComponent';
import ClickableRegion from '../../../../components/ClickableRegion';
import styles from './FixesList.module.scss';
import { t } from 'i18next';
import Icon from '../../../../components/Icon';

export interface FixesList {
  elements: string[];
  title: string;
}

interface Props {
  list: FixesList[];
}

export const FixesList = (props: Props) => {
  const { list } = props;
  if (!list || list.length === 0)
    return (
      <div className={`card ${styles.container}`}>
        <p className="card-title">{t('page.vulnerability.details.main-tabs.fixes')}</p>
        <div className={styles.notFixesContainer}>
          <div className={styles.notFixesIcon}>
            <Icon icon="wrench" iconSharp ata-testid="clear-icon" />
          </div>
          <TextComponent color="muted" variant="componentSmall">
            {t('page.vulnerability.details.fixes-tab.empty')}
          </TextComponent>
        </div>
      </div>
    );

  return (
    <div className={`card ${styles.container}`}>
      <p className="card-title">{t('page.vulnerability.details.main-tabs.fixes')}</p>
      {list.map(({ title, elements }, index) => (
        <div key={index}>
          {!!title && (
            <TextComponent color="muted" variant="headerAllCaps" mb="md">
              {title}
            </TextComponent>
          )}
          <ul className={styles.list}>
            {elements.map((e, index) => (
              <li key={index}>{e}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};
