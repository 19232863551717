import { ColorDot } from '@manifest-cyber/design-system/lib/components/ColorDot';
import { TextComponent } from '@manifest-cyber/design-system/lib/components/TextComponent';
import { ManifestColors } from '@manifest-cyber/design-system/lib/foundations/ts';
import { Box, Button, clsx, Divider, Flex, Loader, Stack } from '@mantine/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ActionableCard } from '../../../components/ActionableCard/ActionableCard';
import Icon from '../../../components/Icon/Icon';
import NumberBadge from '../../../components/NumberBadge/NumberBadge';
import { useOrganizationId } from '../../../hooks/utils/useOrganizationId';
import { useFetchRoles } from './api/useFetchRoles';
import styles from './Roles.module.scss';

export const Roles = () => {
  const [organizationId] = useOrganizationId();
  const { data, isLoading, error, refetch } = useFetchRoles({
    organizationId: organizationId ?? '',
  });
  const { roleId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (data && !roleId) {
      const defaultRole = data?.items.find((role) => role.isDefault);
      if (defaultRole) {
        navigate(`/settings/roles/${defaultRole.id}`);
      }
    }
  }, [data?.items, roleId]);

  if (isLoading) {
    return <Loader aria-label="Loading..." data-testid="roles-loader" />;
  }

  return (
    <div>
      <Flex>
        <Box mr="24px">
          <TextComponent variant="headerXLarge">Roles</TextComponent>
          <Divider my="16px" color={ManifestColors.colorBorderCards} />
          <Stack spacing={'8px'} className={clsx(styles.navbarContainer)}>
            {data?.items.map((role) => (
              <Button
                size="lg"
                classNames={{
                  root: clsx(styles.root, role.id === roleId && styles.itemActive),
                  label: styles.inner,
                }}
                unstyled
                key={role.id}
                onClick={() => navigate(`/settings/roles/${role.id}`)}
              >
                <Flex>
                  <ColorDot size="medium" color={role.color} />
                  <TextComponent variant="componentTextEntry" ml="12px">
                    {role.name}
                  </TextComponent>
                </Flex>
                {role.isDefault && <NumberBadge number="Default" withUpperCase={false} />}
              </Button>
            ))}
          </Stack>
        </Box>
        <div style={{ flex: 1 }}>
          {(error || !data) && (
            <Box mt="120px">
              <ActionableCard
                title={t('error.general.errorLoadingData')}
                subtitle={t('error.general.errorLoadingDataSubtitle')}
                Icon={<Icon icon="circle-exclamation" size="lg" />}
                primaryAction={{
                  label: t('global.retry'),
                  onClick: () => {
                    refetch();
                  },
                  isLoading,
                }}
              />
            </Box>
          )}
          {data && <Outlet />}
        </div>
      </Flex>
    </div>
  );
};
