interface Props {
  iconWidth: string;
  iconHeight: string;
}

export const GitHubOrganizationFallbackIcon = ({ iconWidth, iconHeight }: Props) => {
  return (
    <div className="logo">
      <div className="logo-visualmark">
        <svg
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M32.0215 5.97314C17.6175 5.97314 5.97333 17.902 5.97333 32.6596C5.97333 44.4561 13.4342 54.4416 23.7844 57.9758C25.0784 58.2415 25.5524 57.4016 25.5524 56.6951C25.5524 56.0764 25.5097 53.9558 25.5097 51.7463C18.2638 53.3371 16.7549 48.5651 16.7549 48.5651C15.5904 45.4722 13.865 44.6773 13.865 44.6773C11.4934 43.0426 14.0377 43.0426 14.0377 43.0426C16.6685 43.2193 18.0489 45.7819 18.0489 45.7819C20.3773 49.8463 24.1293 48.6979 25.6388 47.9909C25.8542 46.2677 26.5447 45.0748 27.2778 44.4122C21.4986 43.7935 15.4182 41.4961 15.4182 31.2455C15.4182 28.3294 16.4525 25.9436 18.0915 24.0882C17.833 23.4256 16.9271 20.6857 18.3507 17.0187C18.3507 17.0187 20.5501 16.3117 25.5092 19.758C27.6324 19.1739 29.822 18.8767 32.0215 18.8742C34.2209 18.8742 36.463 19.1838 38.5333 19.758C43.493 16.3117 45.6924 17.0187 45.6924 17.0187C47.116 20.6857 46.2096 23.4256 45.951 24.0882C47.6332 25.9436 48.6249 28.3294 48.6249 31.2455C48.6249 41.4961 42.5445 43.7491 36.7221 44.4122C37.6712 45.2516 38.4901 46.8419 38.4901 49.3605C38.4901 52.9392 38.4475 55.8113 38.4475 56.6946C38.4475 57.4016 38.922 58.2415 40.2155 57.9764C50.5657 54.4411 58.0265 44.4561 58.0265 32.6596C58.0692 17.902 46.3823 5.97314 32.0215 5.97314Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};
