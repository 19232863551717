import { ManifestColors } from '@manifest-cyber/design-system/lib/foundations/ts/colors';
import { ManifestApiResponse } from '@manifest-cyber/types/interface/apiResponse';
import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../../../api/axiosProxy/axiosProxy';
import { rejectFailedRequestOrEmptyData } from '../../../../lib/request/rejectFailedRequest/rejectFailedRequest';

export interface Role {
  _id: string;
  urn: 'admin' | 'view-only' | 'member' | (string & {});
  name: string;
  isDefault: boolean;
  isPredefined: boolean;
  color?: string;
  dateCreated: string;
  dateModified: string;
}
export interface MappedRole {
  id: string;
  urn: Role['urn'];
  name: string;
  isDefault: boolean;
  isPredefined: boolean;
  color: string;
  dateCreated: string;
  dateModified: string;
}

export interface MappedRolesResponse {
  items: MappedRole[];
  pagination: RolesApiResponseData['pagination'];
}

export type RolesApiResponseData = {
  items: Role[];
  pagination: {
    total: number;
    page: number;
    limit: number;
    totalPages: number;
  };
};

export type RolesApiResponse = ManifestApiResponse<RolesApiResponseData>;

export const useFetchRoles = ({ organizationId }: { organizationId: string }) => {
  return useQuery({
    queryKey: ['roles', organizationId],
    queryFn: async (): Promise<MappedRolesResponse> => {
      const response = (await AxiosProxy.get({ url: 'roles' })) as RolesApiResponse;

      const validatedResponse =
        await rejectFailedRequestOrEmptyData<RolesApiResponseData>(response);

      return {
        pagination: validatedResponse.data.pagination,
        items: validatedResponse.data.items.map((role) => ({
          id: role._id,
          urn: role.urn,
          name: role.name,
          isDefault: role.isDefault,
          isPredefined: role.isPredefined,
          color: role.color ?? ManifestColors.colorPrimary1,
          dateCreated: role.dateCreated,
          dateModified: role.dateModified,
        })),
      };
    },
  });
};
