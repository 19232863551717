import {
  ActionIcon,
  Alert,
  Button,
  Checkbox,
  Flex,
  Menu,
  Modal,
  ScrollArea,
  Table,
  Text,
  TextInput,
} from '@mantine/core';
import { MRT_RowSelectionState } from 'mantine-react-table';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../Icon';
import Loading from '../../Loading';
import './ProductsAssociationModal.scss';
import { useProductsAssociationModal } from './useProductsAssociationModal.hook';

interface ProductsAssociationModalProps {
  entity: 'product' | 'asset';
  opened: boolean;
  close: () => void;
  assetsIds: MRT_RowSelectionState;
  clearRowSelection: VoidFunction;
  openNotificationModal: VoidFunction;
  updateSelectedProductsCallback: Dispatch<SetStateAction<number>>;
}

const ProductsAssociationModal = ({
  entity,
  opened,
  close,
  assetsIds,
  openNotificationModal,
  clearRowSelection,
  updateSelectedProductsCallback,
}: ProductsAssociationModalProps) => {
  const { t } = useTranslation();
  const {
    productNameFilter,
    selectedProducts,
    selectAll,
    isLoading,
    errors,
    isSubmissionSuccessful,
    handleSelectAllChange,
    onInputChange,
    onCloseModal,
    handleSort,
    handleCheckboxChange,
    submit,
    setSelectAll,
    productsResponse,
    filteredProducts,
    isLoadingMutation,
    setFilteredProducts,
  } = useProductsAssociationModal({
    entity,
    openNotificationModal,
    clearRowSelection,
    close,
  });

  useEffect(() => {
    if (errors.length === 0 && isSubmissionSuccessful) {
      close();
    }
  }, [errors, isSubmissionSuccessful]);

  useEffect(() => {
    setSelectAll(
      selectedProducts.length === filteredProducts.length && filteredProducts.length > 0,
    );
  }, [selectedProducts, filteredProducts]);

  useEffect(() => {
    if (productsResponse) {
      const filtered = productsResponse.data.filter((product) =>
        product.name?.toLocaleLowerCase().includes(productNameFilter.value.toLowerCase()),
      );
      setFilteredProducts(filtered);
    }
  }, [productNameFilter.value, productsResponse]);

  return (
    <Modal.Root opened={opened} onClose={close} size="lg">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Flex justify="space-between" style={{ width: '100%' }}>
            <Flex direction="column" gap={4}>
              <Modal.Title>{t('product.products-association.title')}</Modal.Title>
              <Text size="sm" className="products-association-modal__subtitle">
                {t(
                  `product.products-association.subtitle${entity === 'product' ? 'Products' : 'Assets'}`,
                  {
                    count: Object.keys(assetsIds).length,
                  },
                )}
              </Text>
            </Flex>
            <Modal.CloseButton />
          </Flex>
        </Modal.Header>
        <Modal.Body>
          {errors.length > 0 && (
            <Alert color="red" mb={16}>
              {errors.map((error, index) => (
                <div key={index}>{t(error)}</div>
              ))}
            </Alert>
          )}
          <Flex gap={8}>
            <TextInput
              className="products-association-modal__input"
              placeholder={t('global.search')}
              disabled={isLoading || isLoadingMutation}
              value={productNameFilter.value}
              onChange={onInputChange}
              icon={
                <Icon
                  icon="magnifying-glass"
                  className="products-association-modal__input-icon"
                />
              }
              sx={{ flexGrow: 1 }}
            />

            <Menu withinPortal position="bottom-end">
              <Menu.Target>
                <ActionIcon
                  variant="filled"
                  className="products-association-modal__actions"
                  size="lg"
                  disabled={isLoading || isLoadingMutation}
                >
                  <Icon icon="arrow-down-arrow-up" />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Label>{t('product.products-association.sort.by')}</Menu.Label>

                <Menu.Item onClick={() => handleSort('name')}>
                  {t('product.products-association.sort.name')}
                </Menu.Item>
                <Menu.Item onClick={() => handleSort('dateCreated')}>
                  {t('product.products-association.sort.dateAdded')}
                </Menu.Item>
                <Menu.Item onClick={() => handleSort('dateModified')}>
                  {t('product.products-association.sort.dateModified')}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>

          {isLoading && <Loading />}
          {!isLoading && productsResponse && (
            <>
              <div>
                {filteredProducts.length === 0 && (
                  <Flex direction="column" gap={8} align="center" mt="1rem">
                    <Icon icon="magnifying-glass" color={'$color-text-muted'} size="xl" />
                    <Text size="lg">
                      {t('product.products-association.no-results.title')}
                    </Text>
                    <Text className="products-association-modal__subtitle">
                      {t('product.products-association.no-results.subtitle')}
                    </Text>
                  </Flex>
                )}
                {filteredProducts.length > 0 && (
                  <ScrollArea h={400}>
                    <Table>
                      <thead>
                        <tr>
                          <th>
                            <Checkbox
                              checked={selectAll}
                              indeterminate={selectedProducts.length > 0 && !selectAll}
                              onChange={handleSelectAllChange}
                            />
                          </th>
                          <th className="add-assets-to-product__table-header">
                            {t('product.products-association.header.product-name')}
                          </th>
                          <th className="add-assets-to-product__table-header">
                            {t('product.products-association.header.version')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredProducts
                          .filter((product) =>
                            product.name
                              ? product.name
                                  .toLocaleLowerCase()
                                  .includes(productNameFilter.value.toLowerCase())
                              : null,
                          )
                          .map((product, index) => (
                            <tr key={index}>
                              <td>
                                <Checkbox
                                  checked={selectedProducts.some(
                                    (p) => p._id === product._id,
                                  )}
                                  onChange={() => handleCheckboxChange(product)}
                                />
                              </td>
                              <td className="products-association-modal__name-column">
                                {product.name}
                              </td>
                              <td className="products-association-modal__version">
                                {product.version}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </ScrollArea>
                )}
              </div>

              <Flex justify="flex-end" mt={8} mb={16}>
                <Text className="products-association-modal__assets-count">
                  {selectedProducts.length > 0
                    ? t('product.products-association.messages.productSelected', {
                        count: selectedProducts.length,
                      })
                    : null}
                </Text>
              </Flex>

              <Flex gap={8} justify="flex-end">
                <Button
                  disabled={isLoading || isLoadingMutation}
                  variant="default"
                  onClick={onCloseModal}
                >
                  {t('global.cancel')}
                </Button>
                <Button
                  disabled={isLoading || isLoadingMutation || !selectedProducts.length}
                  onClick={() => {
                    updateSelectedProductsCallback(selectedProducts.length);
                    submit(assetsIds, selectedProducts);
                  }}
                >
                  {t('global.add')}
                </Button>
              </Flex>
            </>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export default ProductsAssociationModal;
