import { useMemo } from 'react';
import { Page, Text, Image, View, Document, Link as PdfLink } from '@react-pdf/renderer';
import { InterfaceVulnerabilityWithVexInfo } from '../../../types/FullInterfaces';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import appConfig from '../../../configs/appConfig';
import IconForPdf from '../../../components/IconForPdf';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import capitalizeWords from '../../../lib/capitalizeWords';
import { InterfaceAssetComponent } from '@manifest-cyber/types/interface/dbTables';
import { VulnImpactApiResponse } from '../../../hooks/queries/useFetchVulnImpact';
import { pdfStyles } from './VulnPdfStyles';
import { extractScoreSeverity } from '../../../components/CvssScore/CvssScore';

interface VulnPdfExportDocumentProps {
  fetchedVulnData: InterfaceVulnerabilityWithVexInfo | undefined;
  isFetching: boolean;
  vulnImpact: VulnImpactApiResponse | undefined;
  exportGraphImageBase64: string | null;
}

export const VulnPdfExportDocument = ({
  fetchedVulnData,
  isFetching,
  vulnImpact,
  exportGraphImageBase64,
}: VulnPdfExportDocumentProps) => {
  const { t } = useTranslation();

  const distinctVulnComponentsData = useMemo(() => {
    if (!vulnImpact?.impactedComponents) {
      return null;
    }
    const componentsByCoordinates: Record<string, InterfaceAssetComponent> = {};
    vulnImpact.impactedComponents.forEach((impactedComponent) => {
      const coordinates = `${impactedComponent.componentPackageUrlNoVersion}@${impactedComponent.componentPackageVersion}`;
      componentsByCoordinates[coordinates] = impactedComponent;
    });
    return Object.values(componentsByCoordinates);
  }, [vulnImpact?.impactedComponents]);

  const severity = useMemo(() => {
    if (!fetchedVulnData) return null;
    const { severity } = extractScoreSeverity(fetchedVulnData);
    return severity;
  }, [fetchedVulnData]);

  return (
    <Document title={`${fetchedVulnData?.cveId}`}>
      {!isFetching && fetchedVulnData && (
        <Page size="LETTER" style={pdfStyles.page}>
          {/* Header, Fixed to All Pages */}
          <View style={pdfStyles.header} fixed>
            <View style={pdfStyles.headerInner}>
              <Text style={pdfStyles.headerLeft}>
                <Image style={pdfStyles.headerImage} src="/pdf-logo.png" />
              </Text>
              <View style={pdfStyles.headerRight}>
                <Text style={pdfStyles.headerRightTitle}>
                  {t('pdf-export.vulnerability.title')}
                </Text>
                <Text style={pdfStyles.headerRightSubtitle}>
                  {t('pdf-export.vulnerability.subtitle')}&nbsp;
                  {DateTime.fromISO(`${fetchedVulnData?.dateModified}`).toLocaleString(
                    DateTime.DATETIME_FULL,
                  )}
                </Text>
              </View>
            </View>
            <View style={pdfStyles.headerBackground}>
              <Image
                style={pdfStyles.headerBackgroundImage}
                src="/pdf-header-background.png"
              />
            </View>
          </View>
          <View style={pdfStyles.headerLeftOverlay} fixed>
            <Image style={pdfStyles.headerImage2} src="/pdf-logo.png" />
          </View>
          <View style={pdfStyles.headerRightOverlay} fixed>
            <Text style={pdfStyles.headerRightTitle}>
              {t('pdf-export.vulnerability.title')}
            </Text>
            <Text style={pdfStyles.headerRightSubtitle}>
              {t('pdf-export.vulnerability.subtitle')}&nbsp;
              {DateTime.fromISO(`${fetchedVulnData?.dateModified}`).toLocaleString(
                DateTime.DATETIME_FULL,
              )}
            </Text>
          </View>
          <View style={pdfStyles.pageContent}>
            {/* CVE Summary Section */}
            <View style={pdfStyles.section}>
              <View style={pdfStyles.overviewSection}>
                <View style={pdfStyles.overviewSectionLeft}>
                  <View style={pdfStyles.overviewSectionTitle}>
                    <PdfLink
                      src={`${appConfig?.siteURL}/vulnerability/${fetchedVulnData?.cveId}`}
                    >
                      <Text style={pdfStyles.linkOverride}>{fetchedVulnData?.cveId}</Text>
                    </PdfLink>
                  </View>
                  <View style={pdfStyles.overviewSectionLeftPublish}>
                    <Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {t('pdf-export.vulnerability.source')}
                      </Text>{' '}
                      {fetchedVulnData.sources?.join(', ')}
                      &nbsp;&nbsp;|&nbsp;&nbsp;
                      <Text style={{ fontWeight: 'bold' }}>
                        {t('pdf-export.vulnerability.published')}
                      </Text>{' '}
                      {DateTime.fromISO(`${fetchedVulnData?.publishDate}`).toLocaleString(
                        DateTime.DATETIME_MED,
                      )}
                    </Text>
                  </View>
                  <View style={pdfStyles.overviewSectionLeftStats}>
                    <View style={pdfStyles.overviewSectionLeftStatsBlock}>
                      <Text style={pdfStyles.overviewSectionLeftStatsBlockTitle}>
                        {t('pdf-export.vulnerability.stats.assetsAffected')}
                      </Text>
                      <Text style={pdfStyles.overviewSectionLeftStatsBlockValue}>
                        {vulnImpact?.countImpactedAssets || 0}
                      </Text>
                    </View>
                    <View style={pdfStyles.overviewSectionLeftStatsBlock}>
                      <Text style={pdfStyles.overviewSectionLeftStatsBlockTitle}>
                        {t('pdf-export.vulnerability.stats.cvssScore')}
                      </Text>
                      {!!severity && (
                        <Text style={pdfStyles.overviewSectionLeftStatsBlockValue}>
                          {capitalizeWords(severity?.toLowerCase())}
                        </Text>
                      )}
                      {!severity && (
                        <Text style={pdfStyles.overviewSectionLeftStatsBlockValue}>
                          {t('global.na')}
                        </Text>
                      )}
                    </View>
                    <View style={pdfStyles.overviewSectionLeftStatsBlock}>
                      <Text style={pdfStyles.overviewSectionLeftStatsBlockTitle}>
                        {t('pdf-export.vulnerability.stats.exploitability')}
                      </Text>
                      {fetchedVulnData.epssScore && (
                        <Text style={pdfStyles.overviewSectionLeftStatsBlockValue}>
                          {Math.round((fetchedVulnData.epssScore || 0) * 100)}%
                        </Text>
                      )}
                      {!fetchedVulnData.epssScore && (
                        <Text style={pdfStyles.overviewSectionLeftStatsBlockValue}>
                          {t('global.na')}
                        </Text>
                      )}
                    </View>
                    <View style={pdfStyles.overviewSectionLeftStatsBlockLast}>
                      <Text style={pdfStyles.overviewSectionLeftStatsBlockTitle}>
                        {t('pdf-export.vulnerability.stats.epssPercentage')}
                      </Text>
                      {fetchedVulnData.epssPercentile && (
                        <Text style={pdfStyles.overviewSectionLeftStatsBlockValue}>
                          {Math.round((fetchedVulnData.epssPercentile || 0) * 100)}%
                        </Text>
                      )}
                      {!fetchedVulnData.epssPercentile && (
                        <Text style={pdfStyles.overviewSectionLeftStatsBlockValue}>
                          {t('global.na')}
                        </Text>
                      )}
                    </View>
                  </View>
                  {/* If on KEV */}
                  {fetchedVulnData?.kevData?.inKEV && (
                    <>
                      <View style={pdfStyles.overviewSectionLeftKevTitle}>
                        <Text style={pdfStyles.overviewSectionLeftKevTitleText}>
                          {t('pdf-export.vulnerability.kevTitle')}
                        </Text>
                        <View style={pdfStyles.overviewSectionLeftKevLine} />
                      </View>
                      <View>
                        <Text style={pdfStyles.overviewSectionLeftDescription}>
                          {t('pdf-export.vulnerability.kevIntro')}
                        </Text>
                        <Text style={pdfStyles.overviewSectionLeftDescription}>
                          <Text style={pdfStyles.bold}>
                            {t('page.vulnerability.kev-card.required-action')}:
                          </Text>
                          &nbsp;{fetchedVulnData?.kevData?.kevRequiredAction}
                        </Text>
                        <Text style={pdfStyles.overviewSectionLeftDescription}>
                          <Text style={pdfStyles.bold}>
                            {t('page.vulnerability.kev-card.due-by')}:
                          </Text>
                          &nbsp;
                          {DateTime.fromISO(
                            fetchedVulnData?.kevData?.kevDueDate ?? '',
                          ).toLocaleString(DateTime.DATE_MED)}
                        </Text>
                      </View>
                    </>
                  )}

                  {/* General Description */}
                  <View style={pdfStyles.overviewSectionLeftDescriptionTitle}>
                    <Text style={pdfStyles.overviewSectionLeftDescriptionTitleText}>
                      {t('pdf-export.vulnerability.description')}
                    </Text>
                    <View style={pdfStyles.overviewSectionLeftDescriptionLine} />
                  </View>
                  <View>
                    <Text style={pdfStyles.overviewSectionLeftDescription}>
                      {fetchedVulnData?.description ||
                        t('pdf-export.vulnerability.no-description')}
                    </Text>
                  </View>
                </View>

                <View style={pdfStyles.overviewSectionRight}>
                  <View style={pdfStyles.overviewSectionRecommendation}>
                    <Text>{t('pdf-export.vulnerability.recommendation')}</Text>
                    <Text style={pdfStyles.overviewSectionRecommendationText}>
                      {capitalizeWords(`${fetchedVulnData?.recommendedAction}`)}
                    </Text>
                  </View>
                  {exportGraphImageBase64 && exportGraphImageBase64?.length > 0 && (
                    <Image src={exportGraphImageBase64} />
                  )}
                </View>
              </View>
            </View>

            <View style={pdfStyles.section}>
              <Text style={pdfStyles.overviewSectionLeftDescriptionTitleText}>
                {t('pdf-export.vulnerability.impacted-components', {
                  count: distinctVulnComponentsData?.length || 0,
                })}
              </Text>
              <View style={pdfStyles.impactedComponentsList}>
                {distinctVulnComponentsData?.map((component) => (
                  <Text
                    style={pdfStyles.impactedComponentsListItem}
                    key={component._id?.toString()}
                  >
                    {component?.name || component.componentPackageUrlNoVersion}&nbsp;
                    <Text style={pdfStyles.bold}>
                      v{component.componentPackageVersion}
                    </Text>
                  </Text>
                ))}
              </View>
            </View>

            <View style={pdfStyles.sectionNoBotPad}>
              <Text style={pdfStyles.overviewSectionLeftDescriptionTitleText}>
                {t('pdf-export.vulnerability.impacted-assets', {
                  count: vulnImpact?.impactedAssets?.length || 0,
                })}
              </Text>
              <View style={pdfStyles.impactedAssetsList}>
                <View style={pdfStyles.impactedAssetsListItemHeader}>
                  <Text style={pdfStyles.impactedAssetsListItemColumnA}>
                    {t('pdf-export.vulnerability.name')}
                  </Text>
                  <Text style={pdfStyles.impactedAssetsListItemColumnB}>
                    {t('pdf-export.vulnerability.version')}
                  </Text>
                  <Text style={pdfStyles.impactedAssetsListItemColumnC}>
                    {t('pdf-export.vulnerability.type')}
                  </Text>
                </View>
                {vulnImpact?.impactedAssets?.map((asset: any) => (
                  <View
                    style={pdfStyles.impactedAssetsListItem}
                    key={asset._id?.toString()}
                  >
                    <Text style={pdfStyles.impactedAssetsListItemColumnA}>
                      {asset?.name || asset?.packageUrlNoVersion}
                    </Text>
                    <Text style={pdfStyles.impactedAssetsListItemColumnB}>
                      {asset?.version || asset?.packageVersion}
                    </Text>
                    <Text style={pdfStyles.impactedAssetsListItemColumnC}>
                      {asset?.type}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
          {/* Page Number, Fixed to All Pages */}
          <Text
            style={pdfStyles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${t('pdf-export.page-number', {
                current: pageNumber,
                total: totalPages,
              })}`
            }
            fixed
          />
        </Page>
      )}
      {!isFetching && !fetchedVulnData && (
        <Page size="LETTER" style={pdfStyles.page}>
          <View>
            <Text>{t('pdf-export.vulnerability.failed-fetch')}</Text>
          </View>
        </Page>
      )}
      {isFetching && !fetchedVulnData && (
        <Page size="LETTER" style={pdfStyles.page}>
          <View>
            <Text>{t('pdf-export.vulnerability.pending-fetch')}</Text>
          </View>
        </Page>
      )}
    </Document>
  );
};
