import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import { Box, Flex, Radio, Stack } from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosProxy } from '../../../api/axiosProxy/axiosProxy';
import { Logger } from '../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { ManifestNotifications } from '../../../hooks/utils/useNotifications';
import { rejectFailedRequestOrEmptyData } from '../../../lib/request/rejectFailedRequest/rejectFailedRequest';
import ManifestModal from '../../ManifestModal/ManifestModal';
import { useAuth } from '../../../hooks/useAuth';
import { TextComponent } from '@manifest-cyber/design-system/lib/components/TextComponent';

interface DownloadLicenseReportModalProps {
  onClose: () => void;
  product: InterfaceOrganizationProduct;
}

export const DownloadLicenseReportModal = ({
  onClose,
  product,
}: DownloadLicenseReportModalProps) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [selectedFilter, setSelectedFilter] = useState<string>('getIssuesOnly');

  const downloadLicenseReportHandler = async () => {
    if (!product._id) {
      return;
    }

    try {
      const response = await AxiosProxy.post({
        url: 'file-request',
        body: {
          type: 'licenses',
          format: 'csv',
          targetType: 'product',
          targetIds: [product._id.toString()],
          requestorId: user?._id.toString(),
          metadata: {
            getIssuesOnly: selectedFilter === 'getIssuesOnly',
          },
        },
      });

      await rejectFailedRequestOrEmptyData(response);

      ManifestNotifications.showInfoNotification({
        title: t('product.downloadNotifications.download-license-report.successTitle'),
        message: t(
          'product.downloadNotifications.download-license-report.successDescription',
        ),
      });
    } catch (error) {
      Logger.error('Error downloading license report', error);
      ManifestNotifications.showErrorNotification({
        title: t('product.downloadNotifications.download-license-report.errorTitle'),
        message: t(
          'product.downloadNotifications.download-license-report.errorDescription',
        ),
      });
    }
  };

  const title = t('product.download-license-modal.title');
  const subtitle = t('product.download-license-modal.description');
  const secondaryAction = {
    label: t('product.download-license-modal.cancel'),
    onClick: onClose,
  };
  const primaryActionLabel = t('product.download-license-modal.download');

  return (
    <ManifestModal
      size="md"
      opened
      withCloseButton={true}
      onClose={onClose}
      title={title}
      subtitle={subtitle}
      body={
        <Flex w="100%" direction="column" gap="12px">
          <Radio.Group
            required
            size="md"
            value={selectedFilter}
            onChange={setSelectedFilter}
          >
            <Stack mt="xs" spacing="xs">
              <Radio
                key="getIssuesOnly"
                value="getIssuesOnly"
                label={
                  <Box>
                    <Box fw={500}>{t('product.download-license-modal.issues-only')}</Box>
                    <TextComponent variant="bodySmall" color="muted">
                      {t('product.download-license-modal.issues-only-description')}
                    </TextComponent>
                  </Box>
                }
              />
              <Radio
                key="allLicenses"
                value="allLicenses"
                label={
                  <Box>
                    <Box fw={500}>{t('product.download-license-modal.all-licenses')}</Box>
                    <TextComponent variant="bodySmall" color="muted">
                      {t('product.download-license-modal.all-licenses-description')}
                    </TextComponent>
                  </Box>
                }
              />
            </Stack>
          </Radio.Group>
        </Flex>
      }
      primaryAction={{
        label: primaryActionLabel,
        onClick: async () => {
          await downloadLicenseReportHandler();
          onClose();
        },
        isDisabled: !selectedFilter,
      }}
      secondaryAction={secondaryAction}
    />
  );
};
