import { Button, Flex, Select } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionableCard } from '../../../../../components/ActionableCard/ActionableCard';
import BasicSearch from '../../../../../components/BasicSearch';
import Icon from '../../../../../components/Icon/Icon';
import ManifestModal from '../../../../../components/ManifestModal/ManifestModal';
import { usePutAlert } from '../../../../../hooks/mutations/usePutAlert';
import { useNotifications } from '../../../../../hooks/utils/useNotifications';
import { sortByLasUpdated } from '../../../../../lib/array/sortByLastUpdated';
import { useFetchSbomAlerts } from '../../hooks/useFetchSbomAlerts';
import { MappedSbomAlert } from '../../types/SBOMAlerts.interface';
import { getSortOptions } from '../../utils/getSortOptions';
import styles from './AlertList.module.scss';
import AlertsListSkeleton from './AlertListSkeleton/AlertListSkeleton';
import AlertRow from './AlertRow/AlertRow';

interface ModalProps {
  title: string;
  description: string;
  onConfirm: () => void;
  confirmLabel: string;
}

interface NotificationProps {
  message: string;
  type: 'error' | 'success';
}

const filterAlerts = (alerts: MappedSbomAlert[], query: string) =>
  alerts.filter(({ name }) =>
    name.toLocaleLowerCase().includes(query.toLocaleLowerCase()),
  );

function SbomAlertsList() {
  const [filteredAlerts, setFilteredAlerts] = useState<MappedSbomAlert[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modal, setModal] = useState<ModalProps | undefined>(undefined);
  const { mutateAsync: updateAlert, isLoading: isUpdatingAlert } = usePutAlert();
  const {
    data: alertsResponse,
    isLoading: isLoadingAlerts,
    error,
    refetch,
  } = useFetchSbomAlerts();
  const alertSortOrders = getSortOptions(t);
  const { success: showSuccessNotification, error: showErrorNotification } =
    useNotifications();

  useEffect(() => {
    if (isLoadingAlerts || !alertsResponse) {
      return;
    }

    if (searchTerm) {
      setFilteredAlerts(filterAlerts(alertsResponse.alerts.mappedAlerts, searchTerm));
    } else {
      setFilteredAlerts(alertsResponse.alerts.mappedAlerts);
    }
  }, [isLoadingAlerts, alertsResponse?.alerts]);

  const showNotification = (notification: NotificationProps) => {
    if (notification.type === 'success') {
      showSuccessNotification({
        message: notification.message,
      });
    } else {
      showErrorNotification({
        message: notification.message,
      });
    }
  };

  const handleSearch = (searchValue: string) => {
    if (alertsResponse?.alerts) {
      setSearchTerm(searchValue);

      if (!searchValue) {
        setFilteredAlerts(alertsResponse.alerts.mappedAlerts);
        return;
      }

      const filteredList = filterAlerts(alertsResponse.alerts.mappedAlerts, searchValue);
      setFilteredAlerts(filteredList);
    }
  };

  const handleSortChange = (
    value: ReturnType<typeof getSortOptions>[number]['value'],
  ) => {
    let orderedList = filteredAlerts;
    switch (value) {
      case 'ascending':
        orderedList = filteredAlerts.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'descending':
        orderedList = filteredAlerts.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case 'lastUpdated':
      default:
        orderedList = sortByLasUpdated(filteredAlerts);
        break;
    }

    setFilteredAlerts([...orderedList]);
  };

  const updateAlertStatus = async ({
    isActive,
    id,
  }: {
    isActive: boolean;
    id: string;
  }) => {
    const rawAlert = alertsResponse?.alerts.rawAlerts.find(({ _id }) => _id === id);

    if (!rawAlert) {
      return;
    }

    const result = await updateAlert({
      alert: {
        ...rawAlert,
        active: isActive,
      },
    });

    if (result.success) {
      const message = isActive
        ? t('page.userSettings.sbomUploadAlerts.enabledAlert')
        : t('page.userSettings.sbomUploadAlerts.disabledAlert');
      showNotification({
        message,
        type: 'success',
      });
    } else {
      showNotification({
        message: t('error.general.unexpectedErrorTryAgain'),
        type: 'error',
      });
    }

    closeModal();
  };

  const deleteAlert = async (alertId: string) => {
    const rawAlert = alertsResponse?.alerts.rawAlerts.find(({ _id }) => _id === alertId);

    if (!rawAlert) {
      return;
    }

    const response = await updateAlert({
      alert: {
        ...rawAlert,
        active: false,
        dateDeleted: new Date().toISOString(),
      },
    });

    if (!response.success) {
      showNotification({
        message: t('error.general.unexpectedErrorTryAgain'),
        type: 'error',
      });
    }

    closeModal();
  };

  const onClickToggle = (id: string, isActive: boolean) => {
    if (isActive) {
      setModal({
        title: t('page.userSettings.sbomUploadAlerts.disableModal.title'),
        description: t('page.userSettings.sbomUploadAlerts.disableModal.body'),
        onConfirm: () => {
          updateAlertStatus({ isActive: false, id });
        },
        confirmLabel: t('global.yesContinue'),
      });
      return;
    }

    updateAlertStatus({ isActive: true, id });
  };

  const gotToCreate = async () => {
    navigate('/settings/sbom-upload-alerts/create');
  };

  const goToDetail = (id: string) => {
    navigate(`/settings/sbom-upload-alerts/view/${id}`);
  };

  const goToEdit = (id: string) => {
    navigate(`/settings/sbom-upload-alerts/edit/${id}`);
  };

  const closeModal = () => {
    setModal(undefined);
  };

  const alertsQuantity = alertsResponse?.alerts.mappedAlerts.length || 0;
  const showLoadingSkeleton = !error && (isLoadingAlerts || !alertsResponse);
  const showNotFoundSearch = filteredAlerts.length === 0 && searchTerm !== '';
  const showEmptyList =
    !error &&
    !isLoadingAlerts &&
    alertsQuantity === 0 &&
    alertsResponse?.alerts.mappedAlerts.length === 0 &&
    filteredAlerts.length === 0 &&
    !searchTerm;
  const showList = !isLoadingAlerts && filteredAlerts.length > 0;

  return (
    <>
      <div className={styles.pageSbomUploadAlerts}>
        <div className={styles.headerContainer}>
          <div className={styles.headerItems}>
            <div className={styles.header}>
              {t('page.userSettings.sbomUploadAlerts.header')}
            </div>
            <div className={styles.subHeader}>
              {t('page.userSettings.sbomUploadAlerts.subHeader')}
            </div>
          </div>
          {!showEmptyList && (
            <div>
              <Button onClick={gotToCreate} leftIcon={<Icon icon="plus" />}>
                {t('page.userSettings.sbomUploadAlerts.createAlert')}
              </Button>
            </div>
          )}
        </div>
        {!showEmptyList && (
          <div className={styles.alertsSearchSort}>
            <BasicSearch
              handleSearch={handleSearch}
              minCharCount={1}
              placeholder={t('page.userSettings.sbomUploadAlerts.searchAlert')}
            />
            <div className={styles.alertsSort}>
              <Select
                defaultValue="lastUpdated"
                data={alertSortOrders}
                onChange={handleSortChange}
              />
            </div>
          </div>
        )}
        <div className={styles.allAlertsContainer}>
          {Boolean(error) && (
            <Flex justify={'center'}>
              <ActionableCard
                title={t('error.general.errorLoadingData')}
                subtitle={t('error.general.errorLoadingDataSubtitle')}
                Icon={<Icon icon="circle-exclamation" size="lg" />}
                primaryAction={{
                  label: t('global.retry'),
                  onClick: () => {
                    refetch();
                  },
                  isLoading: isLoadingAlerts,
                }}
              />
            </Flex>
          )}
          {showNotFoundSearch && (
            <Flex justify={'center'}>
              <ActionableCard
                title={t('page.userSettings.sbomUploadAlerts.noAlerts', {
                  term: searchTerm,
                })}
                subtitle={t('page.userSettings.sbomUploadAlerts.tryOtherTerm')}
                Icon={<Icon icon="magnifying-glass" size="lg" />}
              />
            </Flex>
          )}

          <div className={styles.alertsList}>
            {showLoadingSkeleton && <AlertsListSkeleton />}
            {showEmptyList && (
              <div className={styles.emptyStateContainer}>
                <Flex justify={'center'}>
                  <ActionableCard
                    Icon={<Icon icon="bell" size="lg" />}
                    title={t('page.userSettings.sbomUploadAlerts.emptyState.title')}
                    primaryAction={{
                      label: t(
                        'page.userSettings.sbomUploadAlerts.emptyState.buttonLabel',
                      ),
                      icon: <Icon icon="plus" />,
                      onClick: gotToCreate,
                    }}
                  />
                </Flex>
              </div>
            )}
            {showList &&
              filteredAlerts.map((alert) => (
                <AlertRow
                  key={alert.id}
                  alert={alert}
                  onClickDelete={(id: string) => {
                    setModal({
                      title: t('page.userSettings.sbomUploadAlerts.deleteModal.title'),
                      description: t(
                        'page.userSettings.sbomUploadAlerts.deleteModal.body',
                      ),
                      onConfirm: () => deleteAlert(id),
                      confirmLabel: t('global.delete'),
                    });
                  }}
                  onClickEdit={goToEdit}
                  onClickToggle={onClickToggle}
                  onClickDetail={goToDetail}
                />
              ))}
          </div>
        </div>
      </div>

      {modal && (
        <ManifestModal
          opened
          withCloseButton
          onClose={closeModal}
          title={modal.title}
          body={modal.description}
          primaryAction={{
            label: modal.confirmLabel,
            onClick: modal.onConfirm,
            isLoading: isUpdatingAlert,
            color: 'red',
          }}
          secondaryAction={{
            label: t('global.cancel'),
            onClick: closeModal,
          }}
        />
      )}
    </>
  );
}

export default SbomAlertsList;
