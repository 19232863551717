import { InterfaceAssetsFilters } from '../state/useFilterState';

const filterMapping: Record<
  keyof InterfaceAssetsFilters,
  { field: string; transform?: (value: any) => any }
> = {
  filterOnlyHasVulns: { field: 'onlyHasVulns' },
  filterOnlyHasCriticalVulns: { field: 'onlyCriticalVulns' },
  filterOnlyHasLicenseIssues: { field: 'onlyHasLicenseIssues' },
  filterOnlyFirstParty: { field: 'onlyFirstParty' },
  filterOnlyThirdParty: { field: 'onlyThirdParty' },
  filterIsActive: { field: 'onlyActiveAssets' },
  filterSharedToPortal: { field: 'sharedOnPortal' },
  filterRiskScore: { field: 'riskScore' },
  filterCveIds: { field: 'cveIds' },
  filterCvssScore: { field: 'cvssScore', transform: (val: number) => [val, 10] },
  filterAssetAuthor: { field: 'assetAuthor' },
  filterAssetSupplier: { field: 'assetSupplier' },
  filterAssetTypes: { field: 'assetType' },
  filterLicenseTypes: { field: 'licenseIssueType' },
  filterLabels: { field: 'labels' },
  filterAssetName: { field: 'assetName', transform: (val: string) => [val.trim()] },
};

export const getCurrentFilters = (filters: InterfaceAssetsFilters) => {
  return Object.entries(filterMapping).reduce(
    (acc, [filterKey, { field, transform }]) => {
      // Get the filter value from your filters state
      const value = filters[filterKey as keyof InterfaceAssetsFilters];

      // Only include the filter if it's "active"
      // For booleans: include if true
      // For arrays: include if non-empty
      // For numbers: include if not 0
      // For strings: include if not empty (ignoring whitespace)
      if (
        (typeof value === 'boolean' && value) ||
        (Array.isArray(value) && value.length > 0) ||
        (typeof value === 'number' && value !== 0) ||
        (typeof value === 'string' && value.trim() !== '')
      ) {
        acc.push({
          field,
          value: transform ? transform(value) : value,
        });
      }
      return acc;
    },
    [] as { field: string; value: any }[],
  );
};
