import { Grid } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Loading from '../../components/Loading';
import ProductOverviewAbout from '../../components/Product/Overview/About';
import ProductOverviewHighlights from '../../components/Product/Overview/Highlights';
import ProductOverviewTopAssets from '../../components/Product/Overview/TopAssets';
import ProductOverviewTopVulns from '../../components/Product/Overview/TopVulns';
import { useFetchProductOverview } from '../Products/products/useFetchProductOverview';
import styles from './ProductOverview.module.scss';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import { useFetchOrganization } from '../../hooks/queries/useFetchOrganization';
import { GenericEmptyState } from '../../components/GenericEmptyState/GenericEmptyState.component';
import { useOrganizationFeatureFlag } from '../../hooks/useOrganizationFeatureFlag';
import { EmptyStateProductOverviewTopAssets } from '../../components/Product/Overview/TopAssets/TopAssetsEmptyState.component';

export const ProductOverview = () => {
  const { t } = useTranslation();
  let { productId } = useParams<{ productId: string }>();

  const [currentOrgId] = useOrganizationId();
  const { data: fetchedCurrentOrganization, isLoading: isLoadingCurrentOrganization } =
    useFetchOrganization({ organizationId: currentOrgId });
  const { isEnabled: productInProductsFeatureFlag, isLoading: isFeatureFlagLoading } =
    useOrganizationFeatureFlag('product-in-products');

  const {
    data: productOverviewResponse,
    isLoading,
    isSuccess,
    isError,
  } = useFetchProductOverview({
    productId,
  });

  if (isError) {
    return (
      <ul className="page-errors anim-slideInUpShort">
        {productOverviewResponse?.errors?.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
        <li>{t('page.product.fetchProductError')}</li>
      </ul>
    );
  }

  if (isLoading || isLoadingCurrentOrganization) {
    return <Loading />;
  }

  if (isSuccess && productOverviewResponse.data) {
    const {
      countVulnerabilities,
      countHighRiskAssets,
      description,
      top5Assets,
      top5Vulns,
      labels,
    } = productOverviewResponse.data;

    // Only show the "empty product" UI if the productInProductsFeatureFlag is enabled
    if (productInProductsFeatureFlag) {
      if (
        countHighRiskAssets === 0 &&
        !countVulnerabilities &&
        (!top5Assets || top5Assets.length === 0) &&
        (!top5Vulns || top5Vulns.length === 0)
      ) {
        return (
          <GenericEmptyState
            titleKey="page.product.startAddingToYourProduct"
            buttons={[
              { textKey: 'page.product.addAssets', navigateTo: '/assets' },
              { textKey: 'page.product.addProducts', navigateTo: '/products' },
            ]}
          />
        );
      }
    }

    return (
      <div className={styles.product_overview}>
        <Grid>
          <Grid.Col span={12} md={9}>
            <ProductOverviewHighlights
              countHighRiskAssets={countHighRiskAssets}
              inKevVulns={countVulnerabilities?.inKEV || 0}
              mitigateVulns={countVulnerabilities?.mitigate || 0}
            />
            <Grid align="stretch" pt={6}>
              <Grid.Col span={12} md={6}>
                {!!top5Assets.length ? (
                  <ProductOverviewTopAssets top5Assets={top5Assets} />
                ) : productInProductsFeatureFlag ? (
                  <EmptyStateProductOverviewTopAssets />
                ) : null}
              </Grid.Col>
              <Grid.Col span={12} md={6}>
                {!!top5Vulns.length && <ProductOverviewTopVulns top5Vulns={top5Vulns} />}
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={12} md={3} mb={12}>
            <ProductOverviewAbout description={description} labels={labels} />
          </Grid.Col>
        </Grid>
      </div>
    );
  }

  return null;
};
