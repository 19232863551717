import { useTranslation } from 'react-i18next';
import ClickableRegion from '../../../components/ClickableRegion';
import { InterfaceVulnerabilityWithVexInfoAndMitigations } from '../../../types/FullInterfaces';
import { FixesList } from './FixesTabComponents/FixesList';
import Icon from '../../../components/Icon';

interface FixesTabProps {
  references: InterfaceVulnerabilityWithVexInfoAndMitigations['references'];
  mitigation: InterfaceVulnerabilityWithVexInfoAndMitigations['mitigationFlat'];
  notes: string[];
}

export const FixesTab = ({ mitigation, references, notes }: FixesTabProps) => {
  const { t } = useTranslation();

  const hasFoundFixes = mitigation && mitigation.length > 0;
  const hasNotes = notes && notes.length > 0;
  const list: FixesList[] = [];
  if (hasFoundFixes) list.push({ title: '', elements: mitigation });
  if (hasNotes)
    list.push({
      title: t('page.vulnerability.details.notes'),
      elements: notes,
    });

  return (
    <div className="fixes-tab anim-slideInDownShort">
      <FixesList list={list} />
    </div>
  );
};
