import { ManifestApiResponse } from '@manifest-cyber/types/interface/apiResponse';
import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../../api/axiosProxy/axiosProxy';
import { getComponentName } from '../../../api/componentsApi/components.mapper';
import { rejectFailedRequestOrEmptyData } from '../../../lib/request/rejectFailedRequest/rejectFailedRequest';

export interface LicenseIssueWithAffectedComponents {
  affectedComponents?: {
    packageUrlNoVersion: string;
    version: string;
    name: string;
    organizationComponentId: string;
  }[];
  fullName: string;
  shortName: string;
  licenseType: string;
  id: string;
}

export type GetLicenseIssuesByAssetResponse = ManifestApiResponse<{
  licenseIssues: LicenseIssueWithAffectedComponents[];
}>;

interface Params {
  assetId: string;
}

interface Options {
  enabled?: boolean;
}

export const useFetchAssetLicenseIssues = ({ assetId }: Params, option?: Options) => {
  let fetchUrl = `license-issues/asset/${assetId}`;

  return useQuery({
    enabled: option?.enabled,
    queryKey: ['asset-license-issues', { assetId }, `/${fetchUrl}`],
    queryFn: async () => {
      const response = await AxiosProxy.get({
        url: fetchUrl,
      });

      const validatedResponse =
        await rejectFailedRequestOrEmptyData<GetLicenseIssuesByAssetResponse['data']>(
          response,
        );

      return validatedResponse.data.licenseIssues.map((issue) => ({
        ...issue,
        affectedComponents:
          issue.affectedComponents?.map((component) => ({
            ...component,
            name: getComponentName(component.name),
          })) || [],
      }));
    },
  });
};
