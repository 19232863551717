import { Box } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { AssetsTable } from '../../components/DataTables/AssetsTable';
import Loading from '../../components/Loading';
import { ManifestTabs } from '../../components/ManifestTabs/ManifestTabs';
import NumberBadge from '../../components/NumberBadge/NumberBadge';
import { ProductHeader } from '../../components/Product/ProductHeader/ProductHeader';
import { ProtectedRoute } from '../../components/ProtectedRoute/ProtectedRoute';
import { useAuth } from '../../hooks/useAuth';
import { useOrganizationFeatureFlag } from '../../hooks/useOrganizationFeatureFlag';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import { FailedApiResponse } from '../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { Logger } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import NoMatch404 from '../NoMatch404';
import ProductOverview from '../ProductOverview';
import { useFetchProduct } from '../Products/products/useFetchProduct';
import { ProductVulnerabilitiesTab } from './ProductVulnerabilities/ProductVulnerabilities.tab';

export const Product = () => {
  const { t } = useTranslation();
  let { productId } = useParams<{ productId: string }>();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const activeTab = pathname.split('/').pop();
  const { isEnabled: productInProductsFeatureFlag } =
    useOrganizationFeatureFlag('product-in-products');

  const {
    isLoading,
    error,
    data: productResponse,
  } = useFetchProduct({
    productId: productId,
  });

  const { user } = useAuth();
  const [currentOrgData, setCurrentOrgData] = useState<any>(null);
  const [currentOrgId] = useOrganizationId();
  const [orgName, setOrgName] = useState('');
  const orgId: any = useRef(null);

  useEffect(() => {
    if (currentOrgData?.name) {
      setOrgName(currentOrgData.name);
    }
  }, [currentOrgData]);

  useEffect(() => {
    const fetchOrgData = async (currentOrgId?: string) => {
      const getOrgData = await AxiosProxy.get({ url: 'organization' });
      if (getOrgData && getOrgData?.success && getOrgData?.data[0]) {
        setCurrentOrgData(getOrgData?.data[0]);
      }

      orgId.current = currentOrgId;
    };

    fetchOrgData();
  }, [currentOrgId, user]);

  if (
    (error as unknown as FailedApiResponse)?.errors?.some(
      (item) => item === 'error.products.not-found',
    )
  ) {
    Logger.error(`Unable to fetch product:`, error);
    return <NoMatch404 />;
  }

  return (
    <section className="page-product-details anim-slideInUpShort">
      <Helmet title={t('page.products.page-title')}>
        <meta name="description" content={t('app.oneliner')} />
      </Helmet>
      {isLoading && <Loading />}
      {productResponse?.data?._id && (
        <>
          <ProductHeader product={productResponse.data} orgName={orgName} />
          <Box mt="24px">
            <ManifestTabs
              value={activeTab}
              onTabChange={(newTab) => navigate(newTab as string, { replace: true })}
            >
              <ManifestTabs.List>
                <ManifestTabs.Tab value="overview">
                  {t('page.product.product-overview')}
                </ManifestTabs.Tab>
                <ManifestTabs.Tab value="assets">
                  {productInProductsFeatureFlag
                    ? t('page.product.inventory')
                    : t('page.product.assets')}{' '}
                  <NumberBadge
                    isLoading={isLoading}
                    number={productResponse.data.assetCount ?? 0}
                    active={activeTab === 'assets'}
                  />
                </ManifestTabs.Tab>
                <ManifestTabs.Tab value="vulnerabilities">
                  {t('page.product.vulnerabilities')}{' '}
                  <NumberBadge
                    isLoading={isLoading}
                    number={productResponse.data?.countVulnerabilities?.total ?? 0}
                    active={activeTab === 'vulnerabilities'}
                  />
                </ManifestTabs.Tab>
              </ManifestTabs.List>
            </ManifestTabs>
          </Box>

          <Routes>
            <Route
              path="overview"
              element={
                <ProtectedRoute requiredPermissions={['read:product']}>
                  <div>
                    <ProductOverview />
                  </div>
                </ProtectedRoute>
              }
            />
            <Route
              path="assets"
              element={
                <ProtectedRoute requiredPermissions={['read:product', 'read:asset']}>
                  <div>
                    <AssetsTable embedded hasFilters={false} productId={productId} />
                  </div>
                </ProtectedRoute>
              }
            />
            <Route
              path="vulnerabilities"
              element={
                <ProtectedRoute
                  requiredPermissions={['read:product', 'read:vulnerability']}
                >
                  <div>
                    <ProductVulnerabilitiesTab />
                  </div>
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to={'overview'} replace />} />
          </Routes>
        </>
      )}
    </section>
  );
};
