import { SbomEnrichmentSource } from '@manifest-cyber/types/interface/db';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';

export const usePostOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      organization,
      isChild = false,
    }: {
      organization: {
        enableInviteOnly?: boolean;
        defaultSettings?: {
          enabledSbomEnrichmentSource: SbomEnrichmentSource | undefined;
        };
        name?: string;
        _id?: string;
      };
      isChild?: boolean;
    }) => {
      if (isChild) {
        return AxiosProxy.post({
          url: `organization/${organization._id}`,
          withCredentials: true,
          body: {
            name: organization.name,
          },
        });
      }

      return AxiosProxy.post({
        url: `organization/`,
        withCredentials: true,
        body: organization,
      });
    },
    onSuccess: () => {
      // Invalidate orgs
      queryClient.invalidateQueries(['currentOrganization']);
      queryClient.invalidateQueries(['childOrganizations']);
    },
  });
};
