import { SBOMSelectionData } from '../../../../pages/Sboms/models/SBOMSelectionData.model';
import { InterfaceFullAsset } from '../../../../types/FullInterfaces';
import { SbomToShare } from '../AssetsTable';

export const getAssetsToShare = (
  sbomsSelected: SBOMSelectionData[],
  assets: InterfaceFullAsset[],
): SbomToShare[] => {
  return sbomsSelected.map(({ assetId: assetQueryId, id: sbomId }) => {
    const foundAsset = assets.find((asset) => asset._id?.toString() === assetQueryId);
    return {
      sbomId,
      isActiveAsset: foundAsset ? Boolean(foundAsset.isActive) : true,
    };
  });
};
