import React, { useState } from 'react';
import { Button, Flex, Loader } from '@mantine/core';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  flexRender,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../../components/Icon/Icon';
import { usePostGitHubSettings } from '../../../../../../hooks/mutations/usePostGitHubSettings';
import { InterfaceFullGithubIntegration } from '../../../../../../types/FullInterfaces';
import { useOrganizationFeatureFlag } from '../../../../../../hooks/useOrganizationFeatureFlag';
import { RepositoryManifestSettings } from '@manifest-cyber/types/interface/dbIntegration';
import { useRepositoryColumns } from './useRepositoryColumns';
import classNames from 'classnames';

interface Props {
  fetchedGitHubIntegration: InterfaceFullGithubIntegration;
  isLoadingGitHubIntegration: boolean;
}

export const GitHubOrganizationRepositories: React.FC<Props> = ({
  fetchedGitHubIntegration,
  isLoadingGitHubIntegration,
}) => {
  const { t } = useTranslation();
  const { isEnabled: importGhLabelsFeatureFlag } =
    useOrganizationFeatureFlag('import-gh-labels');

  const [editedRepositorySettings, setEditedRepositorySettings] = useState<
    Record<string, RepositoryManifestSettings>
  >({});
  const [settingsEdited, setSettingsEdited] = useState<boolean>(false);

  const githubIntegration = fetchedGitHubIntegration.githubIntegration[0];
  const fetchedRepositories = githubIntegration.repositories || [];
  const fetchedRepositorySettings = githubIntegration.repositoryManifestSettings || {};

  const { mutateAsync: postGitHubSettings, isLoading: isSavingGitHubSettings } =
    usePostGitHubSettings();

  const currentRepositorySettings = {
    ...fetchedRepositorySettings,
    ...editedRepositorySettings,
  };

  const columns = useRepositoryColumns({
    currentRepositorySettings,
    githubIntegration,
    importGhLabelsFeatureFlag,
    setEditedRepositorySettings,
    setSettingsEdited,
  });

  const table = useReactTable({
    columns: columns,
    data: fetchedRepositories,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (originalRow) => originalRow.id.toString(),
  });

  const handleSubmitRepositoryChanges = async () => {
    await postGitHubSettings({
      githubOrganizationId: githubIntegration.githubOrganizationId?.toString()!,
      manifestOrganizationId: githubIntegration.manifestOrganizationId?.toString()!,
      repositoryManifestSettings: editedRepositorySettings,
    });
    setSettingsEdited(false);
  };

  const renderActionItems = () => {
    const reposNeedingAction = fetchedRepositories.filter(
      (thisRepo) => thisRepo?.actionRequired && thisRepo?.actionRequired?.length > 0,
    );
    if (reposNeedingAction?.length > 0) {
      return (
        <div className="repositories-need-action" data-testid="repositories-need-action">
          <Icon icon="exclamation-triangle" />
          <span>
            {t('page.userSettings.integrations.github.repositories-need-action', {
              count: reposNeedingAction?.length,
            })}
          </span>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div
        data-testid="github-integration-edit-repositories"
        className="github-integration-edit-repositories anim-slideInUpShort"
      >
        <div className="github-integration-edit-repositories-header">
          <div className="left">
            <h4 data-testid="repository-header">
              <span>
                {t('page.userSettings.integrations.github.my-repositories', {
                  count: fetchedRepositories.length,
                })}
              </span>
            </h4>
            {renderActionItems()}
          </div>
          <div className="right">
            <Button
              data-testid="save-button-top"
              disabled={!settingsEdited}
              loading={isSavingGitHubSettings}
              title={
                !editedRepositorySettings ||
                Object.keys(editedRepositorySettings).length < 1
                  ? t('global.saveChanges-disabled-tooltip')
                  : t('global.saveChanges-tooltip')
              }
              onClick={handleSubmitRepositoryChanges}
            >
              {t('global.saveChanges')}
            </Button>
          </div>
        </div>
        {isLoadingGitHubIntegration ? (
          <Loader data-testid="loader" />
        ) : (
          <div className="repository-table" data-testid="repository-table">
            {table.getHeaderGroups().map((headerGroup) => (
              <div
                className={classNames('repository-row', {
                  'with-labels': importGhLabelsFeatureFlag,
                })}
                key={headerGroup.id}
              >
                {headerGroup.headers.map((header) => (
                  <div
                    className="repository-head-cell"
                    key={header.id}
                    style={{ flex: 1 }}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                ))}
              </div>
            ))}
            {table.getRowModel().rows.map((row) => (
              <div
                className={classNames('repository-row', {
                  'with-labels': importGhLabelsFeatureFlag,
                })}
                key={row.id}
                data-testid={`repository-row-${row.id}`}
              >
                {row.getVisibleCells().map((cell) => (
                  <div key={cell.id} style={{ flex: 1 }}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
      <Flex justify="center">
        <Button
          data-testid="save-button-bottom"
          disabled={!settingsEdited}
          loading={isSavingGitHubSettings}
          title={
            !editedRepositorySettings || Object.keys(editedRepositorySettings).length < 1
              ? t('global.saveChanges-disabled-tooltip')
              : t('global.saveChanges-tooltip')
          }
          onClick={handleSubmitRepositoryChanges}
        >
          {t('global.saveChanges')}
        </Button>
      </Flex>
    </>
  );
};
