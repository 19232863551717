import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosProxy } from '../../../api/axiosProxy/axiosProxy';
import { rejectFailedRequestAcceptEmptyData } from '../../../lib/request/rejectFailedRequest/rejectFailedRequest';

export interface PostOrganizationUserParams {
  user: {
    email: string;
    firstName: string;
    lastName: string;
    isOrganizationAdmin?: boolean;
    isReadOnly?: boolean;
    title?: string;
    roles: string[];
  };
}

export const usePostOrganizationUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ user }: PostOrganizationUserParams) => {
      const response = await AxiosProxy.post({
        url: `member/new`,
        withCredentials: true,
        body: user,
      });
      const successfulResponse = await rejectFailedRequestAcceptEmptyData(response);

      return successfulResponse;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['organizationUsers']);
      queryClient.invalidateQueries(['currentOrganization']);
    },
  });
};
