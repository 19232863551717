import { TextComponent } from '@manifest-cyber/design-system/lib/components/TextComponent';
import {
  Box,
  Checkbox,
  CloseButton,
  Divider,
  Flex,
  MultiSelect,
  SelectItem,
  Stack,
  Tooltip,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../components/Icon';
import { ManifestRangeSlider } from '../../../../components/ManifestRangeSlider/ManifestRangeSlider';
import { LabelInterface } from '../../../../hooks/queries/useFetchLabels';
import { ProductsFilters } from '../../productsContext/products.reducer';
import styles from './ProductsFilters.module.scss';

const DEFAULT_CVSS_SCORE: [number, number] = [5, 10];

export const ProductsFiltersComponent = ({
  hasEnabledFilters,
  form,
  labelsOptions,
  cveIds,
}: {
  hasEnabledFilters: boolean;
  form: UseFormReturnType<ProductsFilters>;
  labelsOptions: LabelInterface[];
  cveIds: SelectItem[];
}) => {
  const { t } = useTranslation();
  const severityCheckboxLabelClasses = {
    labelWrapper: styles.severityCheckboxLabel,
    description: styles.severityCheckboxDescription,
  } as const;

  // Sort labelsOptions so that GitHub-sourced labels appear at the bottom.
  const sortedLabels = labelsOptions.slice().sort((a, b) => {
    if (a.source === 'github' && b.source !== 'github') return 1;
    if (a.source !== 'github' && b.source === 'github') return -1;
    return 0;
  });

  return (
    <>
      <Divider />
      <Stack spacing="xs" my="20px">
        <Flex justify={'space-between'}>
          <Checkbox
            size="md"
            label={t('tables.products.filters.onlyHasVulns')}
            checked={form.values.onlyHasVulns}
            {...form.getInputProps(`onlyHasVulns`)}
          />
          <Tooltip label={t('tables.products.filters.onlyHasVulnsTooltip')}>
            <span className="icon-tooltip-cont">
              <Icon icon="info-circle" />
            </span>
          </Tooltip>
        </Flex>
        <Flex justify={'space-between'}>
          <Checkbox
            size="md"
            label={t('tables.products.filters.onlyHasVulnerabilitiesOnKevList')}
            checked={form.values.hasKevVulns}
            {...form.getInputProps(`hasKevVulns`)}
          />
          <Tooltip
            label={t('tables.products.filters.onlyHasVulnerabilitiesOnKevListTooltip')}
          >
            <span className="icon-tooltip-cont">
              <Icon icon="info-circle" />
            </span>
          </Tooltip>
        </Flex>
        {hasEnabledFilters && (
          <>
            <Flex justify={'space-between'}>
              <Checkbox
                size="md"
                label={t('tables.products.filters.onlyHasLicenseIssues')}
                checked={form.values.onlyHasLicenseIssues}
                {...form.getInputProps(`onlyHasLicenseIssues`)}
                onChange={(event) => {
                  form.getInputProps(`onlyHasLicenseIssues`).onChange(event);
                }}
              />
              <Tooltip label={t('tables.products.filters.onlyHasLicenseIssuesTooltip')}>
                <span className="icon-tooltip-cont">
                  <Icon icon="info-circle" />
                </span>
              </Tooltip>
            </Flex>
          </>
        )}
      </Stack>
      <Divider />
      <Stack my="20px">
        <MultiSelect
          size="md"
          clearable
          searchable
          label={
            <TextComponent color="title" variant="headerMedium">
              {t('tables.assets.filters.labels')}
            </TextComponent>
          }
          placeholder={t('global.select')}
          data={sortedLabels.map((label) => ({
            color: label.color,
            label: label.name!,
            value: label._id?.toString()!,
            source: label.source,
          }))}
          itemComponent={forwardRef<HTMLDivElement, any>(
            ({ label, color, source, ...rest }, ref) => (
              <Flex ref={ref} {...rest}>
                {source === 'github' ? (
                  <div className={`label-color-container ${styles.githubIcon}`}>
                    <Icon icon="github" iconStyle="fab" />
                  </div>
                ) : (
                  <div className="label-color-container">
                    <div className="label-color" style={{ backgroundColor: color }} />
                  </div>
                )}
                <div className="label-name">{label}</div>
              </Flex>
            ),
          )}
          valueComponent={forwardRef<HTMLDivElement, any>(
            ({ label, color, source, onRemove, ...rest }, ref) => (
              <Flex ref={ref} {...rest}>
                {source === 'github' ? (
                  <div className={`label-color-container ${styles.githubIcon}`}>
                    <Icon icon="github" iconStyle="fab" />
                  </div>
                ) : (
                  <div className="label-color-container">
                    <div className="label-color" style={{ backgroundColor: color }} />
                  </div>
                )}
                <div className="label-name">{label}</div>
                <CloseButton
                  onMouseDown={onRemove}
                  variant="transparent"
                  size={22}
                  iconSize={14}
                  tabIndex={-1}
                />
              </Flex>
            ),
          )}
          value={form.values.labels}
          {...form.getInputProps(`labels`)}
          rightSection={form.values.labels?.length ? null : undefined}
        />
      </Stack>

      {hasEnabledFilters && (
        <>
          <Divider />
          <Checkbox.Group
            {...form.getInputProps(`cvssSeverity`)}
            onChange={(severitiesSelected) => {
              form.getInputProps(`cvssSeverity`).onChange(severitiesSelected);
              if (severitiesSelected.length > 0) {
                // cvss score should be undefined when some severity level is selected
                form.setFieldValue('isCustomCvssScoreSelected', false);
                form.setFieldValue('cvssScore', undefined);
              }
            }}
            size="md"
          >
            <Stack my="20px">
              <TextComponent color="title" variant="headerMedium" mb="4px">
                {t('tables.products.filters.severity')}
              </TextComponent>
              <Checkbox
                label={t('global.critical')}
                description={<TextComponent color="muted">9.0 - 10.0</TextComponent>}
                value="critical"
                classNames={severityCheckboxLabelClasses}
              />
              <Checkbox
                label={t('global.high')}
                description={<TextComponent color="muted">7.0 - 8.9</TextComponent>}
                value="high"
                classNames={severityCheckboxLabelClasses}
              />
              <Checkbox
                label={t('global.medium')}
                description={<TextComponent color="muted">4.0 - 6.9</TextComponent>}
                value="medium"
                classNames={severityCheckboxLabelClasses}
              />
              <Checkbox
                label={t('global.low')}
                description={<TextComponent color="muted">0.1 - 3.9</TextComponent>}
                value="low"
                classNames={severityCheckboxLabelClasses}
              />
            </Stack>
          </Checkbox.Group>
          <Box mb="20px">
            <Checkbox
              label={t('tables.products.filters.custom')}
              {...form.getInputProps(`isCustomCvssScoreSelected`)}
              checked={form.values.isCustomCvssScoreSelected}
              size="md"
              onChange={(event) => {
                form.getInputProps(`isCustomCvssScoreSelected`).onChange(event);
                if (event.target.checked) {
                  form.setFieldValue('cvssSeverity', []);
                  form.setFieldValue('cvssScore', DEFAULT_CVSS_SCORE);
                } else {
                  form.setFieldValue('cvssScore', undefined);
                }
              }}
            />
          </Box>
          {form.values.isCustomCvssScoreSelected && (
            <Box mb="20px" ml="40px">
              <Box mb="16px">
                <ManifestRangeSlider
                  {...form.getInputProps(`cvssScore`)}
                  step={0.5}
                  minRange={0.2}
                  min={0.1}
                  max={10}
                  label={(value) => value.toFixed(1)}
                />
              </Box>
              <TextComponent color="muted">
                {`${t('tables.products.filters.range')}: ${form.values.cvssScore?.[0].toFixed(1)} - ${form.values.cvssScore?.[1].toFixed(1)}`}
              </TextComponent>
            </Box>
          )}

          <Divider />
          <Checkbox.Group {...form.getInputProps(`recommendation`)} size="md">
            <Stack my="20px">
              <TextComponent color="title" variant="headerMedium" mb="4px">
                {t('tables.products.filters.vulnerabilityRecommendation')}
              </TextComponent>
              <Checkbox
                label={t('jargon.recommendation.mitigate')}
                value="mitigate"
                classNames={severityCheckboxLabelClasses}
              />
              <Checkbox
                label={t('jargon.recommendation.monitor')}
                value="monitor"
                classNames={severityCheckboxLabelClasses}
              />
              <Checkbox
                label={t('jargon.recommendation.acceptRisk')}
                value="accept"
                classNames={severityCheckboxLabelClasses}
              />
            </Stack>
          </Checkbox.Group>
          <Divider />
          <Stack my="20px">
            <MultiSelect
              size="md"
              clearable
              searchable
              label={
                <TextComponent color="title" variant="headerMedium" mb="4px">
                  {t('tables.products.filters.vulnerabilityId')}
                </TextComponent>
              }
              placeholder={t('tables.products.filters.vulnerabilityIdPlaceholder')}
              data={cveIds}
              {...form.getInputProps(`cveIds`)}
            />
          </Stack>
        </>
      )}
    </>
  );
};
