import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchUserPermissions } from '../../api/user/useFetchUserPermissions';
import { PermissionUrn } from '../../pages/UserSettings/Roles/api/permissions.type';
import { ActionableCard } from '../ActionableCard/ActionableCard';
import { Icon } from '../Icon/Icon';

interface ProtectedRouteProps {
  /**
   * Required permission key for the route.
   * Must be explicitly provided using the ROUTES constant.
   */
  // permissionKey: RoutePermissionKey;
  children: React.ReactNode;
  requiredPermissions: PermissionUrn[];
  availableOnlyForPlatformAdmins?: boolean;
}

/**
 * A component that protects routes based on explicitly provided permissions.
 *
 * @example
 * // Using ROUTES constant for type safety
 * <ProtectedRoute permissionKey={ROUTES.ROLES}>
 *   <RolesPage />
 * </ProtectedRoute>
 *
 * @example
 * // With dynamic route parameters
 * <Route path="/settings/roles/:roleId">
 *   <ProtectedRoute permissionKey={ROUTES.ROLE_DETAIL}>
 *     <RoleDetailsPage />
 *   </ProtectedRoute>
 * </Route>
 */
export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  // permissionKey,
  requiredPermissions,
  availableOnlyForPlatformAdmins,
  children,
}) => {
  const { t } = useTranslation();
  const { isLoading, hasPermissions } = useFetchUserPermissions();
  const isAuthorized = hasPermissions({
    requiredPermissions,
    availableOnlyForPlatformAdmins,
  });

  if (isLoading) {
    return null;
  }

  if (isAuthorized) {
    return <>{children}</>;
  } else {
    return (
      <ActionableCard
        Icon={<Icon icon="ban" iconSharp />}
        title={t('non-authorized.title')}
        subtitle={t('non-authorized.description')}
      />
    );
  }
};
