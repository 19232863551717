import { TFunction } from 'i18next';
import { useFetchUserPermissions } from '../../api/user/useFetchUserPermissions';
import { MappedUser } from '../../api/user/user.mapper';
import appConfig from '../../configs/appConfig';
import { Organization } from '../../types/organization.interface';

export interface SubItem {
  id:
    | 'settings.menu.option.integrations'
    | 'settings.menu.option.labels'
    | 'settings.menu.option.licenses'
    | 'settings.menu.option.sharing-portal'
    | 'settings.menu.option.sbom-upload-alerts'
    | 'settings.menu.option.sla'
    | 'settings.menu.option.users'
    | 'settings.menu.option.suborganizations'
    | 'settings.menu.option.upload-settings'
    | 'settings.menu.option.roles'
    | 'settings.menu.option.api-tokens'
    | 'settings.menu.option.notifications'
    | 'settings.menu.option.feature-toggle'
    | 'settings.menu.option.profile';
  isVisible: boolean;
  label: string;
  path: string;
}

export interface NavItem {
  id:
    | 'settings.menu.section.organization'
    | 'settings.menu.section.membership'
    | 'settings.menu.section.account';
  title: string;
  isVisible: boolean;
  items: SubItem[];
}

export const useSettingsNavOptions = ({
  t,
  user,
  enabledFeatures,
  sbomUploadAlertsFlag,
}: {
  pathname: string;
  t: TFunction;
  sbomUploadAlertsFlag: boolean;
  user: MappedUser | undefined;
  currentOrgId: string | null;
  enabledFeatures: Organization['enabledFeatures'];
}) => {
  const showSla = enabledFeatures.some((feature) => feature === 'slaV1');
  const { hasPermissions } = useFetchUserPermissions();

  const isParentOrganization = Boolean(user?.organization?.isParentOrg);

  const organizationSection: NavItem = {
    id: 'settings.menu.section.organization',
    title: t('global.organization'),
    isVisible: true,
    items: [
      {
        id: 'settings.menu.option.api-tokens',
        label: t('page.userSettings.navItems.apiTokens'),
        isVisible: hasPermissions({
          requiredPermissions: ['read:api-token'],
        }),
        path: '/settings/apiTokens',
      },
      {
        id: 'settings.menu.option.integrations',
        label: t('page.userSettings.navItems.integrations'),
        isVisible: hasPermissions({
          requiredPermissions: ['read:integration'],
        }),
        path: '/settings/integrations',
      },
      {
        id: 'settings.menu.option.labels',
        label: t('page.userSettings.navItems.labels'),
        isVisible: hasPermissions({
          requiredPermissions: ['read:label'],
        }),
        path: '/settings/labels',
      },
      {
        id: 'settings.menu.option.licenses',
        label: t('page.userSettings.navItems.licenses'),
        isVisible: hasPermissions({
          requiredPermissions: ['read:license'],
        }),
        path: '/settings/licenses',
      },
      {
        id: 'settings.menu.option.sharing-portal',
        label: 'Sharing Portal',
        isVisible: hasPermissions({
          requiredPermissions: ['read:sharing-portal', 'update:sharing-portal'],
        }),
        path: '/settings/sharing-portal',
      },
      {
        id: 'settings.menu.option.sbom-upload-alerts',
        label: t('page.userSettings.navItems.sbomUploadAlerts'),
        isVisible:
          sbomUploadAlertsFlag &&
          hasPermissions({
            requiredPermissions: ['read:organization-alert'],
          }),
        path: '/settings/sbom-upload-alerts',
      },
      {
        id: 'settings.menu.option.sla',
        label: t('page.userSettings.navItems.sla'),
        isVisible:
          Boolean(showSla) && hasPermissions({ requiredPermissions: ['read:sla'] }),
        path: '/settings/sla',
      },
      {
        id: 'settings.menu.option.users',
        label: t('page.userSettings.navItems.users'),
        isVisible: hasPermissions({
          requiredPermissions: ['read:user', 'update:user', 'delete:user', 'create:user'],
        }),
        path: '/settings/people',
      },
      {
        id: 'settings.menu.option.suborganizations',
        label: t('page.userSettings.navItems.suborganizations'),
        isVisible:
          isParentOrganization &&
          hasPermissions({
            requiredPermissions: ['read:sub-organization'],
          }),
        path: '/settings/suborganizations',
      },
      {
        id: 'settings.menu.option.upload-settings',
        label: t('page.userSettings.navItems.uploadSettings'),
        isVisible: hasPermissions({
          requiredPermissions: ['read:enrichment-setting', 'update:enrichment-setting'],
        }),
        path: '/settings/upload-settings',
      },
    ],
  };

  const membershipSection: NavItem = {
    id: 'settings.menu.section.membership',
    title: 'Membership',
    isVisible: hasPermissions({
      requiredPermissions: ['read:role'],
    }),
    items: [
      {
        id: 'settings.menu.option.roles',
        label: 'Roles',
        isVisible: hasPermissions({
          requiredPermissions: ['read:role'],
        }),
        path: '/settings/roles',
      },
    ],
  };

  const accountSection: NavItem = {
    id: 'settings.menu.section.account',
    title: t('global.account'),
    isVisible: true,
    items: [
      {
        id: 'settings.menu.option.profile',
        label: t('page.userSettings.navItems.profile'),
        isVisible: true,
        path: '/settings/profile',
      },
      {
        id: 'settings.menu.option.notifications',
        label: t('page.userSettings.navItems.notifications'),
        isVisible:
          appConfig.enableEmailFeatures &&
          hasPermissions({
            requiredPermissions: [
              'read:vulnerability-notification',
              'update:vulnerability-notification',
              'delete:vulnerability-notification',
              'create:vulnerability-notification',
            ],
          }),
        path: '/settings/notifications',
      },
      {
        id: 'settings.menu.option.feature-toggle',
        label: t('page.userSettings.navItems.feature-toggle'),
        isVisible: hasPermissions({
          requiredPermissions: [],
          availableOnlyForPlatformAdmins: true,
        }),
        path: '/settings/feature-toggle',
      },
    ],
  };
  const nav: NavItem[] = [organizationSection, membershipSection, accountSection];

  const filteredNav = nav
    .map((section) => ({
      ...section,
      items: section.items.filter((item) => item.isVisible),
    }))
    .filter((section) => section.isVisible && section.items.length > 0);

  return filteredNav;
};
