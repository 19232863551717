import { EncryptedStore } from '@manifest-cyber/types/interface/db';
import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { rejectFailedRequestOrEmptyData } from '../../lib/request/rejectFailedRequest/rejectFailedRequest';

export interface OrganizationMemberRole {
  id: string;
  name: string;
  urn: string;
}

export interface OrganizationMember {
  _id: string;
  dateCreated: string;
  dateModified: string;
  email: string;
  firstName: string;
  lastLogin: string;
  lastName: string;
  locale: null;
  organizationGroups?: unknown[];
  status: 'active';
  title?: string;
  roles: OrganizationMemberRole[];
  organizationMember: [
    {
      _id: string;
      organizationId: string;
      userId: string;
      dateCreated: string;
      dateModified: string;
      groupId: [];
      firstName: EncryptedStore | null;
      lastName: EncryptedStore | null;
      title?: EncryptedStore | null;
      isOrganizationAdmin?: boolean;
      isReadOnly?: boolean;
      agreementAcceptedAt?: string;
    },
  ];
}

export interface MappedOrganizationMember extends OrganizationMember {
  dateCreated: string;
  dateModified: string;
  email: string;
  firstName: string;
  groupId: [];
  lastLogin: string;
  lastName: string;
  locale: null;
  memberId: string;
  organizationId: string;
  roles: OrganizationMemberRole[];
  status: 'active';
  title?: string;
  userId: string;
  isOrganizationAdmin?: boolean;
  isReadOnly?: boolean;
}

interface Options {
  refetchOnWindowFocus?: boolean;
}

export const mapOrganizationMembers = (
  members: OrganizationMember[],
): MappedOrganizationMember[] => {
  return members.map((member) => {
    const memberInfo = member.organizationMember[0];

    return {
      ...member,
      dateCreated: member.dateCreated,
      dateModified: member.dateModified,
      email: member.email,
      firstName: member.firstName,
      groupId: memberInfo.groupId,
      isOrganizationAdmin: memberInfo.isOrganizationAdmin,
      isReadOnly: memberInfo.isReadOnly,
      lastName: member.lastName,
      memberId: memberInfo._id,
      organizationId: memberInfo.organizationId,
      roles: member.roles,
      status: member.status,
      title: member.title,
      userId: memberInfo.userId,
      lastLogin: member.lastLogin,
      locale: member.locale,
    };
  });
};

export const useFetchOrganizationUsers = (options?: Options) => {
  let fetchUrl = `users`;

  return useQuery({
    queryKey: ['organizationUsers', `/${fetchUrl}`],
    queryFn: async () => {
      const response = await AxiosProxy.get({ url: fetchUrl });
      const validatedResponse =
        await rejectFailedRequestOrEmptyData<OrganizationMember[]>(response);

      return mapOrganizationMembers(validatedResponse.data);
    },
    refetchOnWindowFocus: options?.refetchOnWindowFocus,
  });
};
