import { InterfaceOrganizationMember } from '@manifest-cyber/types/interface/dbTables';
import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { MappedUser } from '../api/user/user.mapper';
import { LocationUtils } from '../lib/location/location';
import { captureExceptionWithMessage } from '../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import { useLastPath } from './utils/useLastPath';
import { useLocalStorage } from './utils/useLocalStorage';
import { OrganizationIdType, useOrganizationId } from './utils/useOrganizationId';

const AuthContext = createContext<{
  user?: MappedUser | null;
  currentOrgId?: OrganizationIdType;
  login?: (
    userDataToWrite: MappedUser,
    options?: {
      redirectPath?: string;
      shouldRedirect?: boolean;
    },
  ) => Promise<boolean>;
  logout?: (
    reason?: string,
    options?: {
      redirectUrl?: string;
      shouldRedirectToLogin?: boolean;
    },
  ) => void;
  checkUserAccess: (access: 'read' | 'write') => boolean;
}>({
  user: null,
  currentOrgId: null,
  login: undefined,
  logout: undefined,
  checkUserAccess: () => false,
});

export const MANIFEST_USER_LOCAL_STORAGE_KEY = 'manifest-user';
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useLocalStorage<MappedUser>(
    MANIFEST_USER_LOCAL_STORAGE_KEY,
    null,
  );
  const [currentOrgId, setOrgId] = useOrganizationId(null);
  const navigate = useNavigate();
  const [lastPath] = useLastPath();

  /**
   * Log user in locally
   * @param {any} userDataToWrite
   * @param {boolean} redirect Redirect user to homepage
   * @returns
   */
  const login = async (
    userDataToWrite: MappedUser,
    { redirectPath = lastPath || '/', shouldRedirect = true } = {},
  ) => {
    if (userDataToWrite?._id) {
      // Add to Sentry
      Sentry.setUser({
        id: userDataToWrite?._id,
        ip_address: '{{auto}}',
        locale: userDataToWrite?.locale || 'en-us',
        access: userDataToWrite?.internalRoles,
      });

      // If user has orgs, write the one they'll use
      if (userDataToWrite?.organizations && (!currentOrgId || currentOrgId.length < 1)) {
        if (currentOrgId && userDataToWrite?.organizations.includes(currentOrgId)) {
          setOrgId(currentOrgId);
        } else {
          setOrgId(userDataToWrite?.organizations[0] || null);
        }
      }
      setUser(userDataToWrite);

      if (shouldRedirect && redirectPath) {
        navigate(`${redirectPath}`, { replace: true });
      }
    } else {
      captureExceptionWithMessage(
        'AuthProvider login(): unable to set userdata',
        userDataToWrite,
      );
    }

    return true;
  };

  /**
   * Log user out locally
   * @param {string} reason
   * @param {boolean | string} redirect
   * @returns void
   */
  const logout = (
    reason?: string,
    {
      redirectUrl,
      shouldRedirectToLogin = true,
    }: {
      redirectUrl?: string;
      shouldRedirectToLogin?: boolean;
    } = {},
  ) => {
    // Make sure Sentry is updated.
    Sentry.setUser(null);

    setUser(null);
    // removeSessionCookie();
    Cookies.remove('organizationId');
    const prevLastPath = window.localStorage.getItem('lastPath');
    // Remove all storage values
    window.localStorage.clear();
    window.sessionStorage.clear();
    /* this is to be able to redirect the user to their manifest app previous path after they login */

    if (prevLastPath) {
      window.localStorage.setItem('lastPath', prevLastPath);
    }

    if (redirectUrl) {
      LocationUtils.browserNavigate(redirectUrl);
    }

    if (shouldRedirectToLogin) {
      const path = reason ? `/login?logout=${reason}` : '/login';

      navigate(path);
    }
  };

  /**
   * Utility that accepts an access type and returns whether the current user has such access in the currently selected organization
   * @param access 'read' | 'write' - the type of access to check for
   * @returns boolean
   */
  const checkUserAccess = (access: 'read' | 'write') => {
    if (user) {
      if (user.internalRoles.includes('admin')) {
        return true;
      }

      if (user.internalRoles.includes('staff')) {
        return true;
      }

      if (currentOrgId && user?.membershipData) {
        const currentOrgMembership: InterfaceOrganizationMember | null =
          ((user.membershipData?.[currentOrgId]?._doc ||
            user.membershipData?.[
              currentOrgId
            ]) as unknown as InterfaceOrganizationMember) || null;
        if (currentOrgMembership && currentOrgMembership?._id) {
          // Since both admin and standard can write, we only need to check for isReadOnly
          if (access === 'write' && !currentOrgMembership.isReadOnly) {
            return true;
          }

          // All users can read, so if access is read, allow it
          if (access === 'read') {
            return true;
          }
        } else {
          console.warn('Could not find membership data for current organization ', user);
        }
      } else {
        console.warn(
          'Could not find current organization or user membership data',
          currentOrgId,
        );
      }
    }

    return false;
  };

  const value = useMemo(
    () => ({
      user,
      currentOrgId,
      login,
      logout,
      checkUserAccess,
    }),
    [user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
