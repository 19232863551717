import { Dispatch, SetStateAction, useMemo } from 'react';
import { createColumnHelper, ColumnDef, CellContext } from '@tanstack/react-table';
import { Tooltip, Badge, Switch, Flex, Select } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../../components/Icon/Icon';
import styles from './GitHubOrganizationRepositories.module.scss';
import {
  Repository,
  RepositoryManifestSettings,
  TriggerType,
} from '@manifest-cyber/types/interface/dbIntegration';
import { InterfaceGithubIntegration } from '@manifest-cyber/types/interface/dbTables';

const columnHelper = createColumnHelper<Repository>();

interface UseRepositoryColumnsProps {
  currentRepositorySettings: Record<string, RepositoryManifestSettings>;
  githubIntegration: InterfaceGithubIntegration;
  importGhLabelsFeatureFlag: boolean;
  setEditedRepositorySettings: Dispatch<
    SetStateAction<Record<string, RepositoryManifestSettings>>
  >;
  setSettingsEdited: Dispatch<SetStateAction<boolean>>;
}

export const useRepositoryColumns = ({
  currentRepositorySettings,
  githubIntegration,
  importGhLabelsFeatureFlag,
  setEditedRepositorySettings,
  setSettingsEdited,
}: UseRepositoryColumnsProps): ColumnDef<Repository, any>[] => {
  const { t } = useTranslation();

  const columns: ColumnDef<Repository, any>[] = useMemo(() => {
    const cols: ColumnDef<Repository, any>[] = [
      // Repository Name column
      columnHelper.accessor('name', {
        header: () => t('global.name'),
        cell: ({ cell, row }: CellContext<Repository, string>) => (
          <div
            data-testid={`repository-name-${row.id}`}
            className="repository-name-cell"
            style={{
              opacity:
                currentRepositorySettings[row.id]?.isEnabled === false ||
                githubIntegration.status === 'suspended'
                  ? 0.5
                  : 1,
            }}
          >
            <div className="repository-name-alerts">
              {row.original.actionRequired && row.original.status !== 'archived' && (
                <div
                  className="action-required-icon"
                  data-testid={`action-required-icon-${row.id}`}
                >
                  <Tooltip
                    label={
                      row.original.actionRequired.includes('page.userSettings')
                        ? t(`${row.original.actionRequired}`)
                        : `${row.original.actionRequired}`
                    }
                  >
                    <span>
                      <Icon icon="exclamation-triangle" />
                    </span>
                  </Tooltip>
                </div>
              )}
              <div className="name-privacy">
                <div className="repository-name">
                  {cell.getValue()}
                  {row.original.status === 'archived' && (
                    <Badge className={styles.archived}>
                      {t('page.userSettings.integrations.github.repo-archived')}
                    </Badge>
                  )}
                </div>
                <div className="repository-last-modified">
                  {row.original.actionRequired && row.original.status !== 'archived' && (
                    <Tooltip
                      label={
                        row.original.actionRequired.includes('page.userSettings')
                          ? t(`${row.original.actionRequired}`)
                          : `${row.original.actionRequired}`
                      }
                    >
                      <div
                        className="action-required"
                        data-testid={`action-required-${row.id}`}
                      >
                        <span>
                          {t(
                            'page.userSettings.integrations.github.actions-required.short',
                          )}
                        </span>
                      </div>
                    </Tooltip>
                  )}
                  <div className="privacy">
                    {t(
                      `page.userSettings.integrations.github.repo-privacy-${
                        row.original.private ? 'private' : 'public'
                      }`,
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      }),
      // Generation Frequency column
      columnHelper.accessor(
        (originalRow) =>
          currentRepositorySettings[originalRow.id.toString()]?.triggers?.[0]
            ?.triggerTarget,
        {
          id: 'generationFrequency',
          header: () => t('global.generationFrequency'),
          cell: ({ cell, row }: CellContext<Repository, string>) => (
            <div className="select-cell">
              <Select
                placeholder={t('global.select')}
                data-testid={`generation-frequency-${row.id}`}
                disabled={
                  currentRepositorySettings[row.id]?.isEnabled === false ||
                  githubIntegration.status === 'suspended'
                }
                data={[
                  {
                    label: t(
                      'page.userSettings.integrations.github.generationFrequencyOptions.push',
                    ),
                    value: 'push',
                  },
                  {
                    label: t(
                      'page.userSettings.integrations.github.generationFrequencyOptions.day',
                    ),
                    value: 'day',
                  },
                  {
                    label: t(
                      'page.userSettings.integrations.github.generationFrequencyOptions.week',
                    ),
                    value: 'week',
                  },
                  {
                    label: t(
                      'page.userSettings.integrations.github.generationFrequencyOptions.month',
                    ),
                    value: 'month',
                  },
                ]}
                onChange={(val) => {
                  if (val) {
                    setEditedRepositorySettings((prev) => {
                      const prevRowValue = prev[row.original.id.toString()];
                      const triggerType: TriggerType = val === 'push' ? 'push' : 'time';

                      return {
                        ...prev,
                        [row.original.id.toString()]: {
                          ...prevRowValue,
                          id: prevRowValue?.id || 0,
                          name: prevRowValue?.name || '',
                          triggers: [
                            {
                              triggerType,
                              triggerCriteria:
                                val === 'push' ? row.original.defaultBranch : '',
                              triggerTarget: val !== 'push' ? val : 'push',
                            },
                          ],
                        },
                      };
                    });
                    setSettingsEdited(true);
                  }
                }}
                value={
                  currentRepositorySettings[row.id.toString()]?.triggers?.[0]
                    ?.triggerTarget
                }
              />
            </div>
          ),
        },
      ),
      // Branch column
      columnHelper.accessor(
        (originalRow) =>
          currentRepositorySettings[originalRow.id]?.triggers?.[0]?.triggerCriteria,
        {
          id: 'triggerCriteria',
          header: () => (
            <Tooltip
              label={t('page.userSettings.integrations.branch-tooltip')}
              multiline
              width={400}
            >
              <span>{t('global.branch')}</span>
            </Tooltip>
          ),
          cell: ({ cell, row }: CellContext<Repository, string>) => (
            <div className="select-cell">
              <Tooltip
                label={t('page.userSettings.integrations.branch-tooltip')}
                multiline
                width={400}
              >
                <div>
                  <Select
                    placeholder={t('global.select')}
                    data-testid={`branch-${row.id}`}
                    disabled
                    data={[
                      {
                        label: row.original.defaultBranch,
                        value: row.original.defaultBranch,
                      },
                    ]}
                    value={cell.getValue() || row.original.defaultBranch}
                  />
                </div>
              </Tooltip>
            </div>
          ),
        },
      ),
    ];

    // Include the Labels column if the feature flag is enabled.
    if (importGhLabelsFeatureFlag) {
      cols.push(
        columnHelper.accessor('labels', {
          header: () => (
            <Tooltip
              label={t('page.userSettings.integrations.ghLabelsTooltip')}
              position="top"
              withArrow
            >
              <Flex gap={4}>
                <span>{t('page.userSettings.integrations.labels')}</span>
                <span>
                  <Icon icon="info-circle" iconSharp classNames={styles.infoIcon} />
                </span>
              </Flex>
            </Tooltip>
          ),
          cell: ({ row }: CellContext<Repository, { name: string; labelId?: any }[]>) => {
            const labels = row.original.labels || [];
            const maxDisplay = 2;
            const displayedLabels = labels.slice(0, maxDisplay);
            const extraLabels = labels.slice(maxDisplay);
            return (
              <Flex
                className="repository-labels-cell"
                gap={8}
                wrap="wrap"
                data-testid={`labels-${row.id}`}
              >
                {displayedLabels.map((label, idx) => (
                  <Badge
                    key={idx}
                    classNames={{
                      root: styles.badgeRoot,
                      inner: styles.badgeInner,
                    }}
                  >
                    <Icon icon="github" iconStyle="fab" /> {label.name}
                  </Badge>
                ))}
                {extraLabels.length > 0 && (
                  <Tooltip label={extraLabels.map((l) => l.name).join(', ')}>
                    <Badge
                      classNames={{
                        root: styles.badgeRoot,
                        inner: styles.badgeInner,
                      }}
                    >
                      <Icon icon="github" iconStyle="fab" />{' '}
                      {t('page.userSettings.integrations.github.moreLabels', {
                        count: extraLabels.length,
                      })}
                    </Badge>
                  </Tooltip>
                )}
              </Flex>
            );
          },
        }),
      );
    }

    // Enabled column
    cols.push(
      columnHelper.accessor(
        (originalRow) => currentRepositorySettings[originalRow.id]?.isEnabled ?? true,
        {
          id: 'isEnabled',
          header: () => (
            <Tooltip label={t('page.userSettings.integrations.isEnabled')}>
              <span>{t('global.enabled')}</span>
            </Tooltip>
          ),
          cell: ({ cell, row }: CellContext<Repository, boolean>) => (
            <div className="select-cell">
              <Switch
                data-testid={`switch-${row.id}`}
                disabled={
                  row.original.status === 'archived' ||
                  githubIntegration.status === 'suspended'
                }
                defaultChecked={currentRepositorySettings[row.id]?.isEnabled ?? true}
                onChange={(event) => {
                  setEditedRepositorySettings((prev) => ({
                    ...prev,
                    [row.original.id.toString()]: {
                      ...prev[row.original.id.toString()],
                      isEnabled: event.currentTarget.checked,
                      id: prev[row.original.id.toString()]?.id || 0,
                      name: prev[row.original.id.toString()]?.name || '',
                      triggers: prev[row.original.id.toString()]?.triggers || [],
                    },
                  }));
                  setSettingsEdited(true);
                }}
              />
            </div>
          ),
        },
      ),
    );

    return cols;
  }, [
    currentRepositorySettings,
    githubIntegration,
    importGhLabelsFeatureFlag,
    setEditedRepositorySettings,
    setSettingsEdited,
    t,
  ]);

  return columns;
};
