import axios from 'axios';

import packageJson from '../../package.json' assert { type: 'json' };
import { captureExceptionWithMessage } from '../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import buildJson from './meta.json' assert { type: 'json' };

type EnvironmentName =
  | 'local'
  | 'development'
  | 'localDevelopment'
  | 'production'
  | 'stage';

const apiVersion = '1';
const currentEnv: EnvironmentName =
  (import.meta.env.MODE as EnvironmentName) || 'production';
const appEnv = (buildJson.APP_ENV as EnvironmentName) || currentEnv;

interface EnvConfig {
  apiUrl: string;
  apiVersion: string;
  contactEmail: string;
  cspIncludeSample: boolean; // Where applicable, include samples of infringing content in CSP reports
  cspReportOnly: boolean; // Enable report-only mode for CSP, which disables protections in favor of reporting
  cspWildcardWhitelist: ['img-src', 'media-src']; // Wildcard whitelist (*) `img-src` and `media-src` in CSP
  envLabel: string;
  fbTrackingID: string;
  githubAppUrl: string;
  googleAnalyticsID: string;
  googleAnalyticsId4: string;
  googleTagID: string;
  isSentryEnabled: boolean;
  sentrySampleRate: number;
  siteProtocol: string;
  siteURL: string;
  cookieDomain: string;
  orySDKUrl: string;
  useSsl: boolean;
  enableEmailFeatures: boolean;
}

interface AppConfig extends EnvConfig {
  version: string;
  appEnv: string;
  currentEnv: string;
  currentVersion: string;
  sentryDSN: string;
  intercomDisableTours: false;
  intercomWorkspaceId: string;
  localStorageStore: string;
  localStorageJwt: string;
  localStorageVersion: string;
  supportEmail: string;
  helpCenterUrl: string;
}

const local: EnvConfig = {
  apiUrl: 'local.manifestcyber.com:8081',
  apiVersion,
  contactEmail: '',
  cspIncludeSample: true,
  cspReportOnly: true,
  cspWildcardWhitelist: ['img-src', 'media-src'],
  envLabel: 'Local',
  fbTrackingID: '',
  githubAppUrl: 'https://github.com/apps/manifest-cyber-dev',
  googleAnalyticsID: '',
  googleAnalyticsId4: '',
  googleTagID: '',
  isSentryEnabled: false,
  sentrySampleRate: 1.0,
  siteProtocol: 'http://',
  siteURL: 'local.manifestcyber.com:3000',
  cookieDomain: 'manifestcyber.com',
  orySDKUrl: 'http://local.manifestcyber.com:4433',
  useSsl: false,
  enableEmailFeatures: true,
};

const development: EnvConfig = {
  apiUrl: 'api.development.manifestcyber.dev',
  apiVersion,
  contactEmail: '',
  cspIncludeSample: true,
  cspReportOnly: true,
  cspWildcardWhitelist: ['img-src', 'media-src'],
  envLabel: 'Development',
  fbTrackingID: '',
  githubAppUrl: 'https://github.com/apps/manifest-cyber-dev',
  googleAnalyticsID: '',
  googleAnalyticsId4: '',
  googleTagID: '',
  isSentryEnabled: true,
  sentrySampleRate: 1.0,
  siteProtocol: 'https://',
  siteURL: 'app-dev.manifestcyber.com',
  cookieDomain: '.development.manifestcyber.dev',
  orySDKUrl: 'https://auth-dev.manifestcyber.com',
  useSsl: true,
  enableEmailFeatures: true,
};

const production: EnvConfig = {
  apiUrl: 'api.manifestcyber.com',
  apiVersion,
  contactEmail: '',
  cspIncludeSample: true,
  cspReportOnly: true,
  cspWildcardWhitelist: ['img-src', 'media-src'],
  envLabel: 'Production',
  fbTrackingID: '',
  githubAppUrl: 'https://github.com/apps/manifest-cyber',
  googleAnalyticsID: '',
  googleAnalyticsId4: '',
  googleTagID: '',
  isSentryEnabled: true,
  sentrySampleRate: 0.2,
  siteProtocol: 'https://',
  siteURL: 'app.manifestcyber.com',
  cookieDomain: 'manifestcyber.com',
  orySDKUrl: 'https://auth.manifestcyber.com',
  useSsl: true,
  enableEmailFeatures: true,
};

const stage: EnvConfig = {
  apiUrl: 'api-stage.manifestcyber.com',
  apiVersion,
  contactEmail: '',
  cspIncludeSample: true,
  cspReportOnly: true,
  cspWildcardWhitelist: ['img-src', 'media-src'],
  envLabel: 'Stage',
  fbTrackingID: '',
  githubAppUrl: 'https://github.com/apps/manifest-cyber-dev',
  googleAnalyticsID: '',
  googleAnalyticsId4: '',
  googleTagID: '',
  isSentryEnabled: true,
  sentrySampleRate: 0.5,
  siteProtocol: 'https://',
  siteURL: 'app-stage.manifestcyber.com',
  cookieDomain: 'manifestcyber.com',
  orySDKUrl: 'https://auth-stage.manifestcyber.com',
  useSsl: true,
  enableEmailFeatures: true,
};

const localDevelopment: EnvConfig = {
  ...local,
  envLabel: 'Local + Development API',
  apiUrl: development.apiUrl,
};

const configByEnvironments = {
  local,
  localDevelopment,
  development,
  production,
  stage,
} as const;

let appConfig: AppConfig = {
  // Vars not specific to an environment
  appEnv,
  currentEnv,
  currentVersion: packageJson.version,
  sentryDSN:
    'https://4c25b28fbf734d19a3d655b7a50bc616@o4503966059528192.ingest.sentry.io/4504070480199680', // https://foo@bar.ingest.sentry.io/blah,
  intercomDisableTours: false,
  intercomWorkspaceId: 'eh0dl3qc',
  localStorageStore: 'gb',
  localStorageJwt: 'manifestcyberuser',
  localStorageVersion: 'manifestcyberversion',
  supportEmail: 'help@manifestcyber.com',
  helpCenterUrl: 'support.manifestcyber.com',
  ...configByEnvironments[appEnv], // merge current environment config
  ...((buildJson as unknown as { default: { version: string } })?.default || buildJson),
};

// Overwrite the above defaults with a `local.json` file in this dir or main dir
try {
  const localConfigFetch = await axios.get<AppConfig>('/local.json');
  if (localConfigFetch && localConfigFetch.data) {
    const overriddenConfig = {
      ...appConfig,
      ...localConfigFetch.data,
    };
    appConfig = overriddenConfig;
  }
} catch (err) {
  captureExceptionWithMessage('Error reading local.json file:', err);
}

export default appConfig;
