import { useState } from 'react';

export enum AssetModalsEnum {
  ProductAssets = 'productAssets',
  RemoveAsset = 'removeAsset',
  BulkRemoveAssetsFromProduct = 'bulkRemoveAssetsFromProduct',
  ProductsAssociation = 'productsAssociation',
  ConfirmAssociation = 'confirmAssociation',
  MarkInactiveAssetWarning = 'markInactiveAssetWarning',
  FilterSidebarOpen = 'filterSidebarOpen',
  EditLabelModalOpen = 'editLabelModalOpen',
  OssIngestModalOpen = 'ossIngestModalOpen',
}

export type ModalsState = Record<AssetModalsEnum, boolean>;

export type ModalsControls = {
  openModal: (modal: AssetModalsEnum) => void;
  closeModal: (modal: AssetModalsEnum) => void;
};

const useModalsState = (): {
  modals: ModalsState;
  modalControls: ModalsControls;
} => {
  const initialState: ModalsState = {
    [AssetModalsEnum.ProductAssets]: false,
    [AssetModalsEnum.RemoveAsset]: false,
    [AssetModalsEnum.BulkRemoveAssetsFromProduct]: false,
    [AssetModalsEnum.ProductsAssociation]: false,
    [AssetModalsEnum.ConfirmAssociation]: false,
    [AssetModalsEnum.MarkInactiveAssetWarning]: false,
    [AssetModalsEnum.FilterSidebarOpen]: false,
    [AssetModalsEnum.EditLabelModalOpen]: false,
    [AssetModalsEnum.OssIngestModalOpen]: false,
  };

  const [modals, setModals] = useState<ModalsState>(initialState);

  const openModal = (modal: AssetModalsEnum) => {
    setModals((prev) => ({ ...prev, [modal]: true }));
  };

  const closeModal = (modal: AssetModalsEnum) => {
    setModals((prev) => ({ ...prev, [modal]: false }));
  };

  const modalControls: ModalsControls = { openModal, closeModal };

  return { modals, modalControls };
};

export default useModalsState;
