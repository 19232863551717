import { Accordion } from '@mantine/core';
import styles from './ManifestAcordion.module.scss';

interface ManifestAcordionProps {
  children: React.ReactNode;
}

export const ManifestAccordion = ({ children }: ManifestAcordionProps) => {
  return (
    <Accordion
      variant="separated"
      multiple
      classNames={{
        control: styles.accordionControl,
        panel: styles.accordionPanel,
        item: styles.accordionItem,
      }}
    >
      {children}
    </Accordion>
  );
};

ManifestAccordion.Item = Accordion.Item;
ManifestAccordion.Control = Accordion.Control;
ManifestAccordion.Panel = Accordion.Panel;

export default ManifestAccordion;
