import { ManifestColors } from '@manifest-cyber/design-system/lib/foundations/ts';
import {
  BadgeStylesParams,
  ButtonStylesParams,
  MantineThemeOverride,
} from '@mantine/core';
import Icon from '../components/Icon/Icon';

export const theme = (colorScheme: 'light' | 'dark'): MantineThemeOverride => ({
  colors: {
    cyan: [
      'rgba(243,249,250,1.0)',
      'rgba(197,228,236,1.0)',
      'rgba(153,212,226,1.0)',
      'rgba(111,200,222,1.0)',
      'rgba(70,192,222,1.0)',
      'rgba(30,188,226,1.0)',
      'rgba(34,157,187,1.0)',
      'rgba(35,131,154,1.0)',
      'rgba(36,109,127,1.0)',
      'rgba(35,92,105,1.0)',
    ],
    blue: [
      'rgba(223,233,251,1.0)',
      'rgba(162,190,243,1.0)',
      'rgba(112,154,235,1.0)',
      'rgba(70,124,226,1.0)',
      'rgba(37,99,216,1.0)',
      'rgba(28,81,182,1.0)',
      'rgba(22,67,153,1.0)',
      'rgba(16,55,128,1.0)',
      'rgba(12,45,108,1.0)',
      'rgba(9,37,91,1.0)',
    ],
    yellow: [
      'rgba(254,245,231,1.0)',
      'rgba(250,216,164,1.0)',
      'rgba(247,191,108,1.0)',
      'rgba(243,169,60,1.0)',
      'rgba(238,147,13,1.0)',
      'rgba(198,122,10,1.0)',
      'rgba(166,102,8,1.0)',
      'rgba(138,85,6,1.0)',
      'rgba(115,70,4,1.0)',
      'rgba(96,58,3,1.0)',
    ],
    green: [
      'rgba(239,250,241,1.0)',
      'rgba(216,243,219,1.0)',
      'rgba(179,231,187,1.0)',
      'rgba(121,208,140,1.0)',
      'rgba(78,185,105,1.0)',
      'rgba(44,157,77,1.0)',
      'rgba(29,126,59,1.0)',
      'rgba(23,101,49,1.0)',
      'rgba(21,80,41,1.0)',
      'rgba(18,66,35,1.0)',
    ],
    red: [
      'rgba(254,230,237,1.0)',
      'rgba(246,167,191,1.0)',
      'rgba(234,119,154,1.0)',
      'rgba(220,82,123,1.0)',
      'rgba(204,54,99,1.0)',
      'rgba(178,36,79,1.0)',
      'rgba(154,24,63,1.0)',
      'rgba(134,14,50,1.0)',
      'rgba(117,6,39,1.0)',
      'rgba(102,0,30,1.0)',
    ],
    dark: [
      'rgba(221,221,221,1.0)',
      'rgba(195,207,224,1.0)',
      'rgba(195,207,224,1.0)',
      'rgba(195,207,224,1.0)',
      'rgba(34,48,67,1.0)',
      'rgba(28,40,55,1.0)',
      'rgba(23,33,46,1.0)',
      'rgba(19,27,38,1.0)',
      'rgba(15,21,30,1.0)',
      'rgba(12,17,24,1.0)',
    ],
  },
  fontFamily: 'Karla, sans-serif',
  cursorType: 'pointer',
  primaryColor: 'cyan',
  primaryShade: colorScheme === 'dark' ? 5 : 7,
  colorScheme: colorScheme,
  components: {
    Badge: {
      defaultProps: {
        variant: 'filled',
      },
      styles: (theme, params: BadgeStylesParams, { variant }) => ({
        root: {
          margin: '0 0.5rem',
          color:
            variant === 'filled' && !['red', 'dark'].includes(params.color)
              ? theme.colors.dark[9]
              : undefined,
        },
      }),
    },
    Button: {
      styles: (theme, params: ButtonStylesParams, { variant }) => ({
        root: {
          ':disabled': {
            backgroundColor: ManifestColors.colorBorderComponents,
            color: ManifestColors.colorTextMuted,
          },
          color:
            variant === 'filled' && !['red', 'dark'].includes(params.color)
              ? theme.colors.dark[9]
              : undefined,
        },
      }),
    },

    Card: {
      defaultProps: {
        withBorder: true,
        p: 'lg',
      },
    },
    Indicator: {
      defaultProps: {
        color: 'dark',
        withBorder: true,
      },
    },
    Pagination: {
      defaultProps: {
        radius: 'xl',
        size: 'lg',
      },
    },
    MultiSelect: {
      defaultProps: {
        rightSection: <Icon icon="chevron-down" />,
        styles: { rightSection: { pointerEvents: 'none' } },
      },
    },
    Select: {
      defaultProps: {
        rightSection: <Icon icon="chevron-down" />,
        styles: { rightSection: { pointerEvents: 'none' } },
      },
    },
    Tooltip: {
      defaultProps: {
        withArrow: true,
        withinPortal: true,
        portalProps: {
          target: '.mantine-tooltips',
        },
      },
    },
  },
});
