import { Box, Flex, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useFetchUserPermissions } from '../../api/user/useFetchUserPermissions';
import { LicensesTable } from '../../components/DataTables/LicensesTable';
import { UnmatchedLicensesTable } from '../../components/DataTables/UnmatchedLicensesTable';
import { ManifestTabs } from '../../components/ManifestTabs/ManifestTabs';
import '../../scss/pages/licenses.scss';

export const Licenses = () => {
  const { t } = useTranslation();
  const { hasPermissions } = useFetchUserPermissions();
  const hasAdminEditPermissions = hasPermissions({
    requiredPermissions: [],
    availableOnlyForPlatformAdmins: true,
  });

  return (
    <div className="page-licenses anim-slideInDownShort">
      <Flex justify="space-between">
        <Title order={2}>{t('page.userSettings.licenses.header')}</Title>
      </Flex>
      {hasAdminEditPermissions ? (
        <Box mt="24px">
          <ManifestTabs defaultValue="licenses">
            <ManifestTabs.List>
              <ManifestTabs.Tab value="licenses">
                {t('page.userSettings.licenses.licenses')}
              </ManifestTabs.Tab>
              <ManifestTabs.Tab value="unmatched">
                {t('page.userSettings.licenses.unmatched-licenses')}
              </ManifestTabs.Tab>
            </ManifestTabs.List>
            <ManifestTabs.Panel value="licenses">
              <LicensesTable hasAdminEditPermissions />
            </ManifestTabs.Panel>
            <ManifestTabs.Panel value="unmatched">
              <UnmatchedLicensesTable />
            </ManifestTabs.Panel>
          </ManifestTabs>
        </Box>
      ) : (
        <LicensesTable hasAdminEditPermissions />
      )}
    </div>
  );
};

export default Licenses;
