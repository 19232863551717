import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import Icon from '../Icon';
import styles from './GenericEmptyState.module.scss';
import { IconName } from '@fortawesome/fontawesome-svg-core';

interface ButtonConfig {
  icon?: IconName;
  textKey: string;
  navigateTo: string;
}

interface GenericEmptyStateProps {
  icon?: IconName;
  titleKey: string;
  messageKey?: string;
  buttons: ButtonConfig[];
  containerStyle?: React.CSSProperties;
  mt?: number | string;
}

export const GenericEmptyState = ({
  icon = 'sparkles',
  titleKey,
  messageKey,
  buttons,
  containerStyle,
  mt = 100,
}: GenericEmptyStateProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Flex
      align="center"
      justify="center"
      h="100%"
      direction="column"
      gap={20}
      mt={mt}
      style={containerStyle}
    >
      <div className={styles.iconContainer}>
        <Icon icon={icon} className={styles.icon} />
      </div>
      {messageKey ? (
        <Flex align="center" direction="column">
          <Text className={styles.title} mb={16}>
            {t(titleKey)}
          </Text>
          <Text className={styles.getStartedMessage}>{t(messageKey)}</Text>
        </Flex>
      ) : (
        <Text className={styles.title}>{t(titleKey)}</Text>
      )}
      <Flex gap={12}>
        {buttons.map((button, index) => (
          <Button
            key={index}
            leftIcon={<Icon icon={button.icon || 'plus'} />}
            variant="default"
            onClick={() => navigate(button.navigateTo)}
          >
            {t(button.textKey)}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
};
