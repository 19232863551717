import { useQuery } from '@tanstack/react-query';
import { AxiosProxy } from '../../../../api/axiosProxy/axiosProxy';
import { rejectFailedRequestOrEmptyData } from '../../../../lib/request/rejectFailedRequest/rejectFailedRequest';
import { CompositePermissionUrn, PermissionUrn } from './permissions.type';

export interface CompositePermission {
  urn: CompositePermissionUrn;
  permissions: PermissionUrn[];
}

export interface MappedCompositePermission {
  urn: CompositePermissionUrn;
  name: string;
  displayName: string;
  description: string;
  permissions: string[];
  isSelected: boolean;
  isRemovable: boolean;
}

export interface PermissionsResponseData {
  standard?: PermissionUrn[];
  composite?: CompositePermission[];
}

export interface PermissionsResponseDataMapped {
  standard: PermissionUrn[];
  composite: CompositePermission[];
}

interface Options {
  enabled?: boolean;
  staleTime?: number;
}

export const PERMISSIONS_QUERY_KEY = 'permissions';

export const useFetchPermissions = (options?: Options) => {
  return useQuery({
    queryKey: [PERMISSIONS_QUERY_KEY],
    queryFn: async () => {
      const response = await AxiosProxy.get({
        url: 'permissions',
      });
      const { data } =
        await rejectFailedRequestOrEmptyData<PermissionsResponseData>(response);

      return {
        standard: data.standard ?? [],
        composite: data.composite ?? [],
      };
    },
    enabled: options?.enabled,
  });
};
