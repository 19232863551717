import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Group, Text } from '@mantine/core';
import styles from './DisableGithubLabelsConfirmationModal.module.scss';

interface DisableGithubLabelsConfirmationModalProps {
  opened: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const DisableGithubLabelsConfirmationModal = ({
  opened,
  onConfirm,
  onCancel,
}: DisableGithubLabelsConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      opened={opened}
      onClose={onCancel}
      title={t('page.userSettings.integrations.github.labels.disableModal.title')}
      classNames={{ title: styles.title, content: styles.content }}
    >
      <Text mb="md" className={styles.description}>
        {t('page.userSettings.integrations.github.labels.disableModal.description')}
      </Text>
      <Group position="right">
        <Button variant="default" onClick={onCancel} data-testid="cancel-button">
          {t('page.userSettings.integrations.github.labels.disableModal.cancel')}
        </Button>
        <Button
          onClick={onConfirm}
          data-testid="confirm-button"
          className={styles.cancelButton}
          color="red"
        >
          {t('page.userSettings.integrations.github.labels.disableModal.turnOff')}
        </Button>
      </Group>
    </Modal>
  );
};
