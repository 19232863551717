import { Card, Flex, Text, Title, Tooltip } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Icon';
import styles from './TopAssets.module.scss';
import { ProductIcon } from '../../../Icons/ProductIcon/ProductIcon';

export const EmptyStateProductOverviewTopAssets = () => {
  const { t } = useTranslation();

  return (
    <Card className={styles.topAssets}>
      <Flex gap={4} align="baseline">
        <Title className={styles.title}>{t('page.product.top5RiskiestAssets')} </Title>
        <Tooltip label={t('page.product.riskiestAssetsTooltip')} withArrow>
          <span>
            <Icon icon="info-circle" className={styles.infoIcon} />
          </span>
        </Tooltip>
      </Flex>

      <Flex align="center" justify="center" h="100%" direction="column" gap={20}>
        <div className={styles.iconContainer}>
          <ProductIcon className={styles.icon} />
        </div>
        <Flex align="center" direction="column">
          <Text className={styles.titleEmptyState} mb={16}>
            {t('page.product.pleaseAddAssets')}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};
