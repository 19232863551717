import {
  Checkbox,
  CloseButton,
  Divider,
  Flex,
  MultiSelect,
  Slider,
  Stack,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetchSharingPortal } from '../../api/sharingPortal/hooks/useFetchSharingportal/useFetchSharingPortal';
import { useFetchAssetAuthors } from '../../hooks/queries/useFetchAssetAuthors';
import { useFetchAssetCVEIDs } from '../../hooks/queries/useFetchAssetCVEIDs';
import { useFetchAssetSuppliers } from '../../hooks/queries/useFetchAssetSuppliers';
import { LabelInterface, useFetchLabels } from '../../hooks/queries/useFetchLabels';
import Icon from '../Icon/Icon';
import '../Labels/Labels.scss';
import styles from './AssetsTableFilters.module.scss';

interface AssetsTableFiltersProps {
  filters: {
    filterOnlyHasVulns: boolean;
    filterOnlyHasCriticalVulns: boolean;
    filterOnlyHasLicenseIssues: boolean;
    filterOnlyFirstParty: boolean;
    filterOnlyThirdParty: boolean;
    filterIsActive: boolean;
    filterSharedToPortal: boolean;
    filterRiskScore: string[];
    filterCveIds: string[];
    filterCvssScore: number;
    filterAssetAuthor: string[];
    filterAssetSupplier: string[];
    filterAssetTypes: string[];
    filterLicenseTypes: string[];
    filterLabels: string[];
    filterAssetName: string;
  };
  setters: {
    setFilterOnlyHasVulns: (value: boolean) => void;
    setFilterOnlyHasCriticalVulns: (value: boolean) => void;
    setFilterOnlyHasLicenseIssues: (value: boolean) => void;
    setFilterOnlyFirstParty: (value: boolean) => void;
    setFilterOnlyThirdParty: (value: boolean) => void;
    setFilterIsActive: (value: boolean) => void;
    setFilterSharedToPortal: (value: boolean) => void;
    setFilterRiskScore: (value: string[]) => void;
    setFilterCveIds: (value: string[]) => void;
    setFilterCvssScore: (value: number) => void;
    setFilterAssetAuthor: (value: string[]) => void;
    setFilterAssetSupplier: (value: string[]) => void;
    setFilterAssetTypes: (value: string[]) => void;
    setFilterLicenseTypes: (value: string[]) => void;
    setFilterLabels: (value: string[]) => void;
    setFilterAssetName: (value: string) => void;
  };
}

export function SortLablesBySource(labels: LabelInterface[]) {
  return labels
    .map((label) => ({
      color: label.color,
      label: label.name!,
      value: label._id?.toString()!,
      source: label.source,
    }))
    .sort((a, b) => {
      const getPriority = (source?: string) => {
        if (!source || source === 'manifest') return 0;
        return 1;
      };
      return getPriority(a.source) - getPriority(b.source);
    });
}

export const AssetsTableFilters = ({ filters, setters }: AssetsTableFiltersProps) => {
  const { t } = useTranslation();

  const { data: assetAuthors = [] } = useFetchAssetAuthors();
  const { data: assetSuppliers = [] } = useFetchAssetSuppliers();
  const { data: cveIds = [] } = useFetchAssetCVEIDs();
  const { data: labelsRaw = [] } = useFetchLabels({});
  const { data: sharingPortal } = useFetchSharingPortal();
  const isEnabledSharingPortal = Boolean(sharingPortal?.isActive);

  const labels = SortLablesBySource(labelsRaw);

  return (
    <>
      <Title
        order={3}
        className="cap-letter-spacing"
        color="cyan"
        size="lg"
        mt="0"
        mb="sm"
      >
        {t('tables.assets.filters.suggested')}
      </Title>
      <Divider my={0} />
      <Stack my="md">
        <Flex align="center" justify="space-between">
          <Checkbox
            label={t('tables.assets.filters.onlyHasVulns')}
            name="has-vulns"
            checked={filters.filterOnlyHasVulns}
            classNames={{ label: styles.suggestedFilterLabel }}
            onChange={(event) => setters.setFilterOnlyHasVulns(event.target.checked)}
          />
          <Tooltip label={t('tables.assets.filters.onlyHasVulns-tooltip')}>
            <span className="icon-tooltip-cont">
              <Icon icon="info-circle" />
            </span>
          </Tooltip>
        </Flex>
        <Flex align="center" justify="space-between">
          <Checkbox
            label={t('tables.assets.filters.onlyHasCriticalVulns')}
            name="has-critical-vulns"
            checked={filters.filterOnlyHasCriticalVulns}
            classNames={{ label: styles.suggestedFilterLabel }}
            onChange={(event) =>
              setters.setFilterOnlyHasCriticalVulns(event.target.checked)
            }
          />
          <Tooltip label={t('tables.assets.filters.onlyHasCriticalVulns-tooltip')}>
            <span className="icon-tooltip-cont">
              <Icon icon="info-circle" />
            </span>
          </Tooltip>
        </Flex>
        <Flex align="center" justify="space-between">
          <Checkbox
            label={t('tables.assets.filters.onlyHasLicenseIssues')}
            name="has-license-issues"
            checked={filters.filterOnlyHasLicenseIssues}
            classNames={{ label: styles.suggestedFilterLabel }}
            onChange={(event) =>
              setters.setFilterOnlyHasLicenseIssues(event.target.checked)
            }
          />
          <Tooltip label={t('tables.assets.filters.onlyHasLicenseIssues-tooltip')}>
            <span className="icon-tooltip-cont">
              <Icon icon="info-circle" />
            </span>
          </Tooltip>
        </Flex>
        <Flex align="center" justify="space-between">
          <Checkbox
            label={t('tables.assets.filters.onlyFirstParty')}
            name="only-first-party"
            checked={filters.filterOnlyFirstParty}
            classNames={{ label: styles.suggestedFilterLabel }}
            onChange={(event) => setters.setFilterOnlyFirstParty(event.target.checked)}
          />
          <Tooltip label={t('tables.assets.filters.onlyFirstParty-tooltip')}>
            <span className="icon-tooltip-cont">
              <Icon icon="info-circle" />
            </span>
          </Tooltip>
        </Flex>
        <Flex align="center" justify="space-between">
          <Checkbox
            label={t('tables.assets.filters.onlyThirdParty')}
            name="only-third-party"
            checked={filters.filterOnlyThirdParty}
            classNames={{ label: styles.suggestedFilterLabel }}
            onChange={(event) => setters.setFilterOnlyThirdParty(event.target.checked)}
          />
        </Flex>
        <Flex align="center" justify="space-between">
          <Checkbox
            label={t('tables.assets.filters.activeAssetsOnly')}
            name="onlyActiveAssets"
            checked={filters.filterIsActive}
            classNames={{ label: styles.suggestedFilterLabel }}
            onChange={(event) => setters.setFilterIsActive(event.target.checked)}
          />
          <Tooltip
            label={t('tables.assets.filters.activeAssetsOnlyTooltip')}
            classNames={{ tooltip: styles.tooltip }}
          >
            <span className="icon-tooltip-cont">
              <Icon icon="info-circle" />
            </span>
          </Tooltip>
        </Flex>
        {isEnabledSharingPortal && (
          <Flex align="center">
            <Checkbox
              label={t('tables.assets.filters.sharedToPortal')}
              name="sharedOnPortal"
              checked={filters.filterSharedToPortal}
              classNames={{ label: styles.suggestedFilterLabel }}
              onChange={(event) => setters.setFilterSharedToPortal(event.target.checked)}
            />
          </Flex>
        )}
      </Stack>

      <Title
        order={3}
        className="cap-letter-spacing"
        color="cyan"
        size="lg"
        mt="md"
        mb="sm"
      >
        {t('tables.assets.filters.vulnerabilities')}
      </Title>
      <Divider />
      <Stack my="md">
        <MultiSelect
          clearable
          searchable
          label={t('tables.assets.filters.cveIds')}
          placeholder={t('global.select')}
          data={cveIds}
          value={filters.filterCveIds}
          onChange={(value) => setters.setFilterCveIds(value)}
          rightSection={filters.filterCveIds?.length ? null : undefined}
        />
        <Stack className="range-slider" py="md">
          <Flex align="center" justify="space-between">
            <Text fw={500} size="sm">
              {t('tables.assets.filters.cvssScore')}
            </Text>
            <Tooltip label={t('tables.assets.filters.cvssScore-tooltip')}>
              <span className="icon-tooltip-cont">
                <Icon icon="info-circle" />
              </span>
            </Tooltip>
          </Flex>
          <Slider
            min={0}
            max={10}
            defaultValue={filters.filterCvssScore}
            onChangeEnd={(value) => setters.setFilterCvssScore(value)}
          />
          <Text color="gray" size="sm">
            {t('tables.assets.filters.cvssScore-value', {
              value: filters.filterCvssScore,
              severity:
                filters.filterCvssScore < 4
                  ? t('global.low')
                  : filters.filterCvssScore < 7
                    ? t('global.medium')
                    : filters.filterCvssScore < 9
                      ? t('global.high')
                      : t('global.critical'),
            })}
          </Text>
        </Stack>
      </Stack>

      <Title
        order={3}
        className="cap-letter-spacing"
        color="cyan"
        size="lg"
        mt="md"
        mb="sm"
      >
        {t('tables.assets.filters.assets')}
      </Title>
      <Divider />
      <Stack mt="md" mb="xl" pb="xl">
        <MultiSelect
          clearable
          data={[
            { value: 'low', label: t('tables.assets.filters.low') },
            { value: 'medium', label: t('tables.assets.filters.medium') },
            { value: 'high', label: t('tables.assets.filters.high') },
          ]}
          label={
            <Flex align="center" justify="space-between">
              <Text fw={500} size="sm">
                {t('tables.assets.filters.riskScore')}
              </Text>
              <Tooltip label={t('tables.assets.filters.riskScore-tooltip')}>
                <span className="icon-tooltip-cont">
                  <Icon icon="info-circle" />
                </span>
              </Tooltip>
            </Flex>
          }
          placeholder={t('global.select')}
          onChange={(value) => setters.setFilterRiskScore(value ?? [])}
          value={filters.filterRiskScore}
          rightSection={filters.filterRiskScore?.length ? null : undefined}
        />
        <MultiSelect
          clearable
          searchable
          label={t('tables.assets.filters.labels')}
          placeholder={t('global.select')}
          data={labels}
          itemComponent={forwardRef<HTMLDivElement, any>(
            ({ label, color, source, ...rest }, ref) => (
              <Flex ref={ref} {...rest}>
                {source === 'github' ? (
                  <div className={`label-color-container ${styles.githubIcon}`}>
                    <Icon icon="github" iconStyle="fab" />
                  </div>
                ) : (
                  <div className="label-color-container">
                    <div className="label-color" style={{ backgroundColor: color }} />
                  </div>
                )}
                <div className="label-name">{label}</div>
              </Flex>
            ),
          )}
          valueComponent={forwardRef<HTMLDivElement, any>(
            ({ label, color, source, onRemove, ...rest }, ref) => (
              <Flex ref={ref} {...rest}>
                {source === 'github' ? (
                  <div className={`label-color-container ${styles.githubIcon}`}>
                    <Icon icon="github" iconStyle="fab" />
                  </div>
                ) : (
                  <div className="label-color-container">
                    <div className="label-color" style={{ backgroundColor: color }} />
                  </div>
                )}
                <div className="label-name">{label}</div>
                <CloseButton
                  onMouseDown={onRemove}
                  variant="transparent"
                  size={22}
                  iconSize={14}
                  tabIndex={-1}
                />
              </Flex>
            ),
          )}
          value={filters.filterLabels}
          onChange={(value) => setters.setFilterLabels(value)}
          rightSection={filters.filterLabels?.length ? null : undefined}
        />
        <MultiSelect
          clearable
          searchable
          data={assetAuthors}
          label={t('tables.assets.filters.author')}
          placeholder={t('global.select')}
          onChange={(value) => setters.setFilterAssetAuthor(value)}
          value={filters.filterAssetAuthor}
          rightSection={filters.filterAssetAuthor?.length ? null : undefined}
        />
        <MultiSelect
          clearable
          searchable
          data={assetSuppliers}
          label={t('tables.assets.filters.supplier')}
          placeholder={t('global.select')}
          onChange={(value) => setters.setFilterAssetSupplier(value)}
          value={filters.filterAssetSupplier}
          rightSection={filters.filterAssetSupplier?.length ? null : undefined}
        />
        <MultiSelect
          clearable
          searchable
          label={t('tables.assets.filters.types')}
          placeholder={t('global.select')}
          data={[
            { label: t('tables.assets.filters.application'), value: 'application' },
            { label: t('tables.assets.filters.container'), value: 'container' },
            { label: t('tables.assets.filters.file'), value: 'file' },
            { label: t('tables.assets.filters.library'), value: 'library' },
            {
              label: t('tables.assets.filters.operating-system'),
              value: 'operating-system',
            },
            { label: t('tables.assets.filters.firmware'), value: 'firmware' },
            { label: t('tables.assets.filters.device'), value: 'device' },
            { label: t('tables.assets.filters.other'), value: 'other' },
          ]}
          onChange={(value) => setters.setFilterAssetTypes(value)}
          value={filters.filterAssetTypes}
          rightSection={filters.filterAssetTypes?.length ? null : undefined}
        />
        <MultiSelect
          clearable
          searchable
          label={t('tables.assets.filters.licenseTypes')}
          placeholder={t('global.select')}
          data={[
            { label: 'Copyleft', value: 'Copyleft' },
            { label: 'Custom', value: 'Custom' },
            { label: 'Permissive', value: 'Permissive' },
            { label: 'Proprietary', value: 'Proprietary' },
          ]}
          value={filters.filterLicenseTypes}
          onChange={(value) => setters.setFilterLicenseTypes(value)}
          rightSection={filters.filterLicenseTypes?.length ? null : undefined}
        />
      </Stack>
    </>
  );
};
