import { Button, Loader } from '@mantine/core';
import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ClickableRegion from '../../components/ClickableRegion';
import Icon from '../../components/Icon/Icon';
import appConfig from '../../configs/appConfig';
import { useFetchOrganization } from '../../hooks/queries/useFetchOrganization';
import { useAuth } from '../../hooks/useAuth';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import '../../scss/pages/userSettings.scss';
import { NavItem, useSettingsNavOptions } from './getNavOptions';
import {
  getJiraIntegration,
  getLinearIntegration,
  getServiceNowIntegration,
} from './integrations/integrations-data';

import { ProtectedRoute } from '../../components/ProtectedRoute/ProtectedRoute';
import { IS_RBAC_ENABLED } from '../../global.constants';
import FeatureToggle from '../FeatureToggle';
import ApiTokens from './ApiTokens/ApiTokens';
import Integrations from './Integrations';
import { AddIntegration } from './integrations/AddIntegration';
import { GitHubIntegration } from './integrations/github/GitHubIntegration';
import { RemoveIntegration } from './integrations/RemoveIntegration';
import Labels from './Labels/Labels';
import Licenses from './Licenses';
import Notifications from './Notifications/Notifications';
import OrganizationUsers from './OrganizationUsers';
import PeoplePage from './People/PeoplePage';
import { Profile } from './Profile/Profile';
import { RoleDetail } from './Roles/RoleDetail';
import { Roles } from './Roles/Roles';
import AlertCreate from './SbomUploadAlerts/pages/AlertCreate/AlertCreate';
import SbomAlertDetail from './SbomUploadAlerts/pages/AlertDetail/AlertDetail';
import SbomUploadAlertEdit from './SbomUploadAlerts/pages/AlertEdit/AlertEdit';
import SbomUploadAlerts from './SbomUploadAlerts/pages/AlertList/AlertsList';
import { SharingPortalSettings } from './SharingPortal/SharingPortalSettings';
import Sla from './Sla';
import SubOrganizations from './SubOrganizations';
import UploadSettings from './UploadSettings';

function UserSettings() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const [currentOrgId] = useOrganizationId();
  const { data: organization } = useFetchOrganization({
    organizationId: currentOrgId,
  });
  const sbomUploadAlertsFlag = useFeatureFlag('sbomUploadAlerts');

  const nav: NavItem[] = useSettingsNavOptions({
    t,
    pathname,
    sbomUploadAlertsFlag,
    enabledFeatures: organization?.enabledFeatures || [],
    user: user || undefined,
    currentOrgId,
  });

  return (
    <>
      <Helmet title={t('page.userSettings.page-title')}>
        <meta name="description" content={t('app.oneliner')} />
      </Helmet>
      <div className="user-settings-container">
        <div className="navigation-pane" data-testid="user-settings-sidebar">
          <div className="pane-content">
            <div className="header">{t('navigation.settings')}</div>
            {nav.map((section) => {
              return (
                <div className="section" key={section.id}>
                  <div className="section-header">
                    <div className="section-icon">
                      {section.id === 'settings.menu.section.organization' && (
                        <Icon icon="user" />
                      )}
                      {section.id === 'settings.menu.section.account' && (
                        <Icon icon="user" />
                      )}
                      {section.id === 'settings.menu.section.membership' && (
                        <Icon icon="users" iconStyle="fal" />
                      )}
                    </div>
                    <div className="section-title">{section.title}</div>
                  </div>
                  <div className="section-items">
                    {section.items.map((item) => {
                      return (
                        <ClickableRegion
                          className="section-item-clickable"
                          href={item.path}
                          key={item.id}
                        >
                          <Button
                            key={item.id}
                            className={`section-item ${pathname.includes(item.path) ? 'selected' : ''}`}
                          >
                            <div className="item-title">{item.label}</div>
                          </Button>
                        </ClickableRegion>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="vertical-divider" />
        </div>
        <div className="settings-content-container">
          <div className="settings-content">
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route
                  path="/apiTokens"
                  element={
                    <ProtectedRoute requiredPermissions={['read:api-token']}>
                      <ApiTokens />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/integrations"
                  element={
                    <ProtectedRoute requiredPermissions={['read:integration']}>
                      <Integrations />
                    </ProtectedRoute>
                  }
                />
                <Route path="/profile" element={<Profile />} />
                {appConfig.enableEmailFeatures && (
                  <Route
                    path="/notifications"
                    element={
                      <ProtectedRoute
                        requiredPermissions={['read:vulnerability-notification']}
                      >
                        <Notifications />
                      </ProtectedRoute>
                    }
                  />
                )}
                <Route
                  path="/feature-toggle"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[]}
                      availableOnlyForPlatformAdmins
                    >
                      <FeatureToggle />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/labels"
                  element={
                    <ProtectedRoute requiredPermissions={['read:label']}>
                      <Labels />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/people"
                  element={
                    <ProtectedRoute
                      requiredPermissions={[
                        'read:user',
                        'delete:user',
                        'create:user',
                        'update:user',
                      ]}
                    >
                      {IS_RBAC_ENABLED ? <PeoplePage /> : <OrganizationUsers />}
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/roles"
                  element={
                    <ProtectedRoute requiredPermissions={['read:role']}>
                      <Roles />
                    </ProtectedRoute>
                  }
                >
                  <Route
                    path=":roleId"
                    element={
                      <ProtectedRoute requiredPermissions={['read:role']}>
                        <RoleDetail />
                      </ProtectedRoute>
                    }
                  />
                </Route>
                <Route
                  path="/sla"
                  element={
                    <ProtectedRoute requiredPermissions={['read:sla']}>
                      <Sla />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/suborganizations"
                  element={
                    <ProtectedRoute requiredPermissions={['read:sub-organization']}>
                      <SubOrganizations />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/upload-settings"
                  element={
                    <ProtectedRoute requiredPermissions={['read:enrichment-setting']}>
                      <UploadSettings />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/licenses"
                  element={
                    <ProtectedRoute requiredPermissions={['read:license']}>
                      <Licenses />
                    </ProtectedRoute>
                  }
                />
                {sbomUploadAlertsFlag && (
                  <>
                    <Route
                      path="/sbom-upload-alerts"
                      element={
                        <ProtectedRoute requiredPermissions={['read:organization-alert']}>
                          <SbomUploadAlerts />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/sbom-upload-alerts/create"
                      element={
                        <ProtectedRoute
                          requiredPermissions={['create:organization-alert']}
                        >
                          <AlertCreate />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/sbom-upload-alerts/view/:alertId"
                      element={
                        <ProtectedRoute requiredPermissions={['read:organization-alert']}>
                          <SbomAlertDetail />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/sbom-upload-alerts/edit/:alertId"
                      element={
                        <ProtectedRoute
                          requiredPermissions={['update:organization-alert']}
                        >
                          <SbomUploadAlertEdit />
                        </ProtectedRoute>
                      }
                    />
                  </>
                )}
                <Route
                  path="/sharing-portal"
                  element={
                    <ProtectedRoute requiredPermissions={['read:sharing-portal']}>
                      <SharingPortalSettings />
                    </ProtectedRoute>
                  }
                />
                {/* Sub Tabs */}
                <Route
                  path="/integrations/github/*"
                  element={
                    <ProtectedRoute requiredPermissions={['read:integration']}>
                      <GitHubIntegration />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/integrations/jira/add"
                  element={
                    <ProtectedRoute requiredPermissions={['create:integration']}>
                      <AddIntegration integrationInfo={getJiraIntegration(t)} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/integrations/jira/remove"
                  element={
                    <ProtectedRoute requiredPermissions={['delete:integration']}>
                      <RemoveIntegration integrationInfo={getJiraIntegration(t)} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/integrations/servicenow/add"
                  element={
                    <ProtectedRoute requiredPermissions={['create:integration']}>
                      <AddIntegration integrationInfo={getServiceNowIntegration(t)} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/integrations/servicenow/remove"
                  element={
                    <ProtectedRoute requiredPermissions={['delete:integration']}>
                      <RemoveIntegration integrationInfo={getServiceNowIntegration(t)} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/integrations/linear/add"
                  element={
                    <ProtectedRoute requiredPermissions={['create:integration']}>
                      <AddIntegration integrationInfo={getLinearIntegration(t)} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/integrations/linear/remove"
                  element={
                    <ProtectedRoute requiredPermissions={['delete:integration']}>
                      <RemoveIntegration integrationInfo={getLinearIntegration(t)} />
                    </ProtectedRoute>
                  }
                />
                {/* Fallback */}
                <Route path="*" element={<Navigate to="apiTokens" replace />} />
              </Routes>
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserSettings;
