import React, { useMemo } from 'react';
import { MRT_Cell, MRT_ColumnDef, MRT_Row } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { RiskScoreColumn } from '../columns/RiskScore.column';
import { NameColumn } from '../columns/Name.column';
import { VersionColumn } from '../columns/Version.column';
import { SupplierHeader } from '../headers/Supplier.header';
import { SupplierColumn } from '../columns/Supplier.column';
import { VulnerabilitiesColumn } from '../columns/Vulnerabilities.column';
import { DateCreatedColumn } from '../columns/DateCreated.column';
import { LabelsColumn } from '../columns/Labels.column';
import { resolveName, resolveVulnerabilitiesCount } from '../resolvers';
import { InterfaceFullAsset } from '../../../../types/FullInterfaces';
import Icon from '../../../Icon/Icon';
import { Badge } from '@mantine/core';
import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import { ProductNameColumn } from '../columns/ProductName.column';

export interface AssetsTableColumnsProps {
  productId?: string;
  sharingPortalActive?: boolean;
  setFilterLabels: (labels: string[]) => void;
  hasWriteAccess: boolean;
  productInProductsFeatureFlag: boolean;
}

export const useAssetsTableColumns = ({
  productId,
  sharingPortalActive,
  setFilterLabels,
  hasWriteAccess,
  productInProductsFeatureFlag,
}: AssetsTableColumnsProps): MRT_ColumnDef<
  InterfaceFullAsset & { entityType?: string }
>[] => {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    if (productId && productInProductsFeatureFlag) {
      return [
        {
          accessorFn: (row: InterfaceFullAsset) => resolveName(row),
          id: 'name',
          header: t('tables.products.headers.name'),
          size: 300,
          Cell: ProductNameColumn,
        },
        {
          accessorKey: 'version',
          header: t('tables.products.headers.version'),
          size: 150,
        },
        {
          accessorKey: 'manufacturer',
          header: t('tables.products.headers.manufacturer'),
          size: 150,
        },
        {
          accessorKey: 'type',
          header: t('tables.products.headers.type'),
          size: 100,
          Cell: ({
            row,
          }: {
            row: MRT_Row<InterfaceFullAsset & { entityType?: string }>;
          }) => {
            if (row.original.entityType === 'product') {
              return t('global.product');
            } else if (row.original.entityType === 'asset') {
              return t('global.asset');
            }
          },
        },
        {
          accessorFn: (row: InterfaceFullAsset) => row.riskScore,
          id: 'risk',
          header: t('tables.products.headers.risk'),
          size: 80,
          Cell: ({ row, cell }: any) =>
            row.original?.entityType === 'asset' ? (
              <RiskScoreColumn row={row} cell={cell} />
            ) : null,
        },
        {
          accessorKey: 'dateCreated',
          header: t('tables.products.headers.dateAdded'),
          size: 150,
          Cell: ({ cell }: any) =>
            new Date(cell.getValue() as string).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            }),
        },
      ];
    }
    return [
      {
        accessorFn: (row: InterfaceFullAsset) => row.riskScore,
        id: 'riskScore',
        header: t('tables.assets.headers.riskScore'),
        size: 80,
        sortDescFirst: true,
        mantineTableHeadCellProps: { sx: { flexGrow: 0 } },
        mantineTableBodyCellProps: { align: 'left' as const },
        Cell: RiskScoreColumn,
      },
      {
        accessorFn: (row: InterfaceFullAsset) => resolveName(row),
        id: 'name',
        header: t('tables.assets.headers.assetName'),
        size: 300,
        Cell: NameColumn,
      },
      {
        accessorKey: 'version',
        header: t('tables.assets.headers.version'),
        size: 150,
        Cell: VersionColumn,
      },
      {
        accessorKey: 'supplier',
        id: 'supplier',
        size: 150,
        header: t('tables.components.headers.supplier'),
        Header: SupplierHeader,
        Cell: SupplierColumn,
      },
      {
        accessorFn: (row: InterfaceFullAsset) => resolveVulnerabilitiesCount(row),
        id: 'countVulnerabilities.total',
        header: t('tables.components.headers.vulns'),
        enableSorting: true,
        Cell: VulnerabilitiesColumn,
      },
      {
        accessorKey: 'dateCreated',
        id: 'dateCreated',
        header: t('tables.assets.headers.uploaded'),
        enableSorting: true,
        Cell: DateCreatedColumn,
      },
      {
        accessorFn: (row: InterfaceFullAsset) => row.name,
        id: 'labels',
        header: t('tables.assets.headers.labels'),
        Header: '',
        enableSorting: false,
        mantineTableBodyCellProps: { align: 'right' as const },
        Cell: ({ row }: any) => (
          <LabelsColumn<InterfaceFullAsset>
            row={row}
            onLabelClick={(labelId: string) => setFilterLabels([labelId])}
            hasWriteAccess={hasWriteAccess}
          />
        ),
      },
      {
        accessorKey: 'sharedOnPortal',
        id: 'sharedOnPortal',
        header: '',
        Header: '',
        enableSorting: false,
        mantineTableBodyCellProps: { align: 'center' as const },
        Cell: ({ row }: any) =>
          row.original.sharedOnPortal && sharingPortalActive ? (
            <Icon icon="user-group" size="lg" iconStyle="fal" />
          ) : null,
      },
      { id: 'dateModified', header: '' },
    ];
  }, [
    productId,
    t,
    sharingPortalActive,
    setFilterLabels,
    hasWriteAccess,
    productInProductsFeatureFlag,
  ]);

  return columns;
};
