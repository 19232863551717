import { TextComponent } from '@manifest-cyber/design-system/lib/components/TextComponent';
import { Badge, BadgeProps, Loader } from '@mantine/core';
import clsx from 'clsx';
import styles from './NumberBadge.module.scss';

interface NumberBadgeProps extends Omit<BadgeProps, 'classNames' | 'styles'> {
  number?: number | string;
  active?: boolean;
  isLoading?: boolean;
  withUpperCase?: boolean;
  id?: string;
}

const NumberBadge = ({
  number,
  active,
  isLoading,
  withUpperCase = true,
  ...props
}: NumberBadgeProps) => {
  return (
    <Badge
      {...props}
      className={clsx(styles.badgePrimary, active && styles.badgePrimaryActive)}
    >
      {isLoading && <Loader color="white" size={'xs'} variant="dots" />}
      <TextComponent
        variant="componentSmall"
        className={clsx(
          styles.badgePrimaryText,
          active && styles.badgePrimaryTextActive,
          !withUpperCase && styles.noTextTransform,
        )}
      >
        {!isLoading && number}
      </TextComponent>
    </Badge>
  );
};

export default NumberBadge;
