// React & External Libraries
import { Button, Drawer, Flex, Group, Menu, Text, Title } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import {
  MantineReactTable,
  MRT_GlobalFilterTextInput,
  useMantineReactTable,
} from 'mantine-react-table';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// API & Configs
import { AxiosProxy } from '../../../api/axiosProxy/axiosProxy';
import { useFetchSharingPortal } from '../../../api/sharingPortal/hooks/useFetchSharingportal/useFetchSharingPortal';
import appConfig from '../../../configs/appConfig';

// Hooks
import { useCheckActiveAssets } from '../../../hooks/assets/useCheckActiveAssets';
import { useCheckProductAssociation } from '../../../hooks/assets/useCheckProductAssociation';
import { usePostAssets } from '../../../hooks/mutations/usePostAssets';
import { usePutAssetLabels } from '../../../hooks/mutations/usePutAssetLabels';
import { useFetchAssets } from '../../../hooks/queries/useFetchAssets';
import { useFetchSBOMsByAssetIDs } from '../../../hooks/queries/useFetchSBOMsByAssetIDs';
import { useNotifications } from '../../../hooks/utils/useNotifications';

// Libraries
import { captureExceptionWithMessage } from '../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';

// Pages
import { ShareSbomModal } from '../../../pages/Assets/ShareSbomModal/ShareSbomModal';
import { AssetsActions } from '../../../pages/Sboms/Menu/AssetsActions';
import { MenuButtonItem } from '../../../pages/Sboms/Menu/MenuButtonItem';

// Types
import { InterfaceFullAsset } from '../../../types/FullInterfaces';

// Components
import { GenericEmptyState } from '../../GenericEmptyState/GenericEmptyState.component';
import Icon from '../../Icon/Icon';
import ManageLabelsModal from '../../Labels/ManageLabelsModal';
import { getDefaultTableOptions } from '../../MRT/ManifestMRT';
import OssIngestModal from '../../OssIngestModal';
import { AssetsTableFilters } from '../AssetsTableFilters';
import DataTableFooter from '../DataTableFooter';
import { AssetsTableModals } from './AssetsTableModals';

// Local Actions & State
import { useFetchUserPermissions } from '../../../api/user/useFetchUserPermissions';
import { useOrganizationFeatureFlag } from '../../../hooks/useOrganizationFeatureFlag';
import Loading from '../../Loading';
import { getAssetsToShare } from './actions/getAssetsToShare';
import { getAssetsToUpdate } from './actions/getAssetsToUpdate';
import { getCurrentFilters } from './actions/getCurrentFilters';
import useModalsState, { AssetModalsEnum } from './state/useAssetModals';
import { useAssetSelectionState } from './state/useAssetSelectionState';
import { useAssetsTableColumns } from './state/useAssetsTableColumns';
import { useFilterState } from './state/useFilterState';
import { useSelectionState } from './state/useSelectionState';
import { useShareState } from './state/useShareState';
import { useTableState } from './state/useTableState';

export interface SbomToShare {
  sbomId: string;
  isActiveAsset: boolean;
}

const defaultTableOptions = getDefaultTableOptions<InterfaceFullAsset>();

interface Props {
  embedded?: boolean;
  hasFilters?: boolean;
  productId?: string;
}

export const AssetsTable = ({
  embedded = false,
  hasFilters = true,
  productId,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { error, info: infoNotification } = useNotifications();
  const { hasPermissions } = useFetchUserPermissions();
  const hasWriteAccess = hasPermissions({
    requiredPermissions: ['update:asset'],
  });
  const { isEnabled: productInProductsFeatureFlag, isLoading: isFeatureFlagLoading } =
    useOrganizationFeatureFlag('product-in-products');

  const { pagination, setPagination, sorting, setSorting } = useTableState();
  const { sbomsToShare, setSbomsToShare } = useShareState();
  const {
    rowSelection,
    setRowSelection,
    selectedRowsAmount,
    setSelectedRowsAmount,
    selectedProductsAmount,
    setSelectedProductsAmount,
  } = useSelectionState();
  const { filters, setters } = useFilterState();
  const {
    selectedAsset,
    setSelectedAsset,
    currentActionAsset,
    setCurrentActionAsset,
    assetAssociation,
    setAssetAssociation,
  } = useAssetSelectionState();
  const { modals, modalControls } = useModalsState();

  // Not part of useModalsState due to usage contract
  const [viewExportDocument, setViewExportDocument] = useState<boolean>(false);

  const {
    fetch: fetchSBOMs,
    data: selectedSBOMs,
    isLoading: isLoadingSBOMs,
  } = useFetchSBOMsByAssetIDs();

  const { mutateAsync: putAssetLabels } = usePutAssetLabels();

  const { data: sharingPortal } = useFetchSharingPortal();
  const isEnabledSharingPortal = Boolean(sharingPortal?.isActive);

  const checkProductAssociationsMutation = useCheckProductAssociation();

  const { mutate: mutateAssets } = usePostAssets();

  const columns = useAssetsTableColumns({
    productId,
    sharingPortalActive: Boolean(sharingPortal?.isActive),
    setFilterLabels: setters.setFilterLabels,
    hasWriteAccess,
    productInProductsFeatureFlag,
  });

  const { mutate: checkActiveAssets } = useCheckActiveAssets({
    onSuccess: (data) => {
      if (
        data.success &&
        data.data === true &&
        localStorage.getItem('confirmInactiveAssetAssociation') !== 'false'
      ) {
        modalControls.openModal(AssetModalsEnum.ConfirmAssociation);
      } else {
        modalControls.openModal(AssetModalsEnum.ProductsAssociation);
      }
    },
    onError: (error) => {
      captureExceptionWithMessage('Unable to check active assets:', error);
      modalControls.openModal(AssetModalsEnum.ProductsAssociation);
    },
  });

  const {
    data: {
      data: fetchedAssets = [],
      queryInfo: { totalCount: countAssets = 0 } = {},
    } = {},
    isLoading: isLoadingAssets,
    isError: isErrorLoadingAssets,
    refetch: refetchAssets,
  } = useFetchAssets({
    productId,
    sortColumn: sorting[0]?.id,
    sortType:
      sorting[0]?.desc === true ? 'desc' : sorting[0]?.desc === false ? 'asc' : undefined,
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    filters: getCurrentFilters(filters),
  });

  // Prefetch next page for smoother navigation
  useFetchAssets({
    productId,
    sortColumn: sorting[0]?.id,
    sortType:
      sorting[0]?.desc === true ? 'desc' : sorting[0]?.desc === false ? 'asc' : undefined,
    page: pagination.pageIndex + 2,
    limit: pagination.pageSize,
    filters: getCurrentFilters(filters),
  });

  const {
    data: {
      data: fetchedModalAssets = [],
      queryInfo: { totalCount: countModalAssets = 0 } = {},
    } = {},
    isFetching: isFetchingModalAssets,
    isLoading: isLoadingModalAssets,
    isError: isErrorLoadingModalAssets,
  } = useFetchAssets({
    page: pagination.pageIndex + 1,
    limit: 100,
  });

  const selectedAssetIDs = useMemo(() => Object.keys(rowSelection), [rowSelection]);

  const isEmptyState =
    productId && !isLoadingAssets && !isErrorLoadingAssets && fetchedAssets.length === 0;

  const handleAssetProductAssociationCheck = (
    selectedAssetIds: Array<string | undefined>,
    action: 'active' | 'inactive',
  ) => {
    setAssetAssociation(selectedAssetIds);
    const validAssetIds = selectedAssetIds.filter(
      (assetId): assetId is string => !!assetId,
    );

    if (
      localStorage.getItem('markInactiveAssetWarning') !== 'false' &&
      action === 'inactive' &&
      validAssetIds.length > 0
    ) {
      checkProductAssociationsMutation.mutate(validAssetIds, {
        onSuccess: (data) => {
          if (data.success && data.data) {
            modalControls.openModal(AssetModalsEnum.MarkInactiveAssetWarning);
          } else {
            toggleAssetActiveStatus(selectedAssetIds, action);
          }
        },
      });
    } else {
      toggleAssetActiveStatus(selectedAssetIds, action);
    }
  };

  const handleMarkInactiveAssetWarning = (
    selectedAssetIds: Array<string | undefined>,
  ) => {
    modalControls.closeModal(AssetModalsEnum.MarkInactiveAssetWarning);
    toggleAssetActiveStatus(selectedAssetIds, 'inactive');
  };

  const toggleAssetActiveStatus = (
    assetIds: Array<string | undefined>,
    state: 'active' | 'inactive',
  ) => {
    const assetsToUpdate = getAssetsToUpdate(assetIds, state);

    mutateAssets(
      { assets: assetsToUpdate },
      {
        onSuccess: (response: { success: boolean; errors: string[]; data: [] }) => {
          try {
            if (response.errors.length) {
              error({
                title: t('global.error'),
                message: t('component.shareDownloadOptionButton.assetsUpdateFailed', {
                  assetsLength: Object.keys(assetsToUpdate).length,
                }),
              });
            } else {
              queryClient.invalidateQueries(['assets']);
              infoNotification({
                title: t('global.info'),
                message: t(
                  state === 'active'
                    ? 'component.shareDownloadOptionButton.assetsMarkedAsActive'
                    : 'component.shareDownloadOptionButton.assetsMarkedAsInactive',
                  {
                    assetsLength: Object.keys(assetsToUpdate).length,
                  },
                ),
              });
            }
          } catch (err) {
            captureExceptionWithMessage('toggleAssetActiveStatus:', err);
          }
        },
      },
    );
  };

  const handleDownloadSbom = async (assetRow: InterfaceFullAsset) => {
    const id = assetRow._id?.toString() || '';
    const isModifiedSbomAvailable =
      assetRow?.originatedFromEnrichedSbom || assetRow?.changeMade;
    try {
      const sbomId = (await AxiosProxy.get({ url: `sboms?assetId=${id}` }))?.data?.[0]
        ?._id;
      window.open(
        `//${appConfig?.apiUrl}/v${appConfig?.apiVersion}/sbom/download/${sbomId}?${
          isModifiedSbomAvailable ? 'modified=true&' : ''
        }redirect=1`,
      );
    } catch (e) {
      error({
        title: t('notifications.sbom-download-error.title'),
        message: t('notifications.sbom-download-error.message'),
      });
    }
  };

  // Fetch SBOMs when selected asset IDs change
  useEffect(() => {
    if (selectedAssetIDs.length) {
      fetchSBOMs(selectedAssetIDs);
    }
  }, [selectedAssetIDs]);

  const table = useMantineReactTable<InterfaceFullAsset>({
    ...defaultTableOptions,
    mantinePaperProps: { className: 'manifest-data-table-no-footer' },
    layoutMode: 'grid',
    displayColumnDefOptions: {
      'mrt-row-select': {
        size: 50,
        mantineTableHeadCellProps: { align: 'center', sx: { flexGrow: 0 } },
        mantineTableBodyCellProps: { align: 'center', sx: { flexGrow: 0 } },
      },
      'mrt-row-actions': {
        Header: '',
        size: 30,
        mantineTableBodyCellProps: { align: 'center' },
      },
    },
    columns,
    data: fetchedAssets,
    columnFilterDisplayMode: 'custom',
    getRowId: (originalRow) => originalRow?._id?.toString(),
    enableRowActions: true,
    enableRowSelection: true,
    enableFilters: false,
    enablePagination: false,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    rowCount: countAssets,
    initialState: {
      ...defaultTableOptions.initialState,
      columnVisibility: { dateModified: false },
    },
    state: {
      globalFilter: filters.filterAssetName,
      pagination,
      sorting,
      rowSelection,
      isLoading: isLoadingAssets,
      showLoadingOverlay: false,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    onGlobalFilterChange: setters.setFilterAssetName,
    mantineSearchTextInputProps: {
      placeholder: t('tables.assets.search-assets'),
      sx: { minWidth: '260px' },
    },
    renderRowActionMenuItems: ({ row }) => {
      const isActive = row.original?.isActive !== false;
      const assetId = row.original._id?.toString();

      return (
        <>
          {!embedded && (
            <>
              <Menu.Item
                onClick={() => {
                  setSelectedAsset(row.original);
                  setViewExportDocument(true);
                }}
                icon={<Icon icon="arrow-down-to-bracket" />}
              >
                {t('tables.assets.actions.downloadReport')}
              </Menu.Item>
              <Menu.Item
                onClick={() => handleDownloadSbom(row.original)}
                icon={<Icon icon="arrow-down-to-bracket" />}
              >
                {t('tables.assets.actions.downloadSbom')}
              </Menu.Item>
              {isEnabledSharingPortal &&
                hasWriteAccess &&
                appConfig.enableEmailFeatures && (
                  <MenuButtonItem
                    onClick={() => {
                      if (row.original.sbomId) {
                        setSbomsToShare([
                          { isActiveAsset: isActive, sbomId: row.original.sbomId },
                        ]);
                      }
                    }}
                    icon={'share-nodes'}
                    disabled={!row.original.sbomId}
                    disabledText={t('tables.assets.invalidSbom')}
                    text={t('tables.assets.actions.shareSbom')}
                  />
                )}
              {hasWriteAccess && (
                <Menu.Item
                  onClick={() => {
                    setCurrentActionAsset(row.original);
                    modalControls.openModal(AssetModalsEnum.EditLabelModalOpen);
                  }}
                  icon={<Icon icon="tag" />}
                >
                  {t('tables.assets.actions.editLabels')}
                </Menu.Item>
              )}
              {hasWriteAccess && (
                <Menu.Item
                  onClick={() =>
                    handleAssetProductAssociationCheck(
                      [assetId],
                      isActive ? 'inactive' : 'active',
                    )
                  }
                  icon={isActive ? <Icon icon="eye-slash" /> : <Icon icon="eye" />}
                >
                  {isActive
                    ? t('tables.assets.actions.markAsInactive')
                    : t('tables.assets.actions.markAsActive')}
                </Menu.Item>
              )}
            </>
          )}
          {embedded && productId && hasWriteAccess && (
            <Menu.Item
              onClick={() => {
                setSelectedAsset(row.original);
                modalControls.openModal(AssetModalsEnum.RemoveAsset);
              }}
              icon={<Icon icon="ban" />}
            >
              {t('tables.assets.actions.remove-from-product')}
            </Menu.Item>
          )}
        </>
      );
    },
    renderTopToolbar: ({ table }) => (
      <>
        {hasFilters && (
          <Flex justify="space-between" m="1rem 0">
            <Flex gap="xs">
              <MRT_GlobalFilterTextInput table={table} />
              <Button
                onClick={() => {
                  if (modals[AssetModalsEnum.FilterSidebarOpen]) {
                    modalControls.closeModal(AssetModalsEnum.FilterSidebarOpen);
                  } else {
                    modalControls.openModal(AssetModalsEnum.FilterSidebarOpen);
                  }
                }}
                leftIcon={<Icon icon="filter" />}
                variant="default"
              >
                {t('tables.assets.filter')}
              </Button>
            </Flex>
            <Flex gap="xs">
              {Object.keys(rowSelection).length > 0 && hasWriteAccess && (
                <Button
                  onClick={() =>
                    modalControls.openModal(AssetModalsEnum.EditLabelModalOpen)
                  }
                  variant="default"
                  leftIcon={<Icon icon="tag" />}
                >
                  {t('tables.assets.actions.add-labels')}
                </Button>
              )}
              <Menu>
                <Menu.Target>
                  <Button
                    leftIcon={<Icon icon="arrow-up-arrow-down" />}
                    variant="default"
                  >
                    {t('tables.assets.sort')}
                  </Button>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    onClick={() => table.setSorting([{ id: 'name', desc: false }])}
                  >
                    {t('tables.assets.sorting.nameAsc')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => table.setSorting([{ id: 'name', desc: true }])}
                  >
                    {t('tables.assets.sorting.nameDesc')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => table.setSorting([{ id: 'riskScore', desc: false }])}
                  >
                    {t('tables.assets.sorting.riskAsc')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => table.setSorting([{ id: 'riskScore', desc: true }])}
                  >
                    {t('tables.assets.sorting.riskDesc')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => table.setSorting([{ id: 'dateCreated', desc: true }])}
                  >
                    {t('tables.assets.sorting.uploadDateAsc')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => table.setSorting([{ id: 'dateCreated', desc: false }])}
                  >
                    {t('tables.assets.sorting.uploadDateDesc')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => table.setSorting([{ id: 'dateModified', desc: true }])}
                  >
                    {t('tables.assets.sorting.updatedDateAsc')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() =>
                      table.setSorting([{ id: 'dateModified', desc: false }])
                    }
                  >
                    {t('tables.assets.sorting.updatedDateDesc')}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
              {hasWriteAccess && (
                <Button
                  onClick={() => {
                    setSelectedRowsAmount(Object.keys(rowSelection).length);
                    checkActiveAssets(selectedAssetIDs);
                  }}
                  disabled={!Object.keys(rowSelection).length}
                >
                  <Group spacing="xs">
                    <Icon icon="plus" />
                    <span>{t('product.products-association.title')}</span>
                  </Group>
                </Button>
              )}
              <AssetsActions
                openShareToPortalModal={() => {
                  setSbomsToShare(getAssetsToShare(selectedSBOMs, fetchedAssets));
                }}
                SBOMs={selectedAssetIDs.length ? selectedSBOMs : []}
                loading={isLoadingSBOMs}
                selectedAssets={selectedAssetIDs}
                handleAssetProductAssociationCheck={handleAssetProductAssociationCheck}
                hasWriteAccess={hasWriteAccess}
              />
            </Flex>
          </Flex>
        )}
        {embedded && productId && hasWriteAccess ? (
          <Flex justify="flex-end" m="1rem 0">
            {Object.keys(rowSelection).length ? (
              <Button
                onClick={() =>
                  modalControls.openModal(AssetModalsEnum.BulkRemoveAssetsFromProduct)
                }
                variant="default"
                disabled={!Object.keys(rowSelection).length}
              >
                <Group spacing="xs">
                  <Icon icon="ban" />
                  <span>{t('page.assets.remove-from-product')}</span>
                </Group>
              </Button>
            ) : (
              <Flex gap={8}>
                <Button onClick={() => navigate('/assets')} variant="default">
                  <Group spacing="xs">
                    <Icon icon="plus" />
                    <span>{t('page.assets.add-assets')}</span>
                  </Group>
                </Button>
                {productInProductsFeatureFlag ? (
                  <Button onClick={() => navigate('/products')} variant="default">
                    <Group spacing="xs">
                      <Icon icon="plus" />
                      <span>{t('page.assets.addProducts')}</span>
                    </Group>
                  </Button>
                ) : null}
              </Flex>
            )}
          </Flex>
        ) : (
          <div style={{ marginTop: '20px' }}></div>
        )}
      </>
    ),
  });

  return (
    <>
      {isErrorLoadingAssets && (
        <ul className="page-errors anim-slideInUpShort">
          <li>{t('tables.assets.unable-to-fetch-assets')}</li>
        </ul>
      )}
      {isEmptyState && productInProductsFeatureFlag ? (
        <GenericEmptyState
          titleKey="page.product.nothingFoundInInventory"
          messageKey="page.product.getStartedMessage"
          buttons={[
            { textKey: 'page.product.addAssets', navigateTo: '/assets' },
            { textKey: 'page.product.addProducts', navigateTo: '/products' },
          ]}
        />
      ) : (
        <>
          {!embedded && (
            <>
              <Title mb="sm" order={1} className="anim-slideInDownShort">
                {t('page.assets.header')}
              </Title>
              <Text className="anim-slideInUpShort">
                {t(
                  filters.filterIsActive
                    ? 'page.assets.totalActive'
                    : 'page.assets.total',
                  {
                    count: countAssets?.toLocaleString() as any,
                  },
                )}
              </Text>
            </>
          )}
          <div className="list-assets">
            {isFeatureFlagLoading ? <Loading /> : <MantineReactTable table={table} />}
            <Drawer
              title={t('global.filters')}
              onClose={() => modalControls.closeModal(AssetModalsEnum.FilterSidebarOpen)}
              opened={modals[AssetModalsEnum.FilterSidebarOpen]}
              position="right"
              overlayProps={{ opacity: 0.3 }}
              size="xs"
              shadow="xl"
              styles={{
                inner: { top: '58px' },
                content: {
                  border: 'solid 2px var(--color-bg-layout-stroke)',
                  overflowX: 'hidden',
                },
              }}
            >
              <AssetsTableFilters filters={filters} setters={setters} />
            </Drawer>
            <DataTableFooter
              currentPage={pagination.pageIndex}
              limitPerPage={pagination.pageSize}
              totalResults={countAssets}
              onChangePage={setPagination}
            />
          </div>
          {sbomsToShare.length > 0 && (
            <ShareSbomModal
              resources={sbomsToShare}
              onChangeAcceses={() => {
                refetchAssets();
                setRowSelection({});
              }}
              onClose={() => {
                setSbomsToShare([]);
              }}
            />
          )}
          {modals[AssetModalsEnum.EditLabelModalOpen] && (
            <ManageLabelsModal
              putLabels={putAssetLabels}
              entityType="asset"
              labelIds={
                currentActionAsset
                  ? {
                      [currentActionAsset._id?.toString()!]:
                        currentActionAsset.labelIds?.map((l) => l.toString()) || [],
                    }
                  : table.getSelectedRowModel().rows.reduce(
                      (acc, row) => {
                        acc[row.original._id?.toString()!] =
                          row.original.labelIds?.map((l) => l.toString()) || [];
                        return acc;
                      },
                      {} as Record<string, string[]>,
                    )
              }
              open={modals[AssetModalsEnum.EditLabelModalOpen]}
              onClose={() => {
                setCurrentActionAsset(null);
                modalControls.closeModal(AssetModalsEnum.EditLabelModalOpen);
              }}
            />
          )}
          <OssIngestModal
            open={modals[AssetModalsEnum.OssIngestModalOpen]}
            onClose={() => modalControls.closeModal(AssetModalsEnum.OssIngestModalOpen)}
          />
          <AssetsTableModals
            modalState={modals}
            modalControls={modalControls}
            state={{
              productId,
              selectedAsset,
              fetchedAssets,
              fetchedModalAssets,
              rowSelection,
              selectedRowsAmount,
              selectedProductsAmount,
              viewExportDocument,
              assetAssociation,
            }}
            actions={{
              setRowSelection,
              setSelectedProductsAmount,
              setViewExportDocument,
              handleMarkInactiveAssetWarning,
            }}
          />
        </>
      )}
    </>
  );
};
