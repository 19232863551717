import { t } from 'i18next';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  CvssScore,
  CvssScoreProps,
  extractScoreSeverity,
} from '../../../components/CvssScore/CvssScore';
import { formatNumberToPrecision } from '../../../lib/numbers';
import { InterfaceVulnerabilityWithVexInfo } from '../../../types/FullInterfaces';

export interface CustomLink {
  link: string;
  label: string;
}

export const useVulnMetaParsedData = (vuln: InterfaceVulnerabilityWithVexInfo) => {
  const {
    description,
    aliases = [],
    references,
    publishDate,
    epssPercentile,
    epssScore,
    kevData,
    firstSeen,
    sources,
    sourceIdentifier,
    dateCreated,
  } = vuln;
  const { vulnerabilityId } = useParams<{ vulnerabilityId: string }>();
  const published = publishDate?.toString();
  const firstSeenDate = firstSeen?.toString();
  const hasDescription = (description?.length || 0) > 0;

  const filteredAliases = aliases?.filter((aliasName) => aliasName !== vulnerabilityId);
  const firstAlias = filteredAliases?.[0];
  const hasOtherAliases = filteredAliases?.length > 1;

  const nvdLink = references?.find(({ url }) => {
    return url?.includes('nvd.nist.gov');
  });

  const osvLink = references?.find(({ url }) => {
    return url?.includes('osv.dev');
  });

  const generatedReferenceLink: CustomLink | null = useMemo<{
    link: string;
    label: string;
  } | null>(() => {
    const hasAtLeastOneReference = nvdLink || osvLink;

    if (hasAtLeastOneReference || !vulnerabilityId) {
      // dont generate
      return null;
    }

    const isInNvdDB = vulnerabilityId.startsWith('CVE-');

    if (isInNvdDB) {
      return {
        link: `https://nvd.nist.gov/vuln/detail/${vulnerabilityId}`,
        label: t('page.vulnerability.details.meta-data.nvd'),
      };
    } else {
      // Cause osv.dev is case-sensitive and only wants the prefix in uppercase, and the rest of the id on lowercase
      let link = `https://osv.dev/vulnerability/${vulnerabilityId}`;
      const splittedVulnId = vulnerabilityId.split('-');

      if (splittedVulnId.length > 1) {
        const [prefix, ...id] = splittedVulnId;

        link = link.replace(vulnerabilityId, `${prefix}-${id.join('-').toLowerCase()}`);
      }
      return {
        link,
        label: t('page.vulnerability.details.meta-data.osv'),
      };
    }
  }, [nvdLink, osvLink]);

  const severity = useMemo(() => {
    const { score, severity } = extractScoreSeverity(vuln);
    return (
      <CvssScore
        score={score}
        severity={severity as CvssScoreProps['severity']}
        emptyText={t('global.notProvided')}
      />
    );
  }, [vuln]);

  const formattedExploitability =
    epssScore && `${formatNumberToPrecision(100 * epssScore, 2)}%`;
  const formattedPercentile =
    epssPercentile && `${formatNumberToPrecision(100 * epssPercentile, 2)}%`;
  const isInKev = kevData?.inKEV;

  const inKevList = useMemo(() => {
    if (isInKev) {
      return <span className="severity-medium">{t('global.yes')}</span>;
    } else {
      return <span className="detail-title">--</span>;
    }
  }, [isInKev]);

  const formattedDatePublished =
    published && DateTime.fromISO(published).toLocaleString(DateTime.DATE_SHORT);
  const formattedFirstSeen =
    firstSeenDate && DateTime.fromISO(firstSeenDate).toLocaleString(DateTime.DATE_SHORT);
  const formattedDateCreated =
    dateCreated && DateTime.fromISO(`${dateCreated}`).toLocaleString(DateTime.DATE_SHORT);

  let sourceLink: CustomLink | null = null;
  const reference = references?.find(({ type }) => type?.toUpperCase() === 'SOURCE');
  if (reference && reference.url) {
    sourceLink = {
      link: reference.url,
      label: 'Source',
    };
    const firstUnknownSource = sources?.find(
      (source) => source !== 'NVD' && source !== 'OSV',
    );
    if (firstUnknownSource) {
      sourceLink.label = firstUnknownSource;
    }
  }

  return {
    hasDescription,
    formattedExploitability,
    formattedPercentile,
    formattedDatePublished,
    inKevList,
    severity,
    firstAlias,
    hasOtherAliases,
    generatedReferenceLink,
    isInKev,
    filteredAliases,
    osvLink,
    nvdLink,
    description,
    formattedFirstSeen,
    sources,
    isCustom: !!sourceIdentifier,
    sourceLink,
    formattedDateCreated,
  };
};
