import { Button, Group, Menu, Stack } from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import ClickableRegion from '../../../../components/ClickableRegion';
import Icon from '../../../../components/Icon/Icon';
import { ManifestTabs } from '../../../../components/ManifestTabs/ManifestTabs';
import MessageWithBackground from '../../../../components/MessageWithBackground/MessageWithBackground';
import NumberBadge from '../../../../components/NumberBadge/NumberBadge';
import { ProtectedRoute } from '../../../../components/ProtectedRoute/ProtectedRoute';
import { useFetchOneGithubIntegration } from '../../../../hooks/queries/useFetchOneGithubIntegration';
import { useOrganizationId } from '../../../../hooks/utils/useOrganizationId';
import NoMatch404 from '../../../NoMatch404';
import { IIntegration } from '../integrations-data';
import { GitHubOrganizationIcon } from './components/GitHubOrganizationIcon';
import { GitHubOrganizationRemoveConfirmModal } from './components/GitHubOrganizationRemoveConfirmModal';
import { GitHubOrganizationRepositories } from './components/GitHubOrganizationRepositories/GitHubOrganizationRepositories';
import { GitHubOrganizationSettings } from './components/GitHubOrganizationSettings';

interface Props {
  integration: IIntegration;
}

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const GitHubIntegrationOrganization = ({ integration }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentOrgId] = useOrganizationId();
  const { installationId } = useParams();
  const location = useLocation();

  const [removeConfirmModalOpen, setRemoveConfirmModalOpen] = useState<boolean>(false);

  const { data: fetchedIntegrationData, isLoading: isLoadingGitHubIntegration } =
    installationId
      ? useFetchOneGithubIntegration(currentOrgId || '', installationId)
      : { data: undefined, isLoading: false };

  if (
    !isLoadingGitHubIntegration &&
    (!fetchedIntegrationData || fetchedIntegrationData.githubIntegration?.length < 1)
  ) {
    return <NoMatch404 />;
  }

  const numRepos = fetchedIntegrationData?.githubIntegration[0].repositories?.length;
  const installedAt = fetchedIntegrationData?.installedAt
    ? new Date(fetchedIntegrationData?.installedAt).toLocaleDateString(
        undefined,
        dateFormatOptions,
      )
    : '';

  const handleNavSelect = (newTab: string) => {
    navigate(newTab);
  };

  const pathSegments = location.pathname.split('/');
  const activeTab = pathSegments[pathSegments.length - 1] || 'repositories';

  return (
    <div className="github-integration-edit-organizations integration-edit-page anim-slideInDownShort">
      <ClickableRegion href="/settings/integrations/github/organizations">
        <Button variant="subtle" leftIcon={<Icon icon="arrow-left" />}>
          {t('page.userSettings.integrations.github.organization.back')}
        </Button>
      </ClickableRegion>
      {fetchedIntegrationData?.githubIntegration[0].status === 'suspended' && (
        <MessageWithBackground
          message={t(
            'page.userSettings.integrations.github.organization.suspended-banner',
          )}
          messageType="warning"
        />
      )}
      <Group position="apart">
        <div className="integration-page-header">
          <GitHubOrganizationIcon iconWidth="64" iconHeight="64" />
          <Stack spacing="xs">
            <h4>
              {fetchedIntegrationData?.githubIntegration[0].githubOrganizationDisplayName}
            </h4>
            <div className="github-organization-metadata">
              <span>
                {t('page.userSettings.integrations.github.repos', { count: numRepos })}{' '}
                &#183;{' '}
              </span>
              <span>{`${t('page.userSettings.integrations.github.added-on')} ${installedAt}`}</span>
            </div>
          </Stack>
        </div>
        <Group>
          <Menu position="bottom-end">
            <Menu.Target>
              <Button className="manifest-menu__target" variant="default">
                <Icon
                  icon="ellipsis-vertical"
                  style={{ display: 'flex' }}
                  aria-label={t('action.moreOptions')}
                />
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                className="manifest-menu__item"
                onClick={() => {
                  setRemoveConfirmModalOpen(true);
                }}
              >
                <span>
                  {t('page.userSettings.integrations.github.remove-organization')}
                </span>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
      <div className="github-integration-edit">
        <ManifestTabs defaultValue="repositories">
          <ManifestTabs.List>
            <ManifestTabs.Tab
              value="repositories"
              onClick={() => handleNavSelect('repositories')}
              rightSection={
                <NumberBadge
                  isLoading={!numRepos}
                  number={numRepos ?? 0}
                  active={activeTab === 'repositories'}
                />
              }
            >
              {t('page.userSettings.integrations.github.repositories')}
            </ManifestTabs.Tab>
            <ManifestTabs.Tab
              value="settings"
              onClick={() => handleNavSelect('settings')}
            >
              {t('page.userSettings.integrations.github.settings')}
            </ManifestTabs.Tab>
          </ManifestTabs.List>
        </ManifestTabs>
        <Routes>
          <Route
            path="repositories"
            element={
              <ProtectedRoute requiredPermissions={['read:integration']}>
                {fetchedIntegrationData && (
                  <GitHubOrganizationRepositories
                    fetchedGitHubIntegration={fetchedIntegrationData}
                    isLoadingGitHubIntegration={isLoadingGitHubIntegration}
                  />
                )}
              </ProtectedRoute>
            }
          />
          <Route
            path="settings"
            element={
              <ProtectedRoute requiredPermissions={['read:integration']}>
                {fetchedIntegrationData && (
                  <GitHubOrganizationSettings
                    fetchedGitHubIntegration={fetchedIntegrationData}
                    isLoadingGitHubIntegration={isLoadingGitHubIntegration}
                  />
                )}
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="repositories" replace />} />
        </Routes>
      </div>
      <GitHubOrganizationRemoveConfirmModal
        open={removeConfirmModalOpen}
        onClose={() => setRemoveConfirmModalOpen(false)}
        organizationId={currentOrgId || undefined}
        installationId={installationId}
      />
    </div>
  );
};
