import clsx from 'clsx';
import { ReactNode } from 'react';
import ClickableRegion from '../../ClickableRegion';
import Icon from '../../Icon';
import styles from './EditableRow.module.scss';

export const EditableRow = ({
  children,
  onClick,
  tooltipText,
  useMutedText,
  isEditable,
}: {
  children: ReactNode;
  onClick: () => void;
  tooltipText?: string;
  useMutedText?: boolean;
  isEditable?: boolean;
}) => {
  return (
    <div className={clsx(styles.container, useMutedText ? styles.mutedText : '')}>
      {children}
      {isEditable && (
        <ClickableRegion
          regionLabel={tooltipText}
          onClick={onClick}
          className={styles.editIcon}
        >
          <>
            <Icon icon="pencil" />
          </>
        </ClickableRegion>
      )}
    </div>
  );
};
