import React, { useState } from 'react';
import { Card, Select, Switch, Text, Title, Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../../components/Loading';
import { useOrganizationId } from '../../../../../hooks/utils/useOrganizationId';
import { usePostGitHubDefaultSettings } from '../../../../../hooks/mutations/usePostGitHubDefaultSettings';
import { InterfaceFullGithubIntegration } from '../../../../../types/FullInterfaces';
import styles from './GitHubOrganizationSettings.module.scss';
import { TriggerType } from '@manifest-cyber/types/interface/dbIntegration';
import { useOrganizationFeatureFlag } from '../../../../../hooks/useOrganizationFeatureFlag';
import { useDisclosure } from '@mantine/hooks';
import { DisableGithubLabelsConfirmationModal } from './DisableGithubLabelsConfirmationModal';
import { useNotifications } from '../../../../../hooks/utils/useNotifications';
import { captureExceptionWithMessage } from '../../../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import Icon from '../../../../../components/Icon';
import { notifications } from '@mantine/notifications';

interface Props {
  fetchedGitHubIntegration: InterfaceFullGithubIntegration;
  isLoadingGitHubIntegration: boolean;
}

export const GitHubOrganizationSettings = ({
  fetchedGitHubIntegration,
  isLoadingGitHubIntegration,
}: Props) => {
  const { t } = useTranslation();
  const [currentOrgId] = useOrganizationId();
  const { mutateAsync: postGitHubDefaultSettings } = usePostGitHubDefaultSettings();
  const { isEnabled: importGhLabelsFeatureFlag } =
    useOrganizationFeatureFlag('import-gh-labels');
  const [opened, { open, close }] = useDisclosure(false);

  const { success, error, custom } = useNotifications();

  const githubIntegration = fetchedGitHubIntegration.githubIntegration[0];

  const [sbomFrequency, setSbomFrequency] = useState<TriggerType>(
    (githubIntegration?.defaultRepositoryOptions?.triggerType as TriggerType) || 'push',
  );

  const [importLabels, setImportLabels] = useState<boolean>(
    githubIntegration?.defaultRepositoryOptions?.enableTopicsImport ?? false,
  );

  const [isProcessing, setIsProcessing] = useState(false);

  const handleFrequencyChange = async (value: TriggerType) => {
    setSbomFrequency(value);
    try {
      await postGitHubDefaultSettings({
        manifestOrganizationId: (
          githubIntegration?.manifestOrganizationId || currentOrgId
        )?.toString()!,
        githubOrganizationId: githubIntegration.githubOrganizationId?.toString()!,
        githubIntegrationId: githubIntegration._id?.toString()!,
        defaultRepositoryOptions: {
          triggerType: value,
          enableTopicsImport: importLabels,
        },
      });
    } catch (err) {
      captureExceptionWithMessage(
        'Github Integration Settings: Changing frequency failed',
        err,
      );
    }
  };

  const handleImportLabelsChange = async (checked: boolean) => {
    setIsProcessing(true);
    setImportLabels(checked);

    if (checked) {
      custom({
        id: 'importing-github-labels',
        title: t('page.userSettings.integrations.github.labels.settingPendingTitle'),
        message: t('page.userSettings.integrations.github.labels.settingsPendingMessage'),
        autoClose: false,
        icon: <Icon icon="sync" classNames={styles.pendingIcon} />,
      });
    }

    try {
      const response = await postGitHubDefaultSettings({
        manifestOrganizationId: (
          githubIntegration?.manifestOrganizationId || currentOrgId
        )?.toString()!,
        githubOrganizationId: githubIntegration.githubOrganizationId?.toString()!,
        githubIntegrationId: githubIntegration._id?.toString()!,
        defaultRepositoryOptions: {
          triggerType: sbomFrequency,
          enableTopicsImport: checked,
        },
      });

      // Regardless of outcome, hide the pending notification if it was shown.
      if (checked) {
        notifications.hide('importing-github-labels');
      }

      if (response.success) {
        if (checked) {
          success({
            title: t('page.userSettings.integrations.github.labels.settingsUpdatedTitle'),
            message: t(
              'page.userSettings.integrations.github.labels.settingsUpdatedMessage',
              { org: githubIntegration?.githubOrganizationDisplayName },
            ),
            autoClose: 3000,
          });
        } else {
          success({
            title: t(
              'page.userSettings.integrations.github.labels.settingsDisabledTitle',
            ),
            message: t(
              'page.userSettings.integrations.github.labels.settingsDisabledMessage',
            ),
            autoClose: 3000,
          });
        }
      } else {
        if (checked) {
          error({
            title: t('page.userSettings.integrations.github.labels.importErrorTitle'),
            message: t('page.userSettings.integrations.github.labels.importErrorMessage'),
            autoClose: 3000,
          });
        } else {
          error({
            title: t('page.userSettings.integrations.github.labels.importErrorTitle'),
            message: t('page.userSettings.integrations.github.labels.importErrorMessage'),
            autoClose: 3000,
          });
        }
      }
    } catch (err) {
      if (checked) {
        notifications.hide('importing-github-labels');
        error({
          title: t('page.userSettings.integrations.github.labels.importErrorTitle'),
          message: t('page.userSettings.integrations.github.labels.importErrorMessage'),
          autoClose: 3000,
        });
      } else {
        error({
          title: t('page.userSettings.integrations.github.labels.importErrorTitle'),
          message: t('page.userSettings.integrations.github.labels.importErrorMessage'),
          autoClose: 3000,
        });
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const confirmDisableLabels = async () => {
    close();
    await handleImportLabelsChange(false);
  };

  const cancelDisableLabels = () => {
    close();
  };

  if (isLoadingGitHubIntegration) {
    return <Loading />;
  }

  return (
    <div className="github-integration-edit-settings anim-slideInUpShort">
      <Title order={5} mt={32}>
        {t('page.userSettings.integrations.github.labels.sbomGeneration')}
      </Title>
      <Card withBorder shadow="sm" mb="md" mt={12} className={styles.card}>
        <Flex justify="space-between">
          <Flex direction="column">
            <Title order={5} className={styles.cardTitle}>
              {t(
                'page.userSettings.integrations.github.default-sbom-generation-frequency',
              )}
            </Title>
            <Text size="sm" mt={4} className={styles.cardDescription}>
              {t(
                'page.userSettings.integrations.github.labels.sbomGenerationDescription',
              )}
            </Text>
          </Flex>
          <Select
            withinPortal
            mt="sm"
            disabled={githubIntegration.status === 'suspended'}
            value={sbomFrequency}
            onChange={(value) => {
              if (value) handleFrequencyChange(value as TriggerType);
            }}
            data={[
              {
                value: 'push',
                label: t('page.userSettings.integrations.github.commit-push'),
              },
              {
                value: 'time',
                label: t('page.userSettings.integrations.github.time-based'),
              },
            ]}
          />
        </Flex>
      </Card>
      {importGhLabelsFeatureFlag ? (
        <>
          <Title order={5} weight={700} mt={32}>
            {t('page.userSettings.integrations.github.labels.title')}
          </Title>
          <Card withBorder shadow="sm" className={styles.card} mt={12}>
            <Flex justify="space-between" gap={24}>
              <Flex direction="column">
                <Title order={5} className={styles.cardTitle}>
                  {t('page.userSettings.integrations.github.labels.importGithubLabels')}
                </Title>
                <Text size="sm" mt={4} className={styles.cardDescription}>
                  {t('page.userSettings.integrations.github.labels.description')}
                </Text>
              </Flex>
              <Switch
                checked={importLabels}
                onChange={(event) => {
                  const checked = event.currentTarget.checked;
                  if (checked) {
                    void handleImportLabelsChange(true);
                  } else {
                    open();
                  }
                }}
                disabled={isProcessing}
              />
            </Flex>
          </Card>
        </>
      ) : null}
      <DisableGithubLabelsConfirmationModal
        opened={opened}
        onConfirm={confirmDisableLabels}
        onCancel={cancelDisableLabels}
      />
    </div>
  );
};
