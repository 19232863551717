import { InterfaceVulnerability } from '@manifest-cyber/types/interface/dbTables';
import { Badge, Flex, Popover } from '@mantine/core';
import {
  MantineReactTable,
  type MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_PaginationState,
  useMantineReactTable,
} from 'mantine-react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ClickableRegion from '../../../components/ClickableRegion';
import {
  CvssScore,
  CvssScoreProps,
  extractScoreSeverity,
} from '../../../components/CvssScore/CvssScore';
import DataTableFooter from '../../../components/DataTables/DataTableFooter';
import Loading from '../../../components/Loading';
import { getDefaultTableOptions } from '../../../components/MRT/ManifestMRT';
import { ManifestTag } from '../../../components/ManifestTag/ManifestTag';
import {
  useURLandLocalStorageSortingState,
  useURLFilterStringState,
} from '../../../hooks/utils/usePersistentStates';
import capitalizeWords from '../../../lib/capitalizeWords';
import {
  ProductVulnerabilitiesFilters,
  useFetchProductVulnerabilities,
} from '../../Products/products/useFetchProductVulnerabilities';
import styles from './ProductVulnerabilites.module.scss';
import { useProductVulnerabilitiesTab } from './useProductVulnerabilitiesTab.hook';
import { useOrganizationId } from '../../../hooks/utils/useOrganizationId';
import { useFetchOrganization } from '../../../hooks/queries/useFetchOrganization';
import { GenericEmptyState } from '../../../components/GenericEmptyState/GenericEmptyState.component';

export type ProductVulnerability = InterfaceVulnerability & {
  cvssScore?: number;
  affectedAssets: Array<{
    _id: string;
    packageUrlNoVersion: string;
    version: string;
    ecosystem: string;
  }>;
};

type CurrentFiltersArray = {
  [K in keyof ProductVulnerabilitiesFilters]: {
    field: K;
    value: ProductVulnerabilitiesFilters[K];
  };
}[keyof ProductVulnerabilitiesFilters][];

export const ProductVulnerabilitiesTab = () => {
  const { t } = useTranslation();
  const { productId } = useParams();
  const { getEPSSScore } = useProductVulnerabilitiesTab();

  const [currentOrgId] = useOrganizationId();
  const { data: fetchedCurrentOrganization, isLoading: isLoadingCurrentOrganization } =
    useFetchOrganization({ organizationId: currentOrgId });
  const superProductsFeatureFlag =
    !isLoadingCurrentOrganization &&
    fetchedCurrentOrganization?.enabledFeatures?.some(
      (feature) => feature === 'product-in-products',
    );

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useURLandLocalStorageSortingState(
    'productVulnerabilities',
    [],
  );
  const sortColumn = sorting[0]
    ? `${sorting[0]?.id},${sorting[0]?.desc ? 'DESC' : 'ASC'}`
    : undefined;
  const [searchText, setSearchText] = useURLFilterStringState('productVulnsSearch', '');

  const getCurrentFilters = (): CurrentFiltersArray => {
    const currentFilters: CurrentFiltersArray = [];
    if (searchText) {
      currentFilters.push({ field: 'search', value: searchText });
    }
    return currentFilters;
  };

  const {
    isFetching,
    isLoading,
    data: productVulnsResponse,
  } = useFetchProductVulnerabilities({
    productId,
    sortColumn,
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    filters: getCurrentFilters(),
  });

  const isLoadingAny = isLoading || isLoadingCurrentOrganization;
  const hasNoVulnerabilities =
    superProductsFeatureFlag && productVulnsResponse?.queryInfo?.total === 0;

  const columns = useMemo<MRT_ColumnDef<ProductVulnerability>[]>(
    () => [
      {
        header: t('tables.productVulnerabilities.headers.cveId'),
        accessorKey: 'CVE_ID',
        Cell: ({ row }) => {
          if (row.original.cveId) {
            return (
              <div className={styles.cveId}>
                <ClickableRegion
                  className="table-link"
                  regionLabel={t(
                    'page.vulnerabilities.table.body.viewVulnerabilityDetails',
                  )}
                  href={`/vulnerability/${`${row.original.cveId}`.toUpperCase()}`}
                >
                  <span>{`${row.original.cveId}`.toUpperCase()}</span>
                </ClickableRegion>
                {!!row.original?.kevData?.inKEV && (
                  <ManifestTag variant="kev" showIcon={false} />
                )}
              </div>
            );
          }
          return `${row.original.cveId}`.toUpperCase();
        },
      },
      {
        header: t('tables.productVulnerabilities.headers.recommendation'),
        accessorKey: 'RECOMMENDATION',
        Cell: ({ row }) => {
          const { recommendedAction } = row.original;
          if (recommendedAction) {
            const action = recommendedAction.toLowerCase().trim();
            const availablerecommendations = [
              { id: 'mitigate', label: t('jargon.recommendation.mitigate') },
              { id: 'monitor', label: t('jargon.recommendation.monitor') },
              { id: 'accept', label: t('jargon.recommendation.acceptRisk') },
            ];
            const rec = availablerecommendations.find((rec) => rec.id === action);
            if (!rec) return 'N/A';
            return <Badge className={styles[action]}>{capitalizeWords(rec.label)}</Badge>;
          }
          return 'N/A';
        },
      },
      {
        header: t('tables.productVulnerabilities.headers.severity'),
        accessorKey: 'SEVERITY',
        Cell: ({ row }) => {
          const { severity, score } = extractScoreSeverity(row.original);
          return (
            <CvssScore
              score={score}
              severity={severity as CvssScoreProps['severity']}
              emptyText={t('global.notProvided')}
            />
          );
        },
      },
      {
        header: t('tables.productVulnerabilities.headers.exploitability'),
        accessorKey: 'EXPLOITABILITY',
        Cell: ({ row }) => {
          if (row.original.epssScore) {
            return getEPSSScore(row.original.epssScore);
          }
          return 'N/A';
        },
      },
      {
        header: t('tables.productVulnerabilities.headers.affectedAsset'),
        accessorKey: 'affectedAssets',
        enableSorting: false,
        size: 250,
        Cell: ({ row }) => {
          const { affectedAssets } = row.original;
          if (affectedAssets?.length > 0) {
            const mainAsset = affectedAssets[0];
            return (
              <div>
                {mainAsset && (
                  <ClickableRegion
                    className="table-link"
                    regionLabel={mainAsset.packageUrlNoVersion}
                    href={`/asset/${mainAsset._id}/overview`}
                  >
                    <span>
                      {mainAsset.packageUrlNoVersion.length > 40
                        ? mainAsset.packageUrlNoVersion.substring(0, 40) + '...'
                        : mainAsset.packageUrlNoVersion}
                      &nbsp;
                    </span>
                  </ClickableRegion>
                )}
                {affectedAssets.length > 1 && (
                  <Popover position="top">
                    <Popover.Target>
                      <span className={styles.additionalCount}>{`(+${
                        affectedAssets.length - 1
                      })`}</span>
                    </Popover.Target>
                    <Popover.Dropdown className="light">
                      <div>
                        {affectedAssets.slice(1).map((asset) => (
                          <ClickableRegion
                            key={asset._id}
                            className="table-link column light"
                            regionLabel={asset.packageUrlNoVersion}
                            href={`/asset/${asset._id}/overview`}
                          >
                            <span className="affected-component">
                              {asset.packageUrlNoVersion.length > 40
                                ? asset.packageUrlNoVersion.substring(0, 40) + '...'
                                : asset.packageUrlNoVersion}
                            </span>
                          </ClickableRegion>
                        ))}
                      </div>
                    </Popover.Dropdown>
                  </Popover>
                )}
              </div>
            );
          }
        },
      },
    ],
    [t, getEPSSScore],
  );

  const defaultTableOptions = getDefaultTableOptions<ProductVulnerability>();

  const table = useMantineReactTable<ProductVulnerability>({
    ...defaultTableOptions,
    mantinePaperProps: {
      className: 'manifest-data-table-no-footer',
    },
    columns,
    data: productVulnsResponse?.data || [],
    enableFilters: false,
    enablePagination: false,
    enableRowActions: false,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    rowCount: productVulnsResponse?.queryInfo?.total ?? 0,
    initialState: {
      ...defaultTableOptions.initialState,
    },
    state: {
      globalFilter: searchText,
      pagination,
      sorting,
      isLoading,
      showLoadingOverlay: isFetching,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onGlobalFilterChange: setSearchText,
    mantineSearchTextInputProps: {
      placeholder: t('global.search'),
      sx: {
        minWidth: '260px',
      },
    },
    renderTopToolbar: ({ table }) => (
      <Flex justify={'space-between'} m="1rem 0">
        <MRT_GlobalFilterTextInput table={table} />
      </Flex>
    ),
  });

  if (isLoadingAny) {
    return <Loading />;
  }

  return (
    <>
      {hasNoVulnerabilities && !searchText ? (
        <GenericEmptyState
          titleKey="page.product.noVulnerabilities"
          messageKey="page.product.vulnerabilityInfoMessage"
          buttons={[
            { textKey: 'page.product.addAssets', navigateTo: '/assets' },
            { textKey: 'page.product.addProducts', navigateTo: '/products' },
          ]}
        />
      ) : (
        <div>
          <MantineReactTable table={table} />
          <DataTableFooter
            currentPage={pagination.pageIndex}
            limitPerPage={pagination.pageSize}
            totalResults={productVulnsResponse?.queryInfo?.total || 0}
            onChangePage={setPagination}
          />
        </div>
      )}
    </>
  );
};
