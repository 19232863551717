import { useState } from 'react';
import { InterfaceFullAsset } from '../../../../types/FullInterfaces';

export const useAssetSelectionState = () => {
  const [selectedAsset, setSelectedAsset] = useState<InterfaceFullAsset | undefined>(
    undefined,
  );
  const [currentActionAsset, setCurrentActionAsset] = useState<InterfaceFullAsset | null>(
    null,
  );
  const [assetAssociation, setAssetAssociation] = useState<Array<string | undefined>>([]);

  return {
    selectedAsset,
    setSelectedAsset,
    currentActionAsset,
    setCurrentActionAsset,
    assetAssociation,
    setAssetAssociation,
  };
};
