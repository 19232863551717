import React from 'react';
import { Modal, Button, Group, Text } from '@mantine/core';
import styles from './DeleteGithubLabelConfirmationModal.module.scss';
import { useTranslation } from 'react-i18next';

interface DeleteGithubLabelConfirmationModalProps {
  opened: boolean;
  label: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const DeleteGithubLabelConfirmationModal = ({
  opened,
  label,
  onConfirm,
  onCancel,
}: DeleteGithubLabelConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      opened={opened}
      onClose={onCancel}
      title={t('page.userSettings.labels.deleteLabelModal.title', {
        label: label,
      })}
      classNames={{ title: styles.title, content: styles.content }}
    >
      <Text mb="md" className={styles.description}>
        {t('page.userSettings.labels.deleteLabelModal.description')}
      </Text>
      <Text mb="md" className={styles.description}>
        {t('page.userSettings.labels.deleteLabelModal.other')}
      </Text>
      <Group position="right">
        <Button variant="default" onClick={onCancel} data-testid="cancel-button">
          {t('global.cancel')}
        </Button>
        <Button
          onClick={onConfirm}
          data-testid="delete-button"
          className={styles.cancelButton}
        >
          {t('global.delete')}
        </Button>
      </Group>
    </Modal>
  );
};
