import { ManifestApiResponse } from '@manifest-cyber/types/interface/apiResponse';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { IS_RBAC_ENABLED } from '../../global.constants';
import { useAuth } from '../../hooks/useAuth';
import { PermissionUrn } from '../../pages/UserSettings/Roles/api/permissions.type';
import { AxiosProxy } from '../axiosProxy/axiosProxy';
export const USER_QUERY_KEY = 'manifest-user';

interface UseFetchUserParams {
  options?: {
    staleTime?: number;
    enabled?: boolean;
  };
}

export interface UserPermissionsResponse {
  permissions: PermissionUrn[];
}

export interface MappedRoutePermission {
  isAuthorized: boolean;
  requiresAuthorization: boolean;
  redirectTo?: string;
}

export interface MappedUIElementPermission {
  isAuthorized: boolean;
  requiresAuthorization: boolean;
}

export const useFetchUserPermissions = (params: UseFetchUserParams = {}) => {
  const userResponse = useAuth();
  const userId = userResponse?.user?.id ?? '';
  const isPlatformAdminOrStaff = userResponse?.user?.isPlatformAdminOrStaff ?? false;
  const { checkUserAccess, user } = useAuth();

  const permissionsResponse = useQuery({
    queryKey: [USER_QUERY_KEY, `user/${userId}/permissions`],
    queryFn: async (): Promise<UserPermissionsResponse> => {
      const response: ManifestApiResponse<UserPermissionsResponse> = await AxiosProxy.get(
        { url: `user/${userId}/permissions` },
      );

      const result: UserPermissionsResponse = {
        permissions: response.data!.permissions,
      };

      return result;
    },
    staleTime: params.options?.staleTime,
    enabled: Boolean(userId) && params.options?.enabled,
  });

  const value = useMemo(
    () => ({
      ...permissionsResponse,
      hasPermissions: ({
        requiredPermissions,
        availableOnlyForPlatformAdmins = false,
      }: {
        requiredPermissions: PermissionUrn[];
        availableOnlyForPlatformAdmins?: boolean;
      }) => {
        if (availableOnlyForPlatformAdmins) {
          return isPlatformAdminOrStaff;
        }

        if (!IS_RBAC_ENABLED) {
          const requireWritePermissions = requiredPermissions.some(
            (permission) =>
              permission.startsWith('create:') ||
              permission.startsWith('update:') ||
              permission.startsWith('delete:'),
          );
          const isPeoplePage = requiredPermissions.some((permission) =>
            ['update:user', 'delete:user', 'create:user'].includes(permission),
          );
          const isSuborgPage = requiredPermissions.some((permission) =>
            [
              'read:sub-organization',
              'update:sub-organization',
              'delete:sub-organization',
              'create:sub-organization',
            ].includes(permission),
          );

          const isRolesPage = requiredPermissions.some((permission) =>
            ['read:role', 'create:role', 'update:role', 'delete:role'].includes(
              permission,
            ),
          );
          if (isPeoplePage || isSuborgPage) {
            return Boolean(user?.isOrganizationAdmin) || isPlatformAdminOrStaff;
          }

          if (requireWritePermissions) {
            return checkUserAccess('write');
          }

          if (isRolesPage) {
            return false; // we do not have roles in old flow
          }

          return true; // allow to view all other pages (read permissions) in old flow
        }

        return (
          requiredPermissions.every((permission) =>
            permissionsResponse.data?.permissions.includes(permission),
          ) || isPlatformAdminOrStaff
        );
      },
    }),
    [permissionsResponse.data?.permissions, user],
  );

  return value;
};
