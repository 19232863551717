import { APIQueryInformation } from '@manifest-cyber/types/interface/apiResponse';
import { APIResponse } from '../../../types/api_response.model';

export interface SucessApiResponse<T> {
  success: true;
  data?: T | null;
  errors?: string[] | null;
  queryInfo?: APIQueryInformation;
}
export interface SucessApiResponseWithData<T> {
  success: true;
  data: NonNullable<T>;
  errors?: string[] | null;
  queryInfo?: APIQueryInformation;
}

export interface FailedApiResponse {
  success: true;
  data: null;
  errors?: string[] | null;
  queryInfo?: APIQueryInformation;
}

export const rejectFailedRequestAcceptEmptyData = async <T,>(
  response: APIResponse<T>,
): Promise<SucessApiResponse<T>> => {
  if (!response.success) {
    return Promise.reject(response);
  }

  return { ...response, success: true };
};

export const rejectFailedRequestOrEmptyData = async <T,>(
  response: APIResponse<T>,
): Promise<SucessApiResponseWithData<NonNullable<T>>> => {
  if (response.success && response.data) {
    return { ...response, data: response.data as NonNullable<T>, success: true };
  } else {
    return Promise.reject(response);
  }
};
