import { MultiSelectValueProps } from '@mantine/core';
import ChipComponent from '../../../../../components/ChipComponent/ChipComponent';

const SelectValue = ({ label, color, onRemove, className }: MultiSelectValueProps) => (
  <div className={className}>
    <ChipComponent
      leftIcon={{ isVisible: true, color: color }}
      label={label}
      onClick={onRemove}
    />
  </div>
);

export default SelectValue;
