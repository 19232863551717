import { Flex } from '@mantine/core';
import { t } from 'i18next';
import { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ManifestTabs } from '../../../components/ManifestTabs/ManifestTabs';
import { InterfaceVulnerabilityWithVexInfoAndMitigations } from '../../../types/FullInterfaces';
import { ActionTab } from './ActionTab';
import { FixesTab } from './FixesTab';
import { OverviewTab } from './OverviewTabComponents/OverviewTab/OverviewTab';
import { ReferenceTab } from './ReferenceTab';

enum VulnerabilityDetailsPageTabs {
  OVERVIEW = 'overview',
  ACTIONS = 'actions',
  FIXES = 'fixes',
  REFERENCE = 'reference',
}

interface VulnerabilityV2TabsProps {
  vuln: InterfaceVulnerabilityWithVexInfoAndMitigations;
  setShowSimpleVexModal: (isOpen: boolean) => void;
  handleAddToErrors: (msg: string) => void;
}

export const VulnerabilityV2Tabs = ({
  vuln,
  handleAddToErrors,
  setShowSimpleVexModal,
}: VulnerabilityV2TabsProps) => {
  const { vulnerabilityId } = useParams<{ vulnerabilityId: string }>();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const activeTab = pathname.split('/').pop();

  const parsedActiveTab = useMemo(() => {
    // if we dont have a active tab or we dont have a "/" at the end
    if (
      !activeTab ||
      !(activeTab.toUpperCase() in VulnerabilityDetailsPageTabs) ||
      activeTab === vulnerabilityId
    ) {
      return VulnerabilityDetailsPageTabs.OVERVIEW;
    }

    return activeTab;
  }, [vulnerabilityId, activeTab]);

  return (
    <Flex direction="column" gap="24px">
      <ManifestTabs
        value={parsedActiveTab}
        onTabChange={(newTab) =>
          navigate(`${newTab}${search}` as string, { replace: true })
        }
      >
        <ManifestTabs.List>
          <ManifestTabs.Tab value={VulnerabilityDetailsPageTabs.OVERVIEW}>
            {t('page.vulnerability.details.main-tabs.overview')}
          </ManifestTabs.Tab>
          <ManifestTabs.Tab value={VulnerabilityDetailsPageTabs.ACTIONS}>
            {t('page.vulnerability.details.main-tabs.actions')}
          </ManifestTabs.Tab>
          <ManifestTabs.Tab value={VulnerabilityDetailsPageTabs.FIXES}>
            {t('page.vulnerability.details.main-tabs.fixes')}
          </ManifestTabs.Tab>
          <ManifestTabs.Tab value={VulnerabilityDetailsPageTabs.REFERENCE}>
            {t('page.vulnerability.details.main-tabs.reference')}
          </ManifestTabs.Tab>
        </ManifestTabs.List>
      </ManifestTabs>
      {parsedActiveTab === VulnerabilityDetailsPageTabs.OVERVIEW && (
        <OverviewTab
          reccomendedAction={vuln.recommendedAction}
          vulnerabilityId={vulnerabilityId}
          setShowSimpleVexModal={setShowSimpleVexModal}
        />
      )}
      {parsedActiveTab === VulnerabilityDetailsPageTabs.ACTIONS && (
        <ActionTab id={vuln._id} vexInfo={vuln.vexInfo} onError={handleAddToErrors} />
      )}
      {parsedActiveTab == VulnerabilityDetailsPageTabs.FIXES && (
        <FixesTab
          references={vuln.references}
          mitigation={vuln.mitigationFlat}
          notes={vuln.mitigationNotes || []}
        />
      )}
      {parsedActiveTab === VulnerabilityDetailsPageTabs.REFERENCE && (
        <ReferenceTab aliases={vuln.aliases} references={vuln.references} />
      )}
    </Flex>
  );
};
