import {
  useLocalStoragePaginationState,
  useURLandLocalStorageSortingState,
} from '../../../../hooks/utils/usePersistentStates';

export const useTableState = () => {
  const [pagination, setPagination] = useLocalStoragePaginationState('assets', {
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useURLandLocalStorageSortingState('assets', [
    { id: 'riskScore', desc: true },
  ]);

  return { pagination, setPagination, sorting, setSorting };
};
