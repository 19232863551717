import { InterfaceVulnerability } from '@manifest-cyber/types/interface/dbTables';
import { Button, List, Spoiler } from '@mantine/core';
import { DateTime } from 'luxon';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import capitalizeWords from '../../lib/capitalizeWords';
import ClickableRegion from '../ClickableRegion';
import { CvssScore, CvssScoreProps, extractScoreSeverity } from '../CvssScore/CvssScore';
import Icon from '../Icon';
import { ManifestTabs } from '../ManifestTabs/ManifestTabs';
import TriageForm from './TriageForm';

interface Props {
  cve: InterfaceVulnerability;
  updateIsCloseable: Dispatch<SetStateAction<boolean>>;
  shouldResetForm: boolean;
  organizationAssetId: string;
  handleTriageSaved: () => void;
}
function SingleCVETriage({
  cve,
  organizationAssetId,
  updateIsCloseable,
  shouldResetForm,
  handleTriageSaved,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>('triageState');

  const getColorClassFromRecommandation = (recommandation: string) => {
    switch (recommandation) {
      case 'mitigate':
        return 'critical';
      case 'monitor':
        return 'medium';
      case 'accept':
        return 'low';
      default:
        return 'info';
    }
  };
  const getRecommendation = () => {
    if (cve.recommendedAction) {
      if (cve.recommendedAction === 'accept') {
        return t('jargon.recommendation.acceptRisk');
      }
      return t(`jargon.recommendation.${cve.recommendedAction}`);
    }
    return t('global.notProvided');
  };
  const renderSeverity = () => {
    const { severity, score } = extractScoreSeverity(cve);

    return (
      <div className={`value pop `}>
        <CvssScore
          score={score}
          severity={severity as CvssScoreProps['severity']}
          emptyText={t('global.notProvided')}
        />
      </div>
    );
  };
  const renderEpss = (value: number | null | undefined) => {
    const percent =
      value && value < 1
        ? Math.round((value * 10000) / 100)
        : Math.round(value || 0)
          ? Math.round(value || 0)
          : 0;
    const displayValue = percent || percent === 0 ? `${percent}%` : null;

    return (
      <div className={`value pop`}>
        {displayValue && displayValue.length > 1 ? (
          <div className="severity">{capitalizeWords(`${displayValue}`)}</div>
        ) : (
          <span className="na">{t('global.notProvided')}</span>
        )}
      </div>
    );
  };

  const renderAffectedComponents = () => {
    //@ts-ignore
    const { affectedComponents } = cve;
    if (affectedComponents && affectedComponents.length > 0) {
      return (
        <div className="value">
          <Spoiler
            maxHeight={250}
            showLabel={`+ ${affectedComponents.length - 10} ${t('global.components')}`}
            hideLabel={t('global.seeLess')}
          >
            <List listStyleType="disc">
              {affectedComponents.map(
                (component: { id: string; affectedComponentName: string }) => {
                  const displayValue =
                    component.affectedComponentName.length > 35
                      ? component.affectedComponentName.substring(0, 35) + '...'
                      : component.affectedComponentName;
                  return (
                    <List.Item key={component.id}>
                      <ClickableRegion
                        regionLabel={
                          displayValue !== component.affectedComponentName
                            ? component.affectedComponentName
                            : ''
                        }
                        target="_blank"
                        className="table-link"
                        href={`/component/${component.id}/overview`}
                      >
                        {displayValue}
                      </ClickableRegion>
                    </List.Item>
                  );
                },
              )}
            </List>
          </Spoiler>
        </div>
      );
    }
  };

  const renderLink = () => {
    const { references } = cve;
    const advisoryLink = references?.find(
      (ref) => ref?.url?.includes('https://nvd.nist.gov/') || ref.type === 'ADVISORY',
    );
    const link = { url: advisoryLink?.url, type: advisoryLink?.type };
    if (!link.url) {
      link.url = references?.find((ref) => ref?.url)?.url;
      link.type = '';
    }
    if (link.type === 'ADVISORY')
      return (
        <div className="value">
          <List listStyleType="disc">
            <List.Item key={'ADVISORY'}>
              <ClickableRegion regionLabel={link.type} target="_blank" href={link.url}>
                {t('global.nvd')}
              </ClickableRegion>
            </List.Item>
          </List>
        </div>
      );
    return (
      <div className="value">
        <List listStyleType="disc">
          {references?.map((ref, index) => {
            const displayValue =
              ref.url && ref.url.length > 35 ? ref.url.substring(0, 35) + '...' : ref.url;
            return (
              <List.Item key={index}>
                <ClickableRegion
                  regionLabel={displayValue !== ref.url ? ref.url : ''}
                  target="_blank"
                  className="table-link"
                  href={ref.url}
                >
                  {displayValue}
                </ClickableRegion>
              </List.Item>
            );
          })}
        </List>
      </div>
    );
  };

  return (
    <div className="single-triage">
      <ManifestTabs
        value={activeTab}
        onTabChange={(value: string) => setActiveTab(value)}
      >
        <ManifestTabs.List>
          <ManifestTabs.Tab value="triageState">
            {t('triage.triageState')}
          </ManifestTabs.Tab>
          <ManifestTabs.Tab value="details">{t('global.details')}</ManifestTabs.Tab>
        </ManifestTabs.List>
        <ManifestTabs.Panel value="triageState">
          <TriageForm
            cves={[cve]}
            organizationAssetId={organizationAssetId}
            updateIsCloseable={updateIsCloseable}
            shouldResetForm={shouldResetForm}
            onSave={handleTriageSaved}
          />
        </ManifestTabs.Panel>
        <ManifestTabs.Panel value="details">
          <div className="details-container">
            <div className="buttons-container">
              <div className="item">
                <Button
                  variant="default"
                  className="button"
                  rightIcon={<Icon icon="arrow-up-right-from-square" />}
                  onClick={() =>
                    window.open(
                      `https://www.google.com/search?q=${cve.cveId}`,
                      '_blank',
                      'rel=noopener noreferrer',
                    )
                  }
                >
                  {t('global.google')}
                </Button>
              </div>
              <div className="item">
                <Button
                  variant="default"
                  className="button"
                  rightIcon={<Icon icon="arrow-up-right-from-square" />}
                  onClick={() =>
                    window.open(
                      `/vulnerability/${cve.cveId}`,
                      '_blank',
                      'rel=noopener noreferrer',
                    )
                  }
                >
                  {t('global.moreInfo')}
                </Button>
              </div>
            </div>
            <div className="info-container">
              <div className="row">
                <div className="item">
                  <div className="label">{t('global.recommendation')}</div>
                  <div
                    className={`value pop ${getColorClassFromRecommandation(
                      cve.recommendedAction || '',
                    )}`}
                  >
                    {getRecommendation()}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="item">
                  <div className="label">{t('jargon.severity.label')}</div>
                  {renderSeverity()}
                </div>
              </div>
              <div className="row">
                <div className="item">
                  <div className="label">{t('jargon.exploitability.epssPercentile')}</div>
                  {renderEpss(cve.epssPercentile)}
                </div>
              </div>
              <div className="row">
                <div className="item">
                  <div className="label">{t('jargon.exploitability.epssScore')}</div>
                  {renderEpss(cve.epssScore)}
                </div>
              </div>
              <div className="row">
                <div className="item">
                  <div className="horizontal">
                    <div className="label">
                      {t('vulnerabilities.table.columns.affectedComponents')}
                    </div>
                    {renderAffectedComponents()}
                  </div>
                </div>
              </div>
              <hr />
              {!!cve.sources?.length && (
                <div className="row">
                  <div className="item">
                    <div className="label">
                      {t('page.vulnerability.details.meta-data.sources')}
                    </div>
                    <div className="value">
                      {cve.sources?.map((source) => (
                        <>
                          {source}
                          <br />
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="item">
                  <div className="label">
                    {t('vulnerabilities.table.columns.publishDate')}
                  </div>
                  <div className="value">
                    {cve.publishDate
                      ? DateTime.fromISO(cve.publishDate as unknown as string).toFormat(
                          'MM/dd/yyyy',
                        )
                      : t('global.notProvided')}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="item">
                  <div className="label">
                    {t('vulnerabilities.table.columns.lastUpdated')}
                  </div>
                  <div className="value">
                    {cve.dateModified
                      ? DateTime.fromISO(cve.dateModified as unknown as string).toFormat(
                          'MM/dd/yyyy',
                        )
                      : t('global.notProvided')}
                  </div>
                </div>
              </div>
              {!!cve.references?.length && (
                <div className="row">
                  <div className="item">
                    <div className="horizontal">
                      <div className="label">{t('global.links')}</div>
                      {renderLink()}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </ManifestTabs.Panel>
      </ManifestTabs>
    </div>
  );
}

export default SingleCVETriage;
