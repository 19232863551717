import React from 'react';
import ClickableRegion from '../../../components/ClickableRegion';
import { VulnerabilityReference } from '@manifest-cyber/types/interface/db';
import Icon from '../../../components/Icon';
import { t } from 'i18next';
import { CustomLink } from '../hooks/useVulnMetaParsedData';

interface Props {
  cveId: string;
  nvdLink?: VulnerabilityReference;
  osvLink?: VulnerabilityReference;
  referenceLink?: CustomLink | null;
  sourceLink?: CustomLink | null;
  isCustom?: boolean;
}

export const VulnerabilityLinks = (props: Props) => {
  const { cveId, nvdLink, osvLink, referenceLink, sourceLink, isCustom } = props;
  if (sourceLink)
    return (
      <ClickableRegion
        className="text external_link"
        href={sourceLink.link}
        target="_blank"
      >
        {sourceLink.label}
        <Icon icon="arrow-up-right-from-square" />
      </ClickableRegion>
    );
  if (!isCustom)
    return (
      <>
        <ClickableRegion
          className="text external_link"
          href={`https://www.google.com/search?q=${cveId}`}
          target="_blank"
        >
          Google <Icon icon="arrow-up-right-from-square" />
        </ClickableRegion>
        {nvdLink && (
          <ClickableRegion
            className="text external_link"
            href={nvdLink.url}
            target="_blank"
          >
            {t('page.vulnerability.details.meta-data.nvd')}&nbsp;
            <Icon icon="arrow-up-right-from-square" />
          </ClickableRegion>
        )}
        {osvLink && (
          <ClickableRegion
            className="text external_link"
            href={osvLink.url}
            target="_blank"
          >
            {t('page.vulnerability.details.meta-data.osv')}&nbsp;
            <Icon icon="arrow-up-right-from-square" />
          </ClickableRegion>
        )}
        {referenceLink && (
          <ClickableRegion
            className="text external_link"
            href={referenceLink.link}
            target="_blank"
          >
            {referenceLink.label} <Icon icon="arrow-up-right-from-square" />
          </ClickableRegion>
        )}
      </>
    );
  return null;
};
