import { InterfaceOrganizationComponent } from '@manifest-cyber/types/interface/dbTables';
import { Badge, Popover, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import ClickableRegion from '../../../../../components/ClickableRegion';
import Icon from '../../../../../components/Icon';
import ManifestAccordion from '../../../../../components/ManifestAcordion/ManifestAcordion';
import { truncateString } from '../../../../../lib/truncateString';
import RiskyComponentData, {
  parseLicenseIssuesFromComponent,
} from '../RiskyComponentData';
import styles from './ComponentCard.module.scss';

interface ComponentCardProps {
  component: InterfaceOrganizationComponent;
}

const MAX_VERSION_LABEL_LENGTH = 10;
const COMPONENT_NAME_MAX_LENGTH = 20;

function ComponentCard({ component }: ComponentCardProps) {
  const [
    licenseIssuePopoverOpened,
    { close: closeLicenseIssuePopover, open: openLicenseIssuePopover },
  ] = useDisclosure(false);

  const licenseIssues = parseLicenseIssuesFromComponent(component);

  return (
    <ManifestAccordion.Item
      key={component?._id?.toString() || ''}
      value={component?._id?.toString() || ''}
    >
      <ManifestAccordion.Control>
        <div className="risky-components-container risky-component-card">
          <div className="name-container">
            <div>
              <ClickableRegion
                regionLabel={component.fullyQualifiedName}
                href={`/component/${component?._id?.toString()}`}
              >
                <Text>{truncateString(component.name, COMPONENT_NAME_MAX_LENGTH)}</Text>
              </ClickableRegion>
            </div>
            <Tooltip
              disabled={(component.version?.length ?? 0) < MAX_VERSION_LABEL_LENGTH}
              label={component.version}
              className={styles['flex-shrink-0']}
            >
              <Badge>
                {truncateString(component.version ?? '', MAX_VERSION_LABEL_LENGTH)}
              </Badge>
            </Tooltip>
          </div>
          <div className={`kev ${!component?.countVulnerabilities?.inKEV && 'empty'}`}>
            {component?.countVulnerabilities?.inKEV ? <Icon icon="bolt" /> : null}
            {component?.countVulnerabilities?.inKEV || '--'}
          </div>
          <div
            className={`mitigate ${
              !component?.countVulnerabilities?.mitigate && 'empty'
            }`}
          >
            {component?.countVulnerabilities?.mitigate ? (
              <Icon icon="triangle-exclamation" />
            ) : null}
            {component?.countVulnerabilities?.mitigate || '--'}
          </div>
          <div
            className={`monitor ${!component?.countVulnerabilities?.monitor && 'empty'}`}
          >
            {component?.countVulnerabilities?.monitor ? (
              <Icon icon="circle-exclamation" />
            ) : null}
            {component?.countVulnerabilities?.monitor || '--'}
          </div>

          <Popover
            disabled={!component?.countLicenseIssues?.total}
            width={200}
            position="top"
            withArrow
            shadow="md"
            opened={licenseIssuePopoverOpened}
          >
            <Popover.Target>
              <div
                onMouseEnter={openLicenseIssuePopover}
                onMouseLeave={closeLicenseIssuePopover}
                className={`license-issues ${
                  !component?.countLicenseIssues?.total && 'empty'
                }`}
              >
                {component?.countLicenseIssues?.total ? <Icon icon="key" /> : null}
                {component?.countLicenseIssues?.total || '--'}
              </div>
            </Popover.Target>
            <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
              <div className="license-issues-popover">
                {licenseIssues.map((issue) => (
                  <div className="license-issue" key={issue.id}>
                    <Icon icon="key" />
                    <span className="license-issue-name">{issue.licenseName}</span>
                    <span className="license-issue-type">{issue.type}</span>
                  </div>
                ))}
              </div>
            </Popover.Dropdown>
          </Popover>
        </div>
      </ManifestAccordion.Control>
      <ManifestAccordion.Panel>
        <RiskyComponentData component={component} />
      </ManifestAccordion.Panel>
    </ManifestAccordion.Item>
  );
}

export default ComponentCard;
