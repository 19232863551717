import { InterfaceApiTokens } from '@manifest-cyber/types/interface/dbTables';
import { Button } from '@mantine/core';
import copy from 'copy-to-clipboard';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { Notification, Table, useToaster } from 'rsuite';
import { AxiosProxy } from '../../../api/axiosProxy/axiosProxy';
import { useFetchUserPermissions } from '../../../api/user/useFetchUserPermissions';
import ClickableRegion from '../../../components/ClickableRegion';
import Icon from '../../../components/Icon/Icon';
import { captureExceptionWithMessage } from '../../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import '../../../scss/pages/api_tokens.scss';
import CreateTokenModal from '../components/CreateTokenModal';
import { ApiTokenDate } from './ApiTokenDate';
const { Column, HeaderCell, Cell } = Table;

const ApiTokens = () => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<string[]>([]);
  const [isFetchingTokens, setIsFetchingTokens] = useState(false);
  const [fetchedTokens, setFetchedTokens] = useState<InterfaceApiTokens[]>([]);
  const [isNewTokenCopied, setIsTokenCopied] = useState<boolean>(false);
  const [newTokenSecret, setNewTokenSecret] = useState<null | string>(null);
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();

  const { hasPermissions } = useFetchUserPermissions();

  const toaster = useToaster();

  const getTokenData = () => {
    if (sortColumn && sortType) {
      return fetchedTokens.sort((a, b) => {
        let x: any = a[sortColumn];
        let y: any = b[sortColumn];

        if (sortType === 'asc') {
          if (x < y) return -1;
          if (x > y) return 1;
          return 0;
        } else {
          if (x < y) return 1;
          if (x > y) return -1;
          return 0;
        }
      });
    }
    return fetchedTokens;
  };

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setIsFetchingTokens(true);
    setTimeout(() => {
      setIsFetchingTokens(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const deleteToken = async (tokenId: string) => {
    const deleteToken = await AxiosProxy.delete({
      url: `api-token/${tokenId}`,
      withCredentials: true,
    });

    if (deleteToken && deleteToken?.success) {
      setDeletedToken();
    }

    await fetchTokens();
  };

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const handleOpenCreateModal = () => {
    setCreateModalOpen(true);
  };
  const handleCloseCreateModal = () => {
    setCreateModalOpen(false);
  };

  const setCopyToken = () => {
    setIsTokenCopied(true);

    setTimeout(() => {
      setIsTokenCopied(false);
    }, 4000);
  };

  const addErrors = (errors: string[], overwrite: boolean) => {
    if (overwrite) {
      setErrors(errors);
    } else {
      setErrors((prevErrors) => {
        return [...prevErrors, ...errors];
      });
    }
  };

  useEffect(() => {
    if (errors) {
      setTimeout(() => {
        setErrors((prevErrors) => {
          const prevCopy = [...prevErrors];
          prevCopy.shift();
          return prevCopy;
        });
      }, 4000);
    }
  }, [errors.length]);

  const setDeletedToken = () => {
    toaster.push(
      <Notification type="error" header="Token Deleted!" closable>
        {t('page.userSettings.apiTokens.deleteSuccess')}
      </Notification>,
      { placement: 'topEnd' },
    );
  };

  const fetchTokens = async () => {
    setIsFetchingTokens(true);
    try {
      const orgs: {
        success: boolean;
        errors: string[];
        data: InterfaceApiTokens[];
      } = await AxiosProxy.get({ url: 'api-token' });

      if (orgs?.success) {
        if (Array.isArray(orgs?.data)) {
          setFetchedTokens(orgs?.data);
        } else {
          captureExceptionWithMessage('Administration: Fetched orgs is not an array!');
        }
      } else {
        setErrors(['Unable to fetch orgs (success false)']);
        captureExceptionWithMessage('Unable to fetch orgs (success false)');
      }

      setIsFetchingTokens(false);
    } catch (err) {
      captureExceptionWithMessage('Unable to fetch orgs!', err);
    }
  };

  return (
    <section className="page-api-tokens">
      <Helmet title={t('page.userSettings.apiTokens.header')}>
        <meta name="description" content={t('app.oneliner')} />
      </Helmet>
      <div className="header">
        <div className="header-text anim-slideInDownShort">
          {t('page.userSettings.apiTokens.header')}
        </div>
        {hasPermissions({
          requiredPermissions: ['create:api-token'],
        }) && (
          <Button leftIcon={<Icon icon="plus" />} onClick={handleOpenCreateModal}>
            {t('page.userSettings.apiTokens.btnCreateToken')}
          </Button>
        )}
      </div>

      {/* <p className='construction-notice anim-slideInUpShort'>We are currently revamping our UI and tweaking our backend - some features or datafields below may be temporarily unavailable during our transition!</p> */}

      {errors.length > 0 && (
        <ul className="page-errors anim-slideInUpShort">
          {errors.map((error, idx) => (
            <li key={idx}>{error}</li>
          ))}
        </ul>
      )}

      {newTokenSecret && newTokenSecret.length > 0 && (
        <div className="new-token-secret anim-slideInUpShort">
          <span>
            <Trans i18nKey="page.userSettings.apiTokens.tokenCreated" />
          </span>

          <ClickableRegion
            regionLabel="Click to copy to clipboard!"
            onClick={() => {
              copy(`${newTokenSecret}`);
              setCopyToken();
            }}
          >
            <div className="token">
              {newTokenSecret}
              <div className="copy-it">
                {t('page.userSettings.apiTokens.clickToCopy')}
              </div>
              {isNewTokenCopied && (
                <div className="copy-it-done anim-slideInUpShort">
                  {t('page.userSettings.apiTokens.tokenCopied')}
                </div>
              )}
            </div>
          </ClickableRegion>
        </div>
      )}

      <div className="list-tokens anim-slideInUpShort">
        <div className="display-fetched-orgs">
          <Table
            data={getTokenData()}
            height={720}
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSortColumn}
            loading={isFetchingTokens}
            renderEmpty={() => (
              <div className="table-empty">
                <ClickableRegion
                  regionLabel="Click to load organizations"
                  onClick={() => fetchTokens()}
                  className="fetch-table-data"
                >
                  <span>{t('page.userSettings.apiTokens.tokenTable.noData')}</span>
                </ClickableRegion>
              </div>
            )}
          >
            <Column sortable flexGrow={2}>
              <HeaderCell>
                {t('page.userSettings.apiTokens.headers.tokenLabel')}
              </HeaderCell>
              <Cell dataKey="tokenName" />
            </Column>

            <Column sortable flexGrow={2} fullText>
              <HeaderCell>{t('page.userSettings.apiTokens.headers.id')}</HeaderCell>
              <Cell dataKey="_id" />
            </Column>

            <Column sortable flexGrow={2} fullText>
              <HeaderCell>{t('global.description')}</HeaderCell>
              <Cell dataKey="tokenDescription" />
            </Column>

            <Column sortable flexGrow={2} fullText>
              <HeaderCell>{t('page.userSettings.apiTokens.headers.scopes')}</HeaderCell>
              <Cell dataKey="scopes">
                {(rowData, rowIndex) => {
                  if (rowData?.scopes && rowData?.scopes?.length > 0) {
                    if (rowData?.scopes === 'all' || rowData?.scopes?.includes('all')) {
                      return t('page.userSettings.apiTokens.allScopes');
                    }

                    return rowData?.scopes?.join(', ').trim();
                  }

                  return t('global.none');
                }}
              </Cell>
            </Column>

            <Column sortable flexGrow={2}>
              <HeaderCell>
                {t('page.userSettings.apiTokens.headers.expiration')}
              </HeaderCell>
              <Cell dataKey="tokenExpiration">
                {(rowData, rowIndex) => (
                  <ApiTokenDate date={rowData.tokenExpiration || ''} />
                )}
              </Cell>
            </Column>

            <Column sortable flexGrow={2}>
              <HeaderCell>{t('page.userSettings.apiTokens.headers.created')}</HeaderCell>
              <Cell dataKey="dateCreated">
                {(rowData, rowIndex) => {
                  const curr = DateTime.fromISO(rowData?.dateCreated).toLocaleString(
                    DateTime.DATETIME_MED,
                  );
                  return `${curr}`;
                }}
              </Cell>
            </Column>

            {hasPermissions({
              requiredPermissions: ['delete:api-token'],
            }) && (
              <Column flexGrow={1}>
                <HeaderCell>
                  {t('page.userSettings.apiTokens.headers.actions')}
                </HeaderCell>
                <Cell>
                  {(rowData, rowIndex) => {
                    return (
                      <ClickableRegion
                        regionLabel="Delete"
                        className="delete-btn"
                        onClick={() => deleteToken(rowData?._id)}
                      >
                        <span className="initial">
                          <Icon icon="trash" />
                        </span>
                        <span className="danger">
                          <Icon icon="trash" />
                        </span>
                      </ClickableRegion>
                    );
                  }}
                </Cell>
              </Column>
            )}
          </Table>
        </div>
      </div>
      <CreateTokenModal
        open={createModalOpen}
        onCancel={handleCloseCreateModal}
        onSuccess={setNewTokenSecret}
        addErrors={addErrors}
      />
    </section>
  );
};

export default ApiTokens;
