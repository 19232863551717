import {
  Button,
  DefaultMantineColor,
  Group,
  MantineNumberSize,
  Modal,
} from '@mantine/core';
import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './ManifestModal.module.scss';

export interface ManifestModalProps {
  opened: boolean;
  withCloseButton?: boolean;
  onClose: () => void;
  title: string;
  primaryAction: {
    label: string;
    onClick: () => void;
    isLoading?: boolean;
    color?: DefaultMantineColor;
    isDisabled?: boolean;
  };
  secondaryAction?: {
    label: string;
    onClick: () => void;
  };
  /**
   * @deprecated Use children prop instead for better React patterns and consistency
   */
  body?: ReactNode;
  children?: ReactNode;
  size?: MantineNumberSize;
  subtitle?: string;
  closeOnClickOutside?: boolean;
  closeOnEscape?: boolean;
  dataTestId?: string;
}

const ManifestModal = ({
  opened,
  withCloseButton,
  onClose,
  title,
  primaryAction,
  secondaryAction,
  body,
  children,
  size,
  subtitle,
  closeOnClickOutside,
  closeOnEscape,
  dataTestId,
}: ManifestModalProps) => {
  return (
    <Modal
      data-testid={dataTestId}
      size={size}
      opened={opened}
      onClose={onClose}
      withCloseButton={withCloseButton}
      centered
      title={title}
      classNames={{
        title: styles.title,
        body: styles.body,
        header: clsx(styles.header, subtitle && styles.headerWithSubtitle),
      }}
      closeOnClickOutside={closeOnClickOutside}
      closeOnEscape={closeOnEscape}
    >
      {subtitle && (
        <div className={styles.subtitleContainer}>
          <span className={styles.subtitle}>{subtitle}</span>
        </div>
      )}
      <div>
        <div className={styles.contentBody}>
          {/* Prefer children over body, but support both for backward compatibility */}
          {children || body}
        </div>
        <Group position="right">
          {secondaryAction && (
            <Button variant="default" onClick={secondaryAction.onClick}>
              {secondaryAction.label}
            </Button>
          )}

          {primaryAction && (
            <Button
              color={primaryAction.color}
              onClick={primaryAction.onClick}
              loading={primaryAction.isLoading}
              disabled={primaryAction.isDisabled}
            >
              {primaryAction.label}
            </Button>
          )}
        </Group>
      </div>
    </Modal>
  );
};
export default ManifestModal;
