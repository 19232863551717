import { TextComponent } from '@manifest-cyber/design-system/lib/components/TextComponent';
import { InterfaceSbom } from '@manifest-cyber/types/interface/dbTables';
import { Button, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useQueryClient } from '@tanstack/react-query';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Input, InputGroup, Uploader } from 'rsuite';
import { OverlayTriggerHandle } from 'rsuite/esm/internals/Picker';
import { shallow } from 'zustand/shallow';
import { AxiosProxy } from '../../api/axiosProxy/axiosProxy';
import { useFetchSharingPortal } from '../../api/sharingPortal/hooks/useFetchSharingportal/useFetchSharingPortal';
import { useFetchUserPermissions } from '../../api/user/useFetchUserPermissions';
import { SbomTable } from '../../components/DataTables/SbomTable';
import Icon from '../../components/Icon';
import { ManifestTabs } from '../../components/ManifestTabs/ManifestTabs';
import NumberBadge from '../../components/NumberBadge/NumberBadge';
import OssIngestModal from '../../components/OssIngestModal';
import appConfig from '../../configs/appConfig';
import { useFetchOssIngests } from '../../hooks/queries/useFetchOssIngests';
import { useFetchSBOMs } from '../../hooks/queries/useFetchSBOMs';
import { useSbomsStore } from '../../hooks/stores/sboms.store';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import '../../scss/pages/sboms.scss';
import { UploadTabStatus } from '../../types/FullInterfaces';
import DeleteModal from './deleteModal';
import { MenuButton } from './Menu/MenuButton';
import { MenuButtonItem } from './Menu/MenuButtonItem';
import MergeDownloadModal from './mergeDownload';
import MergeModal from './mergeModal';
import { fromSBOMToSelectionData } from './models/SBOMSelectionData.model';
import RequestSbomModal from './requestSbomModal';
import ShareModal from './ShareModal/shareModal';
import { UploadSettingsModal } from './UploadSettings.modal';

const Sboms = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { hasPermissions } = useFetchUserPermissions();
  const canCreateSbom = hasPermissions({
    requiredPermissions: ['create:sbom'],
  });
  const tabsToWatch: UploadTabStatus[] = ['pending', 'ingests'];
  //state
  const [isOssModalOpen, setIsOssModalOpen] = useState(false);
  const [
    uploadSettingsModalOpen,
    { close: closeUploadSettingsModal, open: openUploadSettingsModal },
  ] = useDisclosure(false);
  const [requestSbomModalOpen, setRequestSbomModalOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [mergeResultSBOMId, setMergeResultSBOMId] = useState('');
  const { data: sharingPortal } = useFetchSharingPortal();
  const isEnabledSharingPortal = Boolean(sharingPortal?.isActive);

  const {
    actions: {
      setActiveTab,
      setDeleteModalOpen,
      setErrors,
      setFetchPage,
      setIsMergeDownloadModalOpen,
      setIsMergeModalOpen,
      setIsShareTriggeredByMerge,
      setSbomIdsToDelete,
      setSelectedSBOMsData,
      setShareModalOpen,
      setUploadFileList,
    },
    activeTab,
    cantMergeDownloadReason,
    cantMergeShareReason,
    deleteModalOpen,
    errors,
    fetchLimit,
    fetchPage,
    isMergeDownloadModalOpen,
    isMergeModalOpen,
    isShareTriggeredByMerge,
    sbomIdsToDelete,
    selectedSBOMsData,
    shareModalOpen,
    sortColumn,
    sortType,
    uploadFileList,
  } = useSbomsStore(
    (state) => ({
      actions: state.actions,
      activeTab: state.activeTab,
      cantMergeDownloadReason: state.cantMergeDownloadReason,
      cantMergeShareReason: state.cantMergeShareReason,
      deleteModalOpen: state.deleteModalOpen,
      errors: state.errors,
      fetchLimit: state.fetchLimit,
      fetchPage: state.fetchPage,
      isMergeDownloadModalOpen: state.isMergeDownloadModalOpen,
      isMergeModalOpen: state.isMergeModalOpen,
      isShareTriggeredByMerge: state.isShareTriggeredByMerge,
      sbomIdsToDelete: state.sbomIdsToDelete,
      selectedSBOMsData: state.selectedSBOMsData,
      shareModalOpen: state.shareModalOpen,
      sortColumn: state.sortColumn,
      sortType: state.sortType,
      uploadFileList: state.uploadFileList,
    }),
    shallow,
  );

  //queries
  const {
    data: {
      data: successfulSboms = [],
      queryInfo: { totalCount: countSuccessfulSboms = 0 } = {},
    } = {},
    isLoading: isLoadingSuccessfulSboms,
  } = useFetchSBOMs(
    {
      sortColumn,
      sortType,
      page: fetchPage,
      limit: fetchLimit,
      searchText: searchInputValue,
      targetStatus: 'successful',
    },
    {
      refetchInterval: tabsToWatch?.includes(activeTab) ? 2_000 : 2_000,
      keepPreviousData: true,
    },
  );

  const {
    data: {
      data: failedSboms = [],
      queryInfo: { totalCount: countFailedSboms = 0 } = {},
    } = {},
    isLoading: isLoadingFailedSboms,
  } = useFetchSBOMs(
    {
      sortColumn,
      sortType,
      page: fetchPage,
      limit: fetchLimit,
      searchText: searchInputValue,
      targetStatus: 'failed',
    },
    {
      refetchInterval: tabsToWatch?.includes(activeTab) ? 2_000 : 7_000,
      keepPreviousData: true,
    },
  );

  const {
    data: {
      data: pendingSboms = [],
      queryInfo: { totalCount: countPendingSboms = 0 } = {},
    } = {},
    isLoading: isLoadingPendingSboms,
  } = useFetchSBOMs(
    {
      sortColumn,
      sortType,
      page: fetchPage,
      limit: fetchLimit,
      searchText: searchInputValue,
      targetStatus: 'pending',
    },
    {
      refetchInterval: tabsToWatch?.includes(activeTab) ? 2_000 : 7_000,
      keepPreviousData: true,
    },
  );

  const {
    data: { data: ingests = [], queryInfo: { totalCount: countIngests = 0 } = {} } = {},
    isLoading: isLoadingIngests,
  } = useFetchOssIngests(
    {
      sortColumn,
      sortType,
      page: fetchPage,
      limit: fetchLimit,
      searchText: searchInputValue,
    },
    {
      refetchInterval: tabsToWatch?.includes(activeTab) ? 2_000 : 7_000,
      keepPreviousData: true,
    },
  );

  //memos & refs
  const selectedSBOMIds = useMemo(
    () => selectedSBOMsData.map((sd) => sd.id),
    [selectedSBOMsData],
  );

  const allSboms = useMemo(
    () => [...(successfulSboms ?? []), ...(failedSboms ?? []), ...(pendingSboms ?? [])],
    [successfulSboms, failedSboms, pendingSboms],
  );

  const selectedSboms = useMemo(
    () =>
      allSboms.filter((sbom) => selectedSBOMsData.find((sd) => sd.id === `${sbom._id}`)),
    [selectedSBOMsData],
  );

  const selectedSBOMType = useMemo(() => {
    return new Set(selectedSBOMsData.map((sd) => sd.fileType));
  }, [selectedSBOMsData]);

  const areVexFilesSelected = useMemo(
    () => selectedSBOMsData.some((sbom) => sbom.fileType === 'VEX'),
    [selectedSBOMsData],
  );

  const areMultipleFilesTypeSelected = useMemo(
    () => selectedSBOMType.size > 1,
    [selectedSBOMType],
  );

  const areAllSbomsSelected = useMemo(() => {
    return (
      selectedSBOMsData.length &&
      ((activeTab === 'successful' &&
        selectedSBOMsData.length === (successfulSboms ?? []).length) ||
        (activeTab === 'failed' &&
          selectedSBOMsData.length === (failedSboms ?? []).length))
    );
  }, [activeTab, selectedSBOMsData, successfulSboms, failedSboms]);

  const mergeAndShareMenuRef = useRef<OverlayTriggerHandle>(null);
  const mergeAndDownloadMenuRef = useRef<OverlayTriggerHandle>(null);
  const searchTextRef = useRef('');

  //handlers & callbacks
  const getSuccessfulSBOMByID = useCallback(
    (id: string) => {
      return (successfulSboms as InterfaceSbom[]).find((s) => `${s._id}` === id);
    },
    [successfulSboms],
  );

  const handleOpenShareModal = () => setShareModalOpen(true);

  const handleCloseShareModal = () => {
    setIsShareTriggeredByMerge(false);
    setShareModalOpen(false);
  };

  const handleOpenDeleteModal = (sbomId?: string) => {
    if (sbomId !== undefined) {
      setSbomIdsToDelete([sbomId]);
    } else {
      setSbomIdsToDelete(selectedSBOMIds);
    }
    setDeleteModalOpen(true);
  };

  const handleDeleteSuccess = () => {
    setSbomIdsToDelete([]);
    setSelectedSBOMsData([]);
    setDeleteModalOpen(false);
    //trigger refetch
    queryClient.invalidateQueries(['sboms']);
  };

  const handleDeleteCancel = () => {
    setDeleteModalOpen(false);
  };

  const handleCloseUploadSettingsModal = () => {
    setUploadFileList([]);
    closeUploadSettingsModal();
    handleSwitchTab('pending');
  };

  const handleCloseRequestSbomModal = () => {
    setRequestSbomModalOpen(false);
  };

  const handleOpenRequestSbomsModal = () => {
    setRequestSbomModalOpen(true);
  };

  const handleDownloadRequest = async (sbomIds: string[]) => {
    if (!sbomIds.length) return;
    try {
      if (sbomIds.length === 1) {
        window.open(
          `//${appConfig?.apiUrl}/v${appConfig?.apiVersion}/sbom/download/${sbomIds[0]}?redirect=1`,
          '_blank',
        );
      } else {
        window.open(
          `//${appConfig?.apiUrl}/v${appConfig?.apiVersion}/download/bundle?fileIds=${sbomIds.join(
            ',',
          )}`,
          '_blank',
        );
      }
    } catch (e) {
      captureExceptionWithMessage(`Sboms handleDownloadRequest: `, e);
      setErrors([...errors, 'Unable to download SBOM(s)']);
    }
  };

  const handleSwitchTab = (tab: UploadTabStatus) => {
    setActiveTab(tab);
    setSelectedSBOMsData([]);
  };

  const handleSearchChange = (value: string) => {
    setSearchInputValue(value);
    debouncedSearch(value);
    searchTextRef.current = value;
  };

  const handleClear = async () => {
    setSearchInputValue('');
    searchTextRef.current = '';
  };

  const handleSearchKeyDown = (e: any) => {
    if (e.keyCode === 13 && searchInputValue.length >= 2) {
      submitSearch();
    }
  };

  const handleMergeSbomsAndShare = async () => {
    mergeAndShareMenuRef?.current?.close();
    setIsMergeModalOpen(true);
    const mergedSbomsResponse = await AxiosProxy.get({
      url: 'sboms/multiple-sbom-return',
      withCredentials: true,
      params: {
        merge: true,
        sbomIds: selectedSBOMIds.join(',') || '',
      },
    });

    if (mergedSbomsResponse && mergedSbomsResponse?.success) {
      // We should have an SBOM id now for the newly merged SBOM.
      const SBOMId = mergedSbomsResponse?.data[0]?.sbomId;
      if (SBOMId) {
        setMergeResultSBOMId(SBOMId);
        const SBOM = getSuccessfulSBOMByID(SBOMId);
        if (SBOM) {
          setSelectedSBOMsData([fromSBOMToSelectionData(SBOM)]);
        }
        setIsShareTriggeredByMerge(true); // This may affect share modal UI/copy
        handleOpenShareModal();
      }
    } else {
      setErrors(
        mergedSbomsResponse?.errors?.length > 0
          ? mergedSbomsResponse?.errors
          : 'Unknown issue while sharing!',
      );
    }

    setIsMergeModalOpen(false);
  };

  const handleMergeSbomsAndDownload = async () => {
    mergeAndDownloadMenuRef?.current?.close();
    setIsMergeDownloadModalOpen(true);
  };

  const debouncedSearch = useCallback(
    debounce(async (_nextValue) => {
      // await fetchSboms(true, null, null);
    }, 300),
    [],
  );

  const submitSearch = async () => {
    searchTextRef.current = searchInputValue;
  };

  //effects
  useEffect(() => {
    //reset page when tab changes
    setFetchPage(1);
    //trigger refetch when tab changes
    queryClient.invalidateQueries(['ossingests', 'sboms']);
  }, [activeTab]);

  useEffect(() => {
    //reset page when sort changes
    setFetchPage(1);
  }, [sortColumn, sortType]);

  useEffect(() => {
    if (uploadFileList.length > 0) {
      openUploadSettingsModal();
    }
  }, [uploadFileList]);

  return (
    <section className="page-sboms anim-slideInUpShort">
      <Helmet title={t('page.uploads.page-title')}>
        <meta name="description" content={t('app.oneliner')} />
      </Helmet>
      <div className="header-container">
        <Title order={1} className="anim-slideInDownShort">
          {t('page.uploads.header')}
        </Title>
        {canCreateSbom && (
          <Button
            className="oss-import"
            onClick={() => {
              setIsOssModalOpen(true);
            }}
            leftIcon={<Icon icon="github" iconStyle="fab" />}
          >
            {t(`page.uploads.btnImportOss`)}
          </Button>
        )}
        {hasPermissions({
          requiredPermissions: ['create:sbom-request'],
        }) &&
          appConfig.enableEmailFeatures && (
            <Button
              onClick={() => {
                handleOpenRequestSbomsModal();
              }}
            >
              {t(`page.uploads.btnRequestSboms`)}
            </Button>
          )}
      </div>

      {errors.length > 0 && (
        <ul className="page-errors anim-slideInUpShort">
          {errors.map((error) => (
            <li>{error}</li>
          ))}
        </ul>
      )}

      {canCreateSbom && (
        <div className="sbom-upload anim-slideInUpShort">
          <Uploader
            fileList={uploadFileList}
            action={`#`}
            multiple
            withCredentials
            shouldUpload={() => false}
            onChange={setUploadFileList}
            draggable
            aria-label={t('page.uploads.uploader-title')}
            title={t('page.uploads.uploader-title')}
          >
            <div
              style={{
                height: 118,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Icon icon="arrow-up-from-bracket" />
              <span>
                <span className="hilighted-text">Click to upload</span> or drag and drop a
                new SBOM or VEX Document
              </span>
              <p className="uploader-subtext">{t('page.uploads.uploader-subtext')}</p>
            </div>
          </Uploader>
        </div>
      )}

      {selectedSBOMsData?.length > 0 ? (
        <TextComponent>
          {t('page.uploads.count-selected-sboms', {
            count: selectedSBOMsData?.length || 0,
          })}
        </TextComponent>
      ) : (
        <br />
      )}

      <div className="list-sboms-content">
        <div className="sbom-nav">
          <div className="sbom-nav-left">
            <ManifestTabs variant="pills" defaultValue="successful" value={activeTab}>
              <ManifestTabs.List>
                <ManifestTabs.Tab
                  value="successful"
                  onClick={() => handleSwitchTab('successful')}
                >
                  {t('page.uploads.tabs.successful')}
                  {countSuccessfulSboms > 0 && (
                    <NumberBadge
                      number={countSuccessfulSboms}
                      active={activeTab === 'successful'}
                    />
                  )}
                </ManifestTabs.Tab>
                <ManifestTabs.Tab
                  value="pending"
                  onClick={() => handleSwitchTab('pending')}
                >
                  <>
                    {t('page.uploads.tabs.incomplete')}
                    {(pendingSboms ?? [])?.length > 0 && (
                      <NumberBadge
                        number={(pendingSboms ?? []).length}
                        active={activeTab === 'pending'}
                      />
                    )}
                  </>
                </ManifestTabs.Tab>
                <ManifestTabs.Tab
                  value="failed"
                  onClick={() => handleSwitchTab('failed')}
                >
                  {t('page.uploads.tabs.failed')}
                  {countFailedSboms > 0 && (
                    <NumberBadge
                      number={countFailedSboms}
                      active={activeTab === 'failed'}
                    />
                  )}
                </ManifestTabs.Tab>
                <ManifestTabs.Tab
                  value="ingests"
                  onClick={() => handleSwitchTab('ingests')}
                >
                  {t('page.uploads.tabs.ingests')}
                  {countIngests > 0 && (
                    <NumberBadge number={countIngests} active={activeTab === 'ingests'} />
                  )}
                </ManifestTabs.Tab>
              </ManifestTabs.List>
            </ManifestTabs>
          </div>
          <div className="sbom-nav-right">
            <InputGroup className="sboms-search-input" inside>
              {!searchInputValue ? (
                <InputGroup.Button onClick={submitSearch}>
                  <Icon icon="search" iconSharp />
                </InputGroup.Button>
              ) : (
                <InputGroup.Button onClick={handleClear}>
                  <Icon icon="times" iconSharp />
                </InputGroup.Button>
              )}

              <Input
                data-testid="sboms-search-input"
                type="text"
                placeholder={t('search.placeholder')}
                value={searchInputValue}
                onChange={handleSearchChange}
                onKeyDown={handleSearchKeyDown}
              />
            </InputGroup>
            <div className="sbom-nav-right__actions">
              {activeTab === 'successful' && (
                <MenuButton
                  allSelected={!!areAllSbomsSelected}
                  selectionCount={selectedSBOMsData.length}
                  disabled={selectedSBOMsData.length < 1}
                  disabledText={t('page.uploads.please-select-a-sbom-download')}
                  icon="arrow-down-to-bracket"
                  text={t('global.download')}
                  ariaLabel={t('global.download')}
                  data-testid="download-sboms-button"
                >
                  <MenuButtonItem
                    disabled={selectedSBOMsData.length < 1}
                    disabledText={t('page.uploads.please-select-a-sbom-download')}
                    icon="arrow-down-to-bracket"
                    text={t('page.uploads.download-sboms')}
                    onClick={() => handleDownloadRequest(selectedSBOMIds)}
                  />
                  <MenuButtonItem
                    disabled={!!cantMergeDownloadReason || !canCreateSbom}
                    disabledText={cantMergeDownloadReason || ''}
                    icon="merge"
                    text={t('page.uploads.merge-download')}
                    onClick={() => handleMergeSbomsAndDownload()}
                  />
                </MenuButton>
              )}
              {activeTab === 'successful' && !isEnabledSharingPortal && (
                <MenuButton
                  allSelected={!!areAllSbomsSelected}
                  selectionCount={selectedSBOMsData.length}
                  disabled={
                    selectedSBOMsData.length < 1 ||
                    areMultipleFilesTypeSelected ||
                    areVexFilesSelected
                  }
                  disabledText={
                    areMultipleFilesTypeSelected || areVexFilesSelected
                      ? ''
                      : t('page.uploads.please-select-a-sbom-share')
                  }
                  icon="share-nodes"
                  text={t('page.uploads.share')}
                  ariaLabel={t('page.uploads.share')}
                >
                  <MenuButtonItem
                    disabled={selectedSBOMsData.length < 1}
                    disabledText={t('page.uploads.please-select-a-sbom-download')}
                    icon="share-nodes"
                    text={t('page.uploads.share-sboms')}
                    onClick={() => handleOpenShareModal()}
                  />
                  <MenuButtonItem
                    disabled={!!cantMergeShareReason}
                    disabledText={cantMergeShareReason || ''}
                    icon="merge"
                    text={t('page.uploads.merge-share')}
                    onClick={() => handleMergeSbomsAndShare()}
                  />
                </MenuButton>
              )}
              {activeTab !== 'pending' && canCreateSbom && (
                <MenuButton
                  allSelected={!!areAllSbomsSelected}
                  selectionCount={selectedSBOMsData.length}
                  disabled={selectedSBOMsData.length < 1}
                  text={<Icon icon="ellipsis-vertical" style={{ display: 'flex' }} />}
                  showArrow={false}
                  showSelectionCount={false}
                  ariaLabel={t('page.uploads.failed')}
                >
                  <MenuButtonItem
                    disabled={selectedSBOMsData.length < 1}
                    icon="trash-can"
                    text={t(
                      `page.uploads.${
                        selectedSBOMsData.length > 0
                          ? areAllSbomsSelected
                            ? 'removeAll'
                            : 'removeNum'
                          : 'remove'
                      }`,
                      { numSboms: selectedSBOMsData.length },
                    )}
                    onClick={() => handleOpenDeleteModal()}
                  />
                </MenuButton>
              )}
            </div>
          </div>
        </div>
        <SbomTable
          countTotalSboms={
            activeTab === 'successful'
              ? countSuccessfulSboms
              : activeTab === 'failed'
                ? countFailedSboms
                : activeTab === 'pending'
                  ? countPendingSboms
                  : activeTab === 'ingests'
                    ? countIngests
                    : 0
          }
          isLoadingSboms={
            activeTab === 'successful'
              ? isLoadingSuccessfulSboms
              : activeTab === 'failed'
                ? isLoadingFailedSboms
                : activeTab === 'pending'
                  ? isLoadingPendingSboms
                  : activeTab === 'ingests'
                    ? isLoadingIngests
                    : false
          }
          sboms={
            activeTab === 'successful'
              ? successfulSboms ?? []
              : activeTab === 'failed'
                ? failedSboms ?? []
                : activeTab === 'pending'
                  ? pendingSboms ?? []
                  : activeTab === 'ingests'
                    ? ingests
                    : []
          }
          hasWriteAccess={canCreateSbom}
        />
      </div>

      <MergeModal open={isMergeModalOpen} onClose={() => setIsMergeModalOpen(false)} />
      <MergeDownloadModal
        open={isMergeDownloadModalOpen}
        sbomIds={selectedSBOMIds}
        onClose={() => {
          setIsMergeDownloadModalOpen(false);
        }}
        downloadFn={handleDownloadRequest}
      />
      <ShareModal
        open={shareModalOpen}
        sbomIds={isShareTriggeredByMerge ? [mergeResultSBOMId] : selectedSBOMIds}
        triggeredByMerge={isShareTriggeredByMerge}
        onClose={handleCloseShareModal}
      />
      <DeleteModal
        allSboms={allSboms}
        onSuccess={handleDeleteSuccess}
        onCancel={handleDeleteCancel}
        open={deleteModalOpen}
        sbomIds={sbomIdsToDelete}
      />
      <UploadSettingsModal
        closeModal={handleCloseUploadSettingsModal}
        isOpen={uploadSettingsModalOpen}
        files={uploadFileList}
      />
      <RequestSbomModal
        open={requestSbomModalOpen}
        onClose={handleCloseRequestSbomModal}
      />
      <OssIngestModal
        open={isOssModalOpen}
        onClose={() => {
          setActiveTab('ingests');
          setIsOssModalOpen(false);
        }}
      />
    </section>
  );
};

export default Sboms;
