import { useMemo } from 'react';
import { OrganizationEnabledFeature } from '../types/organization.interface';
import { useFetchOrganization } from './queries/useFetchOrganization';
import { useOrganizationId } from './utils/useOrganizationId';

export function useOrganizationFeatureFlag(feature: OrganizationEnabledFeature) {
  const [currentOrgId] = useOrganizationId();
  const { data: organization, isLoading } = useFetchOrganization({
    organizationId: currentOrgId,
  });

  const isEnabled = useMemo(() => {
    if (isLoading) return false;
    return organization?.enabledFeatures?.includes(feature) ?? false;
  }, [isLoading, organization, feature]);

  return { isEnabled, isLoading };
}
