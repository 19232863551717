import { InterfaceOrganizationComponent } from '@manifest-cyber/types/interface/dbTables';
import Loading from '../../../components/Loading';
import { InterfaceFullAsset } from '../../../types/FullInterfaces';
import { LicenseIssueWithAffectedComponents } from '../api/useFetchAssetLicenseIssues';
import { LicenseIssues } from './asset-risk-overview-tab-components/LicenseIssues';
import { RiskOverviewCard } from './asset-risk-overview-tab-components/RiskOverviewCard/RiskOverviewCard';
import { Tickets } from './asset-risk-overview-tab-components/Tickets';
import { TopRiskiestComponents } from './asset-risk-overview-tab-components/TopRiskiestComponents';

interface Props {
  fetchedAsset?: InterfaceFullAsset;
  riskiestComponents?: InterfaceOrganizationComponent[];
  isLoadingRiskiestComponents?: boolean;
  licenseIssues?: LicenseIssueWithAffectedComponents[];
  isLoadingLicenseIssues?: boolean;
  showAffectedComponentsByLicenseIssues: boolean;
}

export const AssetRiskOverviewTab = ({
  fetchedAsset,
  riskiestComponents,
  isLoadingRiskiestComponents,
  licenseIssues,
  isLoadingLicenseIssues,
  showAffectedComponentsByLicenseIssues,
}: Props) => {
  if (!fetchedAsset) return <Loading />;

  return (
    <div
      className="asset-risk-overview-content"
      data-testid="asset-risk-overview-tab-content"
    >
      <div className="primary-view anim-slideInLeftShort">
        <RiskOverviewCard fetchedAsset={fetchedAsset} />
        <TopRiskiestComponents
          riskiestComponents={riskiestComponents}
          isLoadingRiskiestComponents={isLoadingRiskiestComponents}
        />
        <LicenseIssues
          showAffectedComponentsByLicenseIssues={showAffectedComponentsByLicenseIssues}
          licenseIssues={licenseIssues}
          isLoadingLicenseIssues={isLoadingLicenseIssues}
        />
      </div>
      <div className="sidebar anim-slideInRightShort">
        <Tickets entityType="asset" entityId={fetchedAsset?._id?.toString() || ''} />
      </div>
    </div>
  );
};
