import {
  useURLFilterBooleanState,
  useURLFilterArrayState,
  useURLFilterNumberState,
  useURLFilterStringState,
} from '../../../../hooks/utils/usePersistentStates';

type FilterConfigEntry<T> = {
  hook: (paramId: string, defaultState: T) => readonly [T, (val: T) => void];
  param: string;
  default: T;
};

export interface InterfaceAssetsFilters {
  filterOnlyHasVulns: boolean;
  filterOnlyHasCriticalVulns: boolean;
  filterOnlyHasLicenseIssues: boolean;
  filterOnlyFirstParty: boolean;
  filterOnlyThirdParty: boolean;
  filterIsActive: boolean;
  filterSharedToPortal: boolean;
  filterRiskScore: string[];
  filterCveIds: string[];
  filterCvssScore: number;
  filterAssetAuthor: string[];
  filterAssetSupplier: string[];
  filterAssetTypes: string[];
  filterLicenseTypes: string[];
  filterLabels: string[];
  filterAssetName: string;
}

export interface Setters {
  setFilterOnlyHasVulns: (value: boolean) => void;
  setFilterOnlyHasCriticalVulns: (value: boolean) => void;
  setFilterOnlyHasLicenseIssues: (value: boolean) => void;
  setFilterOnlyFirstParty: (value: boolean) => void;
  setFilterOnlyThirdParty: (value: boolean) => void;
  setFilterIsActive: (value: boolean) => void;
  setFilterSharedToPortal: (value: boolean) => void;
  setFilterRiskScore: (value: string[]) => void;
  setFilterCveIds: (value: string[]) => void;
  setFilterCvssScore: (value: number) => void;
  setFilterAssetAuthor: (value: string[]) => void;
  setFilterAssetSupplier: (value: string[]) => void;
  setFilterAssetTypes: (value: string[]) => void;
  setFilterLicenseTypes: (value: string[]) => void;
  setFilterLabels: (value: string[]) => void;
  setFilterAssetName: (value: string) => void;
}

const filterConfig: Record<keyof InterfaceAssetsFilters, FilterConfigEntry<any>> = {
  filterOnlyHasVulns: {
    hook: useURLFilterBooleanState,
    param: 'onlyHasVulns',
    default: false,
  },
  filterOnlyHasCriticalVulns: {
    hook: useURLFilterBooleanState,
    param: 'onlyHasCriticalVulns',
    default: false,
  },
  filterOnlyHasLicenseIssues: {
    hook: useURLFilterBooleanState,
    param: 'onlyHasLicenseIssues',
    default: false,
  },
  filterOnlyFirstParty: {
    hook: useURLFilterBooleanState,
    param: 'onlyFirstParty',
    default: false,
  },
  filterOnlyThirdParty: {
    hook: useURLFilterBooleanState,
    param: 'onlyThirdParty',
    default: false,
  },
  filterIsActive: {
    hook: useURLFilterBooleanState,
    param: 'onlyActiveAssets',
    default: true,
  },
  filterSharedToPortal: {
    hook: useURLFilterBooleanState,
    param: 'sharedOnPortal',
    default: false,
  },
  filterRiskScore: {
    hook: useURLFilterArrayState,
    param: 'riskScore',
    default: [],
  },
  filterCveIds: {
    hook: useURLFilterArrayState,
    param: 'cveIds',
    default: [],
  },
  filterCvssScore: {
    hook: useURLFilterNumberState,
    param: 'cvssScore',
    default: 0,
  },
  filterAssetAuthor: {
    hook: useURLFilterArrayState,
    param: 'assetAuthor',
    default: [],
  },
  filterAssetSupplier: {
    hook: useURLFilterArrayState,
    param: 'assetSupplier',
    default: [],
  },
  filterAssetTypes: {
    hook: useURLFilterArrayState,
    param: 'assetType',
    default: [],
  },
  filterLicenseTypes: {
    hook: useURLFilterArrayState,
    param: 'licenseIssueType',
    default: [],
  },
  filterLabels: {
    hook: useURLFilterArrayState,
    param: 'labels',
    default: [],
  },
  filterAssetName: {
    hook: useURLFilterStringState,
    param: 'assetName',
    default: '',
  },
};

export const useFilterState = (): {
  filters: InterfaceAssetsFilters;
  setters: Setters;
} => {
  const filters = {} as InterfaceAssetsFilters;
  const setters = {} as Setters;

  Object.entries(filterConfig).forEach(
    ([key, { hook, param, default: defaultState }]) => {
      const [value, setter] = hook(param, defaultState);
      // Here, key is a string but we know it's one of the keys in Filters
      (filters as any)[key] = value;
      // Create the setter key by capitalizing the first letter and prefixing with 'set'
      const setterKey = 'set' + key.charAt(0).toUpperCase() + key.slice(1);
      (setters as any)[setterKey] = setter;
    },
  );

  return { filters, setters };
};
