import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import './ManifestTag.scss';

interface Props {
  children?: React.ReactNode;
  variant:
    | 'critical'
    | 'mitigate'
    | 'monitor'
    | 'accept'
    | 'first-party'
    | 'kev'
    | 'prev-kev'
    | 'fix-available';
  label?: string;
  color?: string;
  outlined?: boolean;
  count?: number;
  showIcon?: boolean;
}

const manifestTags = (t: (t: string, options?: any) => string, count?: number) =>
  ({
    critical: {
      label: t('tags.critical-issue'),
      icon: 'exclamation-triangle',
    },
    'first-party': {
      label: t('tags.first-party'),
      icon: null,
    },
    kev: {
      label: t('tags.kev'),
      icon: 'bolt',
    },
    'prev-kev': {
      label: t('tags.prev-kev'),
      icon: 'bolt',
    },
    mitigate: {
      label: t('tags.mitigate'),
      // icon: 'exclamation-triangle',
      icon: null,
    },
    monitor: {
      label: t('tags.monitor'),
      // icon: 'exclamation-circle',
      icon: null,
    },
    accept: {
      label: t('tags.accept'),
      // icon: 'check',
      icon: null,
    },
    'fix-available': {
      label: t('tags.fix-available'),
      icon: 'wrench',
    },
  }) as const;

export const ManifestTag = ({ children, variant, count, showIcon = true }: Props) => {
  const { t } = useTranslation();

  const variants = manifestTags(t, count);
  const { label, icon } = variants[variant];

  return (
    <span className={`manifest-tag manifest-tag-${variant}`}>
      {showIcon && icon && <Icon icon={icon} />}
      {children ?? <span>{label}</span>}
    </span>
  );
};
