import { Tooltip } from '@mantine/core';
import copy from 'copy-to-clipboard';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Modal } from 'rsuite';
import { MappedUser } from '../../api/user/user.mapper';
import featureFlagDisciminators from '../../configs/featureFlags';
import { useAuth } from '../../hooks/useAuth';
import useFeatureFlag from '../../hooks/useFeatureFlag';
import { useOrganizationId } from '../../hooks/utils/useOrganizationId';
import { getOrgColorCode, getOrgInitials } from '../../lib/orgIconColor';
import representOrganization from '../../lib/representOrganization';
import { captureExceptionWithMessage } from '../../lib/sentry/captureExceptionWithMessage/captureExceptionWithMessage';
import '../../scss/userDropdown.scss';
import Icon from '../Icon';

interface SwitchOrgModalProps {
  open: boolean;
  onClose: () => void;
}
function SwitchOrgsModal({ open, onClose }: SwitchOrgModalProps) {
  const { user, currentOrgId } = useAuth();
  const [, setCurrentOrgId] = useOrganizationId();

  const { t } = useTranslation();
  const [switchOrgsModalOpen, setSwitchOrgsModalOpen] = useState(false);
  const [organizationData, setOrganizationData] = useState<
    MappedUser['organizationData']
  >({});
  const [currentOrganizationId, setCurrentOrganizationId] = useState<any>(null);
  const [errors, setErrors] = useState<string[]>([]);
  const isEngineerElementsEnabled = useFeatureFlag(
    featureFlagDisciminators.engineerElements,
  );

  useEffect(() => {
    if (open) {
      setSwitchOrgsModalOpen(true);
    } else {
      setSwitchOrgsModalOpen(false);
    }
  }, [open]);

  useEffect(() => {
    setCurrentOrganizationId(currentOrgId);
    if (user) {
      setOrganizationData(user.organizationData);
    }
  }, [user, currentOrgId]);

  const getOrgIcon = (name: string) => {
    if (!name) return <></>;
    const iconText = getOrgInitials(name);
    const orgIconColor = getOrgColorCode(name);
    return (
      <div className={`org-icon ${orgIconColor}`} style={{ background: orgIconColor }}>
        {iconText}
      </div>
    );
  };

  const handleOrgSwitch = async (orgId: string) => {
    try {
      await representOrganization(orgId);
      setCurrentOrgId(orgId);
    } catch (e) {
      captureExceptionWithMessage('unable to switch orgs', e);
    }
  };

  return (
    <Modal open={switchOrgsModalOpen} onClose={onClose} className="switch-orgs-modal">
      <Modal.Header>
        <Modal.Title>{t('navigation.switchOrganizationsModal.header')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Dropdown.Separator className="separator" />
        {Object.entries(organizationData).map(([orgId, orgData]) => {
          //@ts-ignore
          const { name } = orgData;
          return (
            <div key={orgId}>
              <div className="org-row">
                <div className="org-name-container">
                  {getOrgIcon(name)}
                  <div className="org-name"> {name}</div>
                  {isEngineerElementsEnabled && (
                    <Tooltip label="Copy organization ID to clipboard">
                      <span className="copy-org-id" onClick={() => copy(`${orgId}`)}>
                        <Icon icon="copy" />
                      </span>
                    </Tooltip>
                  )}
                </div>
                <div className="switch-org-action">
                  {orgId === currentOrganizationId ? (
                    <button className="btn-select disabled" disabled>
                      {t('global.selected')}
                    </button>
                  ) : (
                    <button
                      className="btn-select active"
                      data-testid={`select-org-${name}`}
                      onClick={() => {
                        handleOrgSwitch(orgId);
                      }}
                    >
                      {t('global.select')}
                    </button>
                  )}
                </div>
              </div>
              <Dropdown.Separator className="separator" />
            </div>
          );
        })}
      </Modal.Body>
    </Modal>
  );
}

export default SwitchOrgsModal;
