import React, { useState } from 'react';
import { Popover, Button, Text, Flex } from '@mantine/core';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Input } from 'rsuite';
import Icon from '../../../components/Icon/Icon';
import ColorPicker from './ColorPicker';
import { useLabel } from './useLabel.hook';
import styles from './Label.module.scss';
import { DeleteGithubLabelConfirmationModal } from './DeleteGithubLabelConfirmationModal';
import { InterfaceManageableLabel } from './Labels';

interface ILabelProps {
  label: InterfaceManageableLabel;
  updateFn: (name: string, color: string, _id: string) => Promise<void>;
  insertFn: (name: string, color: string) => Promise<void>;
  deleteFn: (_id?: string) => Promise<void>;
  checkExistFn: (name?: string) => boolean;
  hasWriteAccess: boolean;
  isGithubLabel?: boolean;
}

function Label({
  label,
  updateFn,
  insertFn,
  deleteFn,
  checkExistFn,
  hasWriteAccess,
  isGithubLabel = false,
}: ILabelProps) {
  const { t } = useTranslation();
  const {
    name,
    displayName,
    color,
    inlineError,
    isEditing,
    handleNameChange,
    handleKeyDown,
    handleSave,
    handleCancel,
    handleEditClick,
    handleDeleteClick,
    handleUpdateColor,
    renderLabelCount,
  } = useLabel({ label, updateFn, insertFn, deleteFn, checkExistFn });
  const { dateCreated } = label;
  const SOURCE = {
    MANIFEST: 'manifest',
    GITHUB: 'github',
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteWrapper = () => {
    if (isGithubLabel) {
      setShowDeleteModal(true);
    } else {
      handleDeleteClick();
    }
  };

  return (
    <div className="label">
      {isEditing ? (
        <div className="label-container">
          <div style={{ width: '100%' }}>
            <div className="label-edit">
              <Popover position="bottom" shadow="md">
                <Popover.Target>
                  <div className="label-color-container editing">
                    <div
                      className="label-color editing"
                      style={{ backgroundColor: color }}
                    />
                  </div>
                </Popover.Target>
                <Popover.Dropdown>
                  <ColorPicker currentColor={color} updateColorFn={handleUpdateColor} />
                </Popover.Dropdown>
              </Popover>
              <Input
                type="text"
                className="label-name-input"
                placeholder={t('page.userSettings.labels.newName')}
                maxLength={200}
                value={name}
                onChange={handleNameChange}
                onKeyDown={handleKeyDown}
              />
              <Button variant="default" onClick={handleCancel}>
                {t('global.cancel')}
              </Button>
              <Button
                disabled={name?.length < 1 || inlineError !== null}
                onClick={handleSave}
              >
                {t('global.save.buttonShort')}
              </Button>
            </div>
          </div>
          {inlineError && (
            <div className={`label-${inlineError?.type || 'error'}`}>
              {inlineError?.type === 'error' && <Icon icon="exclamation-triangle" />}
              <span>
                {t(`page.userSettings.labels.error.${inlineError?.key || 'generic'}`)}
              </span>
            </div>
          )}
        </div>
      ) : (
        <div className="label-view">
          <div className="grid-item grid-item-1">
            {isGithubLabel ? (
              <div className="label-color-container">
                <Icon icon="github" iconStyle="fab" classNames={styles.githubIcon} />
              </div>
            ) : (
              <div className="label-color-container">
                <div className="label-color" style={{ backgroundColor: color }} />
              </div>
            )}
            <Flex align="center" gap={8}>
              <Popover position="top" withArrow shadow="md">
                <Popover.Target>
                  <div className="label-name">{displayName}</div>
                </Popover.Target>
                <Popover.Dropdown>{name}</Popover.Dropdown>
              </Popover>
              <div className="label-assets">{renderLabelCount()}</div>
            </Flex>
          </div>
          <div className="grid-item grid-item-2">
            <Flex gap={8} align="center">
              {dateCreated && label.source === SOURCE.MANIFEST && (
                <Popover position="top" withArrow shadow="md">
                  <Popover.Target>
                    <span>
                      <Icon icon="circle-info" />
                    </span>
                  </Popover.Target>
                  <Popover.Dropdown>
                    {t('global.createdOn', {
                      date: DateTime.fromJSDate(dateCreated).toFormat('DDD'),
                    })}
                  </Popover.Dropdown>
                </Popover>
              )}
              {hasWriteAccess && (
                <>
                  {label.source === 'manifest' && (
                    <span>
                      <Icon icon="pen" onClick={handleEditClick} />
                    </span>
                  )}
                  <Popover width={150} position="bottom" withArrow shadow="md">
                    <Popover.Target>
                      <span>
                        <Icon icon="ellipsis" />
                      </span>
                    </Popover.Target>
                    <Popover.Dropdown onClick={handleDeleteWrapper}>
                      <div
                        className="label-actions--delete"
                        style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                      >
                        <Icon icon="trash-can" />
                        <Text>{t('page.userSettings.labels.deleteLabel')}</Text>
                      </div>
                    </Popover.Dropdown>
                  </Popover>
                </>
              )}
            </Flex>
          </div>
        </div>
      )}
      {isGithubLabel && (
        <DeleteGithubLabelConfirmationModal
          opened={showDeleteModal}
          label={displayName}
          onConfirm={() => {
            handleDeleteClick();
            setShowDeleteModal(false);
          }}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
}

export default Label;
