import { useTranslation } from 'react-i18next';
import ManifestAccordion from '../../../../components/ManifestAcordion/ManifestAcordion';
import { LicenseIssueWithAffectedComponents } from '../../api/useFetchAssetLicenseIssues';
import {
  LicenseIssueCard,
  LicenseIssueHeader,
} from './LicenseIssueCard/LicenseIssueCard';
interface Props {
  licenseIssues?: LicenseIssueWithAffectedComponents[];
  showAffectedComponentsByLicenseIssues: boolean;
  isLoadingLicenseIssues?: boolean;
}

export const LicenseIssues = ({
  licenseIssues,
  showAffectedComponentsByLicenseIssues,
}: Props) => {
  const { t } = useTranslation();
  const hasLicenseIssues = licenseIssues && licenseIssues.length > 0;

  return (
    <div className="unstyled-cont prioritization-map anim-slideInLeftShort">
      <h3>
        {t('page.asset.license-issues')}
        {hasLicenseIssues && ` (${licenseIssues.length})`}
      </h3>
      <div className="license-issue-cards">
        {licenseIssues && licenseIssues.length > 0 ? (
          showAffectedComponentsByLicenseIssues ? (
            <ManifestAccordion>
              {licenseIssues.map((licenseIssue) => (
                <LicenseIssueCard
                  key={licenseIssue.id.toString()}
                  licenseIssue={licenseIssue}
                />
              ))}
            </ManifestAccordion>
          ) : (
            <>
              {licenseIssues.map((licenseIssue) => (
                <LicenseIssueHeader
                  licenseIssue={licenseIssue}
                  asCard
                  key={licenseIssue.id}
                />
              ))}
            </>
          )
        ) : (
          <p>{t('page.asset.no-license-issues')}</p>
        )}
      </div>
    </div>
  );
};
