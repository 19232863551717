import { ColorDot } from '@manifest-cyber/design-system/lib/components/ColorDot';
import { Box, CloseButton, clsx } from '@mantine/core';
import { ReactNode } from 'react';
import styles from './ChipComponent.module.scss';

interface ChipComponentProps {
  leftIcon: {
    isVisible: boolean;
    color?: string;
  };
  label: ReactNode;
  onClick?: () => void;
  className?: string;
  showRemoveButton?: boolean;
}

const ChipComponent = ({
  label,
  leftIcon,
  onClick,
  className,
  showRemoveButton = true,
}: ChipComponentProps) => {
  return (
    <div data-testid="chip-component" className={clsx(styles.chip, className)}>
      {leftIcon.isVisible && (
        <Box mr="8px" ml="4px" data-testid="chip-left-icon">
          <ColorDot color={leftIcon.color} />
        </Box>
      )}
      <div className={styles.labelName}>{label}</div>
      {showRemoveButton && (
        <CloseButton
          className={styles.closeButton}
          onMouseDown={onClick}
          variant="transparent"
          data-testid={`chip-component-close-button-${label}`}
          size="xs"
          iconSize={14}
          tabIndex={-1}
        />
      )}
    </div>
  );
};

export default ChipComponent;
