import { InterfaceOrganizationComponent } from '@manifest-cyber/types/interface/dbTables';
import { Loader } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import ManifestAccordion from '../../../../components/ManifestAcordion/ManifestAcordion';
import ComponentCard from './ComponentCard/ComponentCard';

interface Props {
  riskiestComponents?: InterfaceOrganizationComponent[];
  isLoadingRiskiestComponents?: boolean;
}

export const TopRiskiestComponents = ({
  riskiestComponents,
  isLoadingRiskiestComponents,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="unstyled-cont prioritization-map anim-slideInLeftShort">
      <h3>{t('page.asset.riskiestComponents')}</h3>
      <div className="component-cards">
        {isLoadingRiskiestComponents ? (
          <Loader />
        ) : (
          <>
            {riskiestComponents?.length ? (
              <>
                <div className="risky-components-container header-row">
                  <div className="name-container">
                    <div className="name">{t('global.name')}</div>
                  </div>
                  <div className="kev">{t('tags.kev')}</div>
                  <div className="mitigate">{t('tags.mitigate')}</div>
                  <div className="monitor">{t('tags.monitor')}</div>
                  <div className="license-issues">{t('tags.licenseIssues')}</div>
                </div>
                <ManifestAccordion>
                  {riskiestComponents.map((component) => (
                    <ComponentCard
                      key={`${component?._id?.toString()}-${component?.version}-${component?._id?.toString()}`}
                      component={component}
                    />
                  ))}
                </ManifestAccordion>
              </>
            ) : (
              <p>{t('page.asset.noComponents')}</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};
