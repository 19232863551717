import { AssetReference } from '@manifest-cyber/types/interface/db';
import { InterfaceOrganizationComponent } from '@manifest-cyber/types/interface/dbTables';
import {
  ActionIcon,
  Box,
  Card,
  Divider,
  Flex,
  Stack,
  Switch,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { MRT_PaginationState, MRT_SortingState } from 'mantine-react-table';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { MappedOrganizationComponentWithVulns } from '../../api/componentsApi/components.mapper';
import { useFetchUserPermissions } from '../../api/user/useFetchUserPermissions';
import ClickableRegion from '../../components/ClickableRegion';
import { ComponentAssetsTable } from '../../components/DataTables/ComponentAssetsTable';
import { ComponentVersionsTable } from '../../components/DataTables/ComponentVersionsTable/ComponentVersionsTable';
import { ComponentVulnerabilitiesTable } from '../../components/DataTables/ComponentVulnerabilitiesTable/ComponentVulnerabilitiesTable';
import Icon from '../../components/Icon';
import Loading from '../../components/Loading';
import { ManifestTabs } from '../../components/ManifestTabs/ManifestTabs';
import NumberBadge from '../../components/NumberBadge/NumberBadge';
import { ProtectedRoute } from '../../components/ProtectedRoute/ProtectedRoute';
import RenderNameWithGroupName from '../../components/RenderNameWithGroupName';
import SupplierModal from '../../components/SupplierModal';
import { useFetchComponent } from '../../hooks/queries/useFetchComponent';
import { useFetchComponentLicenseIssues } from '../../hooks/queries/useFetchComponentLicenseIssues';
import { useFetchComponentVulns } from '../../hooks/queries/useFetchComponentVulns';
import '../../scss/pages/component.scss';
import { InterfaceOrganizationComponentWithLicenses } from '../../types/FullInterfaces';
import styles from './Component.module.scss';

const Component = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const { hasPermissions } = useFetchUserPermissions();
  const hasWriteAccess = hasPermissions({
    requiredPermissions: ['update:component'],
  });
  const { componentId, organizationId } = useParams();
  const activeTab = pathname.split('/').pop();
  const [showSupplierModal, setShowSupplierModal] = useState(false);
  const [editSupplierComponent, setEditSupplierComponent] =
    useState<MappedOrganizationComponentWithVulns | null>(null);
  const [isActiveAssetsOnly, setIsActiveAssetsOnly] = useState(true);

  const handleSwitchChange = (): void => {
    setIsActiveAssetsOnly((prev: boolean) => !prev);
  };

  const {
    data: fetchedComponentData,
    isLoading: isLoadingComponent,
    isError: isErrorLoadingComponent,
    isFetching: isFetchingComponent,
  } = useFetchComponent({
    componentId: componentId!,
    organizationId: organizationId!,
    translations: {
      activelyMaintained: t('tables.components.supportLevel.activelyMaintained'),
      noMaintained: t('tables.components.supportLevel.noMaintained'),
      abandoned: t('tables.components.supportLevel.abandoned'),
      unknown: t('global.unknown'),
    },
  });

  const [vulnsPagination, setVulnsPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const {
    data: {
      data: fetchedVulnData,
      queryInfo: { totalCount: countVulnerabilities = 0 } = {},
    } = {},
    isSuccess: isSucessVulns,
    isLoading: isLoadingVulns,
    isError: isErrorLoadingVulns,
    isFetched: isFetchedVulns,
    isFetching: isFetchingVulns,
  } = useFetchComponentVulns({
    componentId: componentId!,
    sortColumn: sorting[0]?.id,
    sortType:
      sorting[0]?.desc === true ? 'desc' : sorting[0]?.desc === false ? 'asc' : undefined,
    page: vulnsPagination.pageIndex + 1,
    limit: vulnsPagination.pageSize,
  });

  const {
    data: fetchedComponentLicenseIssues,
    isLoading: isLoadingComponentLicenseIssues,
  } = useFetchComponentLicenseIssues({ componentId: componentId! });

  const errors = useMemo(() => {
    const errs = [];
    if (isErrorLoadingComponent) errs.push('Unable to load component!');
    if (isErrorLoadingVulns) errs.push('Unable to load vulnerabilities!');
    return errs;
  }, [isErrorLoadingComponent, isErrorLoadingVulns]);

  const { component, versions } = fetchedComponentData || {};

  const {
    distinctVulnerabilities,
    thisVersionVulnerabilities: _thisVersionVulnerabilities,
    versionVulnerabilityCount,
  } = fetchedVulnData || {};

  //filter full list of full object vulnerabilities to only those that are in this version
  const thisVersionVulnerabilities = useMemo(() => {
    return distinctVulnerabilities?.filter((vuln) =>
      _thisVersionVulnerabilities //this array is just componentVulns with not all required fields
        ?.map((vuln) => vuln.cveId?.toString())
        ?.includes(vuln.cveId?.toString()),
    );
  }, [_thisVersionVulnerabilities, distinctVulnerabilities]);

  const versionsWithVulnCount = useMemo(() => {
    if (versions) {
      return versions.map((version) => {
        return {
          ...version,
          countVulnerabilities: versionVulnerabilityCount?.[version?.version ?? ''] || 0,
        };
      });
    }
  }, [versions, versionVulnerabilityCount]);

  const refinedComponentLinks = useMemo(() => {
    // Refine our links to remove dupes and require real URL's
    if (component?.references) {
      const refinedLinks: AssetReference[] = [];
      for (let thisAssetLink of component?.references) {
        if (
          thisAssetLink?.url &&
          thisAssetLink?.url?.length > 0 &&
          (thisAssetLink?.url?.includes('http://') ||
            thisAssetLink?.url?.includes('https://'))
        ) {
          const thisLink = refinedLinks.find((link) => link.url === thisAssetLink?.url);
          if (!thisLink) {
            refinedLinks.push(thisAssetLink);
          }
        }
      }
      return refinedLinks;
    }
  }, [component?.references]);

  if (isLoadingComponent) {
    return <Loading />;
  }

  if (!isLoadingComponent && !component) {
    return (
      <div className="no-exist-or-access">
        <span>{t('page.component.no-exist-or-access')}</span>
        <ClickableRegion
          regionLabel={t('global.return-to', {
            name: t('page.dashboard.nav-title'),
          })}
          href="/"
          className="its-awesome"
        >
          <span>
            {t('global.return-to', {
              name: t('page.dashboard.nav-title'),
            })}
          </span>
        </ClickableRegion>
      </div>
    );
  }

  return (
    <section className="page-component-details">
      <Helmet title={t('page.component.page-title')}>
        <meta name="description" content={t('app.oneliner')} />
      </Helmet>

      {errors.length > 0 && (
        <ul className="page-errors anim-slideInUpShort">
          {errors.map((error) => (
            <li>{error}</li>
          ))}
        </ul>
      )}

      {component && (
        <div className="component-header">
          <div className="component-org-logo anim-slideInLeftShort">
            <div className="logo-inner logo-letter">
              <span>{component?.name?.charAt(0).toUpperCase()}</span>
            </div>
          </div>
          <div className="component-name anim-slideInDownShort">
            <h1 className="table-link">
              {RenderNameWithGroupName(
                `${
                  component?.fullyQualifiedName ||
                  component?.name ||
                  component?.packageUrlNoVersion
                }`,
              )}
            </h1>
            <div className="component-last-updated anim-slideInUpShort">
              {component?.version && (
                <span className="current-version">
                  <ClickableRegion
                    regionLabel="Current Version"
                    onClick={() => navigate('versions', { replace: true })}
                  >
                    <strong>{component?.version}</strong>
                  </ClickableRegion>
                </span>
              )}

              {component?.dateModified && (
                <span>
                  {t('page.component.component-last-modified', {
                    timestamp: DateTime.fromISO(
                      `${component?.dateModified}`,
                    ).toLocaleString(DateTime.DATETIME_MED),
                  })}
                </span>
              )}
            </div>
          </div>
          <div className="component-actions"></div>
        </div>
      )}

      {editSupplierComponent && component && (
        <SupplierModal
          opened={showSupplierModal}
          onCancel={() => {
            setEditSupplierComponent(null);
            setShowSupplierModal(false);
          }}
          onConfirm={() => {
            queryClient.invalidateQueries(['component']);
            setEditSupplierComponent(null);
            setShowSupplierModal(false);
          }}
          component={editSupplierComponent as InterfaceOrganizationComponent}
        />
      )}
      <Box mt="24px">
        <ManifestTabs
          value={activeTab}
          onTabChange={(newTab) => navigate(newTab as string, { replace: true })}
        >
          <ManifestTabs.List>
            <ManifestTabs.Tab value="overview">
              {t('page.component.tabs.overview')}
            </ManifestTabs.Tab>
            <ManifestTabs.Tab value="vulnerabilities">
              {t('page.component.tabs.vulnerabilities')}
              {
                <Tooltip
                  label={`There are ${countVulnerabilities} vulnerabilities in this version (${component?.version}) of this component.`}
                  multiline
                  width={300}
                >
                  <NumberBadge
                    id="rewardId"
                    isLoading={isLoadingVulns}
                    number={countVulnerabilities}
                    active={activeTab === 'vulnerabilities'}
                  />
                </Tooltip>
              }
            </ManifestTabs.Tab>
            <ManifestTabs.Tab value="versions">
              {t('page.component.tabs.versions')}
              {versionsWithVulnCount && (
                <NumberBadge
                  isLoading={isLoadingComponent}
                  number={versionsWithVulnCount.length}
                  active={activeTab === 'versions'}
                />
              )}
            </ManifestTabs.Tab>
          </ManifestTabs.List>
        </ManifestTabs>
      </Box>

      <Routes>
        <Route
          path="overview"
          element={
            <ProtectedRoute requiredPermissions={['read:component']}>
              <div className="component-overview-content">
                <div className="alt-view">
                  {component && (
                    <Card className="view-cont description anim-slideInLeftShort">
                      <h4>{t('page.component.description')}</h4>
                      <p>{component?.description || 'No description given'}</p>
                    </Card>
                  )}

                  {component && (
                    <Card className="view-cont about anim-slideInLeftShort">
                      <h4>{t('page.component.about')}</h4>
                      <ul>
                        <li>
                          <div className="label">{t('page.component.name')}</div>
                          <div className="value">{component?.name}</div>
                        </li>
                        <li>
                          <div className="label">{t('page.component.version')}</div>
                          <div className="value">{component?.version}</div>
                        </li>
                        {component?.commitVersionHash && (
                          <li>
                            <div className="label">
                              {t('page.component.commitVersionHash')}
                            </div>
                            <div className="value">{component?.commitVersionHash}</div>
                          </li>
                        )}
                        {component?.type && (
                          <li>
                            <div className="label">{t('page.component.type')}</div>
                            <div className="value">{component?.type}</div>
                          </li>
                        )}
                        <>
                          {component.lastReleaseDateText && (
                            <li>
                              <div className="label">
                                {t('page.component.lastReleaseDate')}
                              </div>
                              <div className="value">{component.lastReleaseDateText}</div>
                            </li>
                          )}
                          {component.levelOfSupportText && (
                            <li>
                              <div className="label">
                                {t('page.component.levelOfSupport')}
                              </div>
                              <div className="value">{component.levelOfSupportText}</div>
                            </li>
                          )}
                          {component.endOfSupportText && (
                            <li>
                              <div className="label">
                                {t('page.component.endOfSupport')}
                              </div>
                              <div className="value">{component.endOfSupportText}</div>
                            </li>
                          )}
                        </>
                        {component?.derivedEcosystem && (
                          <li>
                            <div className="label">{t('page.component.ecosystem')}</div>
                            <div className="value">{component?.derivedEcosystem}</div>
                          </li>
                        )}
                        <li>
                          <div className="label">{t('page.component.developedBy')}</div>
                          <div className="value">
                            {component?.author && (
                              <>
                                <span>{component?.author}</span>
                                {hasWriteAccess && (
                                  <ClickableRegion
                                    regionLabel="Modify source information."
                                    onClick={() => {
                                      setEditSupplierComponent(component);
                                      setShowSupplierModal(true);
                                    }}
                                    className="inline-list-link"
                                  >
                                    <>
                                      <Icon icon="pencil" />
                                    </>
                                  </ClickableRegion>
                                )}
                              </>
                            )}
                            {!component?.author && (
                              <>
                                {hasWriteAccess && (
                                  <ClickableRegion
                                    regionLabel="Modify source information."
                                    onClick={() => {
                                      setEditSupplierComponent(component);
                                      setShowSupplierModal(true);
                                    }}
                                    className="inline-list-link"
                                  >
                                    <>
                                      <span>{t('global.notProvided')}</span>
                                      <Icon icon="pencil" />
                                    </>
                                  </ClickableRegion>
                                )}
                              </>
                            )}
                          </div>
                        </li>
                        <li>
                          <div className="label">{t('page.component.suppliedBy')}</div>
                          <div className="value">
                            {component?.supplier?.name && (
                              <>
                                <span>{component?.supplier?.name}</span>
                                {hasWriteAccess && (
                                  <ClickableRegion
                                    regionLabel="Modify source information."
                                    onClick={() => {
                                      setEditSupplierComponent(component);
                                      setShowSupplierModal(true);
                                    }}
                                    className="inline-list-link"
                                  >
                                    <>
                                      <Icon icon="pencil" />
                                    </>
                                  </ClickableRegion>
                                )}
                              </>
                            )}
                            {!component?.supplier?.name && (
                              <>
                                {hasWriteAccess && (
                                  <ClickableRegion
                                    regionLabel="Modify source information."
                                    onClick={() => {
                                      setEditSupplierComponent(component);
                                      setShowSupplierModal(true);
                                    }}
                                    className="inline-list-link"
                                  >
                                    <>
                                      <span>{t('global.notProvided')}</span>
                                      <Icon icon="pencil" />
                                    </>
                                  </ClickableRegion>
                                )}
                              </>
                            )}
                          </div>
                        </li>
                        <li>
                          <div className="label">{t('page.component.publishedBy')}</div>
                          <div className="value">
                            {component?.publisher && (
                              <>
                                <span>{component?.publisher}</span>
                                {hasWriteAccess && (
                                  <ClickableRegion
                                    regionLabel="Modify source information."
                                    onClick={() => {
                                      setEditSupplierComponent(component);
                                      setShowSupplierModal(true);
                                    }}
                                    className="inline-list-link"
                                  >
                                    <>
                                      <Icon icon="pencil" />
                                    </>
                                  </ClickableRegion>
                                )}
                              </>
                            )}
                            {!component?.publisher && (
                              <>
                                {hasWriteAccess && (
                                  <ClickableRegion
                                    regionLabel="Modify source information."
                                    onClick={() => {
                                      setEditSupplierComponent(component);
                                      setShowSupplierModal(true);
                                    }}
                                    className="inline-list-link"
                                  >
                                    <>
                                      <span>{t('global.notProvided')}</span>
                                      <Icon icon="pencil" />
                                    </>
                                  </ClickableRegion>
                                )}
                              </>
                            )}
                          </div>
                        </li>

                        {(component as InterfaceOrganizationComponentWithLicenses)
                          ?.licensesData && (
                          <li>
                            <div className="label">{t('page.component.licenses')}</div>
                            <div className="value">
                              {(
                                component as InterfaceOrganizationComponentWithLicenses
                              )?.licensesData
                                ?.map((l) => l.shortName)
                                ?.join(', ')}
                            </div>
                          </li>
                        )}
                        {component?.fullyQualifiedName && (
                          <li>
                            <div className="label">{t('page.component.fqn')}</div>
                            <div className="value">{component?.fullyQualifiedName}</div>
                          </li>
                        )}
                        {refinedComponentLinks && refinedComponentLinks?.length > 0 && (
                          <li>
                            <div className="label">{t('page.component.links')}</div>

                            <div className="value">
                              {refinedComponentLinks?.map((reference: AssetReference) => {
                                // @ts-ignore
                                return (
                                  <li>
                                    <ClickableRegion
                                      regionLabel={`${reference.type || 'Click to view'}`}
                                      href={reference.url}
                                      target="_blank"
                                    >
                                      <span>{reference.type}</span>
                                    </ClickableRegion>
                                  </li>
                                );
                              })}
                            </div>
                          </li>
                        )}
                      </ul>
                    </Card>
                  )}
                </div>
                <div className="primary-view anim-slideInRightShort">
                  {component && (
                    <Stack className="unstyled-cont prioritization-map anim-slideInRight">
                      <Card>
                        <Flex justify={'space-between'} align={'center'}>
                          <Title order={3}>
                            {t('page.component.assets', { version: component?.version })}{' '}
                            {}
                          </Title>
                          <Switch
                            checked={isActiveAssetsOnly}
                            onChange={handleSwitchChange}
                            label={
                              <span>
                                {t('page.component.activeAssetsOnly')}{' '}
                                <Tooltip
                                  classNames={{
                                    tooltip: styles.tooltip,
                                  }}
                                  label={t('page.component.activeAssetsOnlyTooltip')}
                                  position="top"
                                  withArrow
                                >
                                  <span>
                                    <Icon icon="info-circle" />
                                  </span>
                                </Tooltip>
                              </span>
                            }
                          />
                        </Flex>
                        <ComponentAssetsTable
                          componentId={componentId!}
                          isActiveAssetsOnly={isActiveAssetsOnly}
                        />
                      </Card>

                      <Card>
                        <Title order={3}>
                          {t('page.component.licenseIssues')}{' '}
                          {!!component.countLicenseIssues?.total && (
                            <NumberBadge
                              isLoading={isLoadingComponent}
                              number={component.countLicenseIssues?.total || 0}
                              active={false}
                              data-testid="license-issues-quatity-badge"
                            />
                          )}
                        </Title>
                        <Divider my="md" />

                        {isLoadingComponentLicenseIssues ? (
                          <Loading />
                        ) : fetchedComponentLicenseIssues?.length === 0 ? (
                          t('page.component.no-license-issues')
                        ) : (
                          fetchedComponentLicenseIssues?.map((licenseIssue) => {
                            return (
                              <Flex align="center" gap="sm">
                                <Text size="lg">{licenseIssue.license.fullName}</Text>
                                <Text color="red">
                                  <ActionIcon variant="transparent" color="red.6">
                                    <Icon icon="circle-info" />
                                  </ActionIcon>
                                </Text>
                                <Text color="gray">
                                  {licenseIssue.license.licenseType}
                                </Text>
                              </Flex>
                            );
                          })
                        )}
                      </Card>
                    </Stack>
                  )}
                </div>
              </div>
            </ProtectedRoute>
          }
        />

        <Route
          path="vulnerabilities"
          element={
            <ProtectedRoute
              requiredPermissions={['read:component', 'read:vulnerability']}
            >
              <ComponentVulnerabilitiesTable
                thisVersionVulnerabilities={thisVersionVulnerabilities}
                isLoadingVulns={isLoadingVulns}
                countVulnerabilities={countVulnerabilities}
                isFetchingVulns={isFetchingVulns}
                pagination={vulnsPagination}
                setPagination={setVulnsPagination}
                sorting={sorting}
                setSorting={setSorting}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="versions"
          element={
            <ProtectedRoute requiredPermissions={['read:component']}>
              <ComponentVersionsTable
                // @ts-expect-error TODO: fix type error when updating this file
                componentData={fetchedComponentData}
                isLoadingComponentVersions={isLoadingComponent}
                isFetchingComponentVersions={isFetchingComponent}
              />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="overview" replace />} />
      </Routes>
    </section>
  );
};

export default Component;
