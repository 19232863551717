// Labels.tsx
import { Tooltip } from '@mantine/core';
import { Types } from 'mongoose';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useFetchLabels } from '../../hooks/queries/useFetchLabels';
import Icon from '../Icon/Icon';
import { ManifestTag } from '../ManifestTag/ManifestTag';
import './Labels.scss';
import { InterfaceFullAsset } from '../../types/FullInterfaces';
import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';
import { VulnImpactAsset } from '../../hooks/queries/useFetchVulnAssets';

interface Label {
  _id: string;
  name: string;
  displayName: string;
  color: string | string[];
  source: string;
}

type SupportedEntity =
  | InterfaceFullAsset
  | InterfaceOrganizationProduct
  | VulnImpactAsset;

interface ILabelsProps {
  entity: SupportedEntity;
  condensed?: boolean;
  justifyContent?: string;
  onEditButtonClick?: () => void;
  onLabelClick?: (label: Label) => void;
  showWarnings?: boolean;
  rounded?: boolean;
  inactive?: boolean;
}

function Labels({
  entity,
  condensed,
  justifyContent = 'flex-start',
  onEditButtonClick,
  onLabelClick,
  showWarnings = true,
  rounded,
  inactive = false,
}: ILabelsProps) {
  const { t } = useTranslation();

  const labelIds = useMemo(
    () => entity?.labelIds?.map((l) => l.toString()) || [],
    [entity],
  );

  const { data: fetchedLabels = [] } = useFetchLabels(
    { labelIds: labelIds },
    { enabled: !!labelIds.length },
  );

  const parsedLabels = useMemo(() => {
    let labels: Label[] = fetchedLabels.map((fetchedLabel: any) => {
      const { _id, name, color, source } = fetchedLabel;
      const displayName = name.length <= 10 ? name : name.slice(0, 9) + '...';
      return {
        _id,
        name,
        displayName,
        color,
        source,
      };
    });

    function ensureColorArray(c: string | string[]): string[] {
      return Array.isArray(c) ? c : [c];
    }

    if (condensed && labels.length > 2) {
      const indices = labels.length > 3 ? [1, 2, 3] : [1, 2];

      if (labels.length > 3) {
        const colors = indices.reduce<string[]>((acc, idx) => {
          // Since we know labels.length > 3, labels[idx] is guaranteed to exist.
          return acc.concat(ensureColorArray(labels[idx]!.color));
        }, []);

        const labelSummary: Label & { isSummary?: boolean } = {
          _id: uuidv4(),
          name: labels
            .map((l) => l.name)
            .slice(1)
            .join('\r\n'),
          displayName: `+${labels.length - 1} ${t('global.labels').toLowerCase()}`,
          color: colors,
          isSummary: true,
          source: 'manifest',
        };

        // We checked that labels.length > 0 before using labels[0].
        labels = [labels[0]!, labelSummary];
      }
    }

    return labels;
  }, [fetchedLabels, condensed, t]);

  return (
    <div
      className={`labels-container ${inactive ? 'isInactive' : ''}`}
      style={{ justifyContent }}
    >
      {showWarnings && (
        <>
          {parseInt(`${entity?.countVulnerabilities?.inKEV}`) > 0 ? (
            <span className="kev-badger-wrapper">
              <ManifestTag variant="kev" />
            </span>
          ) : parseInt(`${entity?.countVulnerabilities?.inKEVPreviously}`) > 0 ? (
            <span className="kev-badger-wrapper">
              <ManifestTag variant="prev-kev" />
            </span>
          ) : null}
        </>
      )}
      {parsedLabels.map((label: any) => {
        console.log('***label', label);
        return (
          <Tooltip
            multiline
            key={label._id}
            disabled={label.displayName === label.name}
            label={<pre>{label.name}</pre>}
          >
            <div
              onClick={() => !label.isSummary && onLabelClick?.(label)}
              style={{ cursor: !label.isSummary && onLabelClick ? 'pointer' : undefined }}
              className={`label-container${rounded ? '--rounded' : ''}`}
            >
              <div className="label-color-container">
                {label.source === 'github' ? (
                  <Icon icon="github" iconStyle="fab" classNames="githubIcon" />
                ) : Array.isArray(label.color) ? (
                  <div className="multi-color-container">
                    {label.color.map((color: string, idx: number) => (
                      <div
                        key={idx}
                        className={`label-color ${idx > 0 ? ' overlap' : ''}`}
                        style={{ backgroundColor: color }}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="label-color" style={{ backgroundColor: label.color }} />
                )}
              </div>
              <div className="label-name">{label.displayName}</div>
            </div>
          </Tooltip>
        );
      })}
      {/* {parsedLabels.length === 0 && (
        <span className='no-labels'>
          {t('jargon.no-labels')}
        </span>
      )} */}
      {onEditButtonClick && (
        <Tooltip label={t('labels.editButtonTooltip.editLabels')}>
          <div className="edit-button-container">
            <Icon icon="pen" onClick={onEditButtonClick} />
          </div>
        </Tooltip>
      )}
    </div>
  );
}

export default Labels;
