import Cookies from 'js-cookie';
import * as Sentry from '@sentry/react';
import { useState } from 'react';

export type OrganizationIdType = string | null;
const keyName = 'organizationid';
export const ORGANIZATION_ID_LOCAL_STORAGE_KEY = keyName;

export const useOrganizationId = (
  defaultValue: OrganizationIdType = null,
): [OrganizationIdType, (newValue: OrganizationIdType) => void] => {
  const [storedValue, setStoredValue] = useState((): OrganizationIdType => {
    try {
      const value =
        window.localStorage.getItem(keyName) ||
        Cookies.get(keyName.toLowerCase()) ||
        null;

      if (value) {
        return JSON.parse(value);
      } else {
        // We should not save falsy values such as undefined, null or empty strings
        if (defaultValue) {
          window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        }

        return defaultValue;
      }
    } catch (err) {
      Sentry.captureMessage(`Error parsing cookie: ${keyName}`, 'error');
      Sentry.captureException(err);
      return defaultValue;
    }
  });

  const setValue = (newValue: OrganizationIdType) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {
      Sentry.captureMessage(`Error setting cookie: ${keyName}`, 'error');
      Sentry.captureException(err);
    }
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};
