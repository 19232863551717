import { InterfaceOrganizationProduct } from '@manifest-cyber/types/interface/dbTables';

export const createRequestBody = (
  entity: 'product' | 'asset',
  entitiesMap: Record<string, boolean>,
  products: InterfaceOrganizationProduct[],
) => {
  const entityIds = Object.keys(entitiesMap);
  const organizationProductIds = products.map((product) => product._id?.toString());

  if (entity === 'asset') {
    return {
      organizationProductIds: organizationProductIds,
      organizationAssetIds: entityIds,
    };
  } else if (entity === 'product') {
    return {
      organizationProductIds: organizationProductIds,
      organizationSubProductIds: entityIds,
    };
  } else {
    return {
      organizationProductIds: organizationProductIds,
      organizationAssetIds: entityIds,
    };
  }
};

export const sortProducts = (
  products: InterfaceOrganizationProduct[],
  order: 'name' | 'dateCreated' | 'dateModified',
): InterfaceOrganizationProduct[] => {
  let sortedProducts = [];

  switch (order) {
    case 'name':
      sortedProducts = products.sort((a, b) => {
        if (!a.name && !b.name) return 0;
        if (!a.name) return -1;
        if (!b.name) return 1;
        return a.name.localeCompare(b.name);
      });
      break;
    case 'dateCreated':
      sortedProducts = products.sort((a, b) => {
        if (!a.dateCreated && !b.dateCreated) return 0;
        if (!a.dateCreated) return -1;
        if (!b.dateCreated) return 1;
        return new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime();
      });
      break;
    case 'dateModified':
      sortedProducts = products.sort((a, b) => {
        if (!a.dateModified && !b.dateModified) return 0;
        if (!a.dateModified) return -1;
        if (!b.dateModified) return 1;
        return new Date(a.dateModified).getTime() - new Date(b.dateModified).getTime();
      });
      break;
    default:
      sortedProducts = products;
  }
  return sortedProducts;
};
