import { TextComponent } from '@manifest-cyber/design-system/lib/components/TextComponent';
import { ManifestColors } from '@manifest-cyber/design-system/lib/foundations/ts';
import { Box, Checkbox, Divider, Flex, Loader, Stack } from '@mantine/core';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ActionableCard } from '../../../components/ActionableCard/ActionableCard';
import Icon from '../../../components/Icon/Icon';
import { ManifestTabs } from '../../../components/ManifestTabs/ManifestTabs';
import NumberBadge from '../../../components/NumberBadge/NumberBadge';
import { useFetchRoleDetail } from './api/useFetchRoleDetail';
import { useFetchRoleUsers } from './api/useFetchRoleUsers';

const texts = ({ t }: { t: TFunction }) => ({
  setAsDefault: t('page.userSettings.roles.setAsDefault'),
  sectionsSubtitles: {
    allPermissions: t('page.userSettings.roles.sectionsSubtitles.allPermissions'),
    apiTokens: t('page.userSettings.roles.sectionsSubtitles.apiTokens'),
    generalSettings: t('page.userSettings.roles.sectionsSubtitles.generalSettings'),
    sboms: t('page.userSettings.roles.sectionsSubtitles.sboms'),
    assets: t('page.userSettings.roles.sectionsSubtitles.assets'),
    products: t('page.userSettings.roles.sectionsSubtitles.products'),
  },
  permissions: {
    viewAll: {
      title: t('page.userSettings.roles.permissions.viewAll.title'),
      description: t('page.userSettings.roles.permissions.viewAll.description'),
    },
    manageSettings: {
      title: t('page.userSettings.roles.permissions.manageSettings.title'),
      description: t('page.userSettings.roles.permissions.manageSettings.description'),
    },
    manageSubOrganizations: {
      title: t('page.userSettings.roles.permissions.manageSubOrganizations.title'),
      description: t(
        'page.userSettings.roles.permissions.manageSubOrganizations.description',
      ),
    },
    manageTokens: {
      title: t('page.userSettings.roles.permissions.manageTokens.title'),
      description: t('page.userSettings.roles.permissions.manageTokens.description'),
    },
    managePeople: {
      title: t('page.userSettings.roles.permissions.managePeople.title'),
      description: t('page.userSettings.roles.permissions.managePeople.description'),
    },
    manageRoles: {
      title: t('page.userSettings.roles.permissions.manageRoles.title'),
      description: t('page.userSettings.roles.permissions.manageRoles.description'),
    },
    manageSharingPortal: {
      title: t('page.userSettings.roles.permissions.manageSharingPortal.title'),
      description: t(
        'page.userSettings.roles.permissions.manageSharingPortal.description',
      ),
    },
    manageSbomsAndVex: {
      title: t('page.userSettings.roles.permissions.manageSbomsAndVex.title'),
      description: t('page.userSettings.roles.permissions.manageSbomsAndVex.description'),
    },
    manageAssets: {
      title: t('page.userSettings.roles.permissions.manageAssets.title'),
      description: t('page.userSettings.roles.permissions.manageAssets.description'),
    },
    manageProducts: {
      title: t('page.userSettings.roles.permissions.manageProducts.title'),
      description: t('page.userSettings.roles.permissions.manageProducts.description'),
    },
    shareSboms: {
      title: t('page.userSettings.roles.permissions.shareSboms.title'),
      description: t('page.userSettings.roles.permissions.shareSboms.description'),
    },
    requestSboms: {
      title: t('page.userSettings.roles.permissions.requestSboms.title'),
      description: t('page.userSettings.roles.permissions.requestSboms.description'),
    },
    importOss: {
      title: t('page.userSettings.roles.permissions.importOss.title'),
      description: t('page.userSettings.roles.permissions.importOss.description'),
    },
    exportReports: {
      title: t('page.userSettings.roles.permissions.exportReports.title'),
      description: t('page.userSettings.roles.permissions.exportReports.description'),
    },
    vulnerabilityTriage: {
      title: t('page.userSettings.roles.permissions.vulnerabilityTriage.title'),
      description: t(
        'page.userSettings.roles.permissions.vulnerabilityTriage.description',
      ),
    },
  },
});

export const RoleDetail = () => {
  const { t } = useTranslation();
  const { roleId } = useParams();
  const {
    data: role,
    isLoading,
    error,
    refetch,
  } = useFetchRoleDetail({
    roleId,
    texts: texts({ t }),
  });
  const [activeTab, setActiveTab] = useState<'permissions' | 'users'>('permissions');
  const { data: users, isLoading: isLoadingUsers } = useFetchRoleUsers({
    roleId,
    enabled: activeTab === 'users',
  });

  if (isLoading && !role) {
    return (
      <Loader
        aria-label="Loading..."
        data-testid="role-detail-loader"
        data-role={role ? 'true' : 'false'}
      />
    );
  }

  if (error || !role) {
    return (
      <ActionableCard
        title={t('error.general.errorLoadingData')}
        subtitle={t('error.general.errorLoadingDataSubtitle')}
        Icon={<Icon icon="circle-exclamation" size="lg" />}
        primaryAction={{
          label: t('global.retry'),
          onClick: () => {
            refetch();
          },
          isLoading,
        }}
      />
    );
  }

  return (
    <Stack spacing="lg" style={{ maxWidth: '600px' }}>
      <Flex justify={'space-between'}>
        <Flex direction={'column'}>
          <TextComponent variant="headerLarge">{role.name}</TextComponent>
        </Flex>
      </Flex>
      <ManifestTabs
        keepMounted={false}
        value={activeTab}
        onTabChange={(value: 'permissions' | 'users') => setActiveTab(value)}
      >
        <ManifestTabs.List>
          <ManifestTabs.Tab value="permissions">
            {t('page.userSettings.roles.detailTabs.general')}
          </ManifestTabs.Tab>
          <ManifestTabs.Tab value="users">
            {t('page.userSettings.roles.detailTabs.members')}

            <NumberBadge number={role.usersQuantity} active={activeTab === 'users'} />
          </ManifestTabs.Tab>
        </ManifestTabs.List>
        <ManifestTabs.Panel value="permissions">
          {role.permissionsGroups.map((group) => (
            <Box key={group.title} mt="32px">
              <TextComponent
                variant={group.isMainGroup ? 'headerMedium' : 'headerAllCaps'}
                color={group.isMainGroup ? 'white' : 'title'}
              >
                {group.title}
              </TextComponent>
              <Divider my="16px" color={ManifestColors.colorBorderCards} />
              {group.compositePermissions.map((permission, index) => (
                <Stack spacing="xs" mt="sm" key={permission.urn}>
                  <Stack>
                    <Flex>
                      <Checkbox
                        checked={permission.isSelected}
                        mr="8px"
                        disabled={!permission.isRemovable}
                        onChange={() => {}}
                      />
                      <TextComponent variant="headerSmall">
                        {permission.displayName}
                      </TextComponent>
                    </Flex>
                    <TextComponent>{permission.description}</TextComponent>
                  </Stack>
                  {index < group.compositePermissions.length - 1 && (
                    <Divider my="16px" color={ManifestColors.colorBorderCards} />
                  )}
                </Stack>
              ))}
            </Box>
          ))}
        </ManifestTabs.Panel>
        <ManifestTabs.Panel value="users">
          <Stack mt="32px">
            {!users && isLoadingUsers && (
              <Loader aria-label="Loading..." data-testid="role-users-loader" />
            )}

            {users && users.length === 0 && (
              <ActionableCard
                hasIconBackground={false}
                title={t('page.userSettings.roles.noMembers.title')}
                Icon={<Icon icon="user" size="lg" />}
              />
            )}
            {users?.map((user) => (
              <Flex key={user.id} gap="12px" justify={'space-between'}>
                <Flex gap="12px">
                  <TextComponent variant="bodyLarge" color="primary">
                    <Icon icon="user" />
                  </TextComponent>

                  <TextComponent variant="bodyMedium">{user.firstName}</TextComponent>
                  <TextComponent variant="bodyMedium">{user.lastName}</TextComponent>
                </Flex>

                <TextComponent variant="bodySmall" color="muted">
                  {user.email}
                </TextComponent>
              </Flex>
            ))}
          </Stack>
        </ManifestTabs.Panel>
      </ManifestTabs>
    </Stack>
  );
};
