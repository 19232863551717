import { InterfaceOrganization } from '@manifest-cyber/types/interface/dbTables';
import { Modal, Button, Stack, TextInput, Flex, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next';

interface Props {
  onCancel: () => void;
  onConfirm: (organization: any) => void;
  opened: boolean;
  validationErrors: Partial<Record<keyof any, string>>;
  isCreating: boolean;
  organizationName?: string;
  organizationId?: string;
}

export const CreateSubOrgModal = ({
  onCancel,
  onConfirm,
  opened,
  validationErrors,
  isCreating,
  organizationName,
  organizationId,
}: Props) => {
  const { t } = useTranslation();

  const form = useForm<Partial<any>>({
    initialValues: {
      name: organizationName || '',
    },
    validate: {
      name: (value) =>
        value && value?.length > 0
          ? null
          : t('page.userSettings.organization.validationMsgs.name'),
    },
  });

  return (
    <Modal
      size={'lg'}
      opened={opened}
      onClose={onCancel}
      data-testid="create-sub-org-modal"
    >
      <form onSubmit={form.onSubmit(onConfirm)}>
        <Stack>
          <Title order={3}>
            {t('page.userSettings.organization.sub-organizations.add-organization')}
          </Title>
          <p>
            {t('page.userSettings.organization.sub-organizations.add-organization-p1')}
          </p>
          <TextInput
            label={t(
              'page.userSettings.organization.sub-organizations.table.headers.name',
            )}
            required
            error={validationErrors.name}
            {...form.getInputProps('name')}
          />
          <TextInput
            hidden
            {...form.getInputProps('organizationId')}
            value={organizationId || ''}
          />
          <Flex justify="flex-end" gap="md">
            <Button type="button" onClick={onCancel} color="dark">
              {t('global.cancel')}
            </Button>
            <Button loading={isCreating} type="submit">
              {t('global.confirm')}
            </Button>
          </Flex>
        </Stack>
      </form>
    </Modal>
  );
};
