import { ColorDot } from '@manifest-cyber/design-system/lib/components/ColorDot';
import { Box, Flex, SelectItemProps } from '@mantine/core';
import { forwardRef } from 'react';
import styles from './SelectOption.module.scss';

const SelectOption = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ label, value, color, ...others }, ref) => {
    return (
      <div ref={ref} {...others}>
        <Flex align="center" className={styles.selectOption}>
          <Box mr="8px" ml="4px">
            <ColorDot color={color} />
          </Box>
          <div>{label}</div>
        </Flex>
      </div>
    );
  },
);

export default SelectOption;
